import './Integration.scss';
import React, { useState, useEffect, useRef } from 'react';
import { Stack, Button, Typography, IconButton, TablePagination, useMediaQuery, TableRow, TableCell, TableContainer, Table, TableBody, Tooltip } from '@mui/material'
import { colors } from '../../../utils/colors';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { buttonStyle4 } from '../../../utils/commonStyle';
import appapi from '../../../api/apiClient';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/features/appSlice';
import Moment from 'moment';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteApiDialog from './components/DeleteApiDialog';
import AddApiDialog from './components/AddApiDialog';
import integrationLock from '../../../assets/images/integrationLock.svg';
import integration from '../../../assets/images/integration.png'
import IntegrationCard from './components/IntegrationCard';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import useOutsideClick from '../../../components/DetectOutsideClick';
import EnchanedTableHeader from '../../../components/EnchanedTableHeader';
import { RenderIf } from '../../../utils/customHooks';
import { showmessage } from '../../../utils/toastr';

const headerCell = [
    {
        name: 'Label',
        width: 'calc(100% - 76.875%)'
    },
    {
        name: 'API Key',
        width: 'calc(100% - 62.5%)'
    },
    {
        name: 'Last Accessed',
        width: 'calc(100% - 78.125%)'
    },
    {
        name: 'Action',
        width: 'calc(100% - 86.875%)'
    }
]

export default function Integration() {

    const [length, setLength] = useState(null);
    const [page, setPage] = useState(0);
    const [backendpage, setBackendPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [integrationList, setIntegrationList] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    const [selectApiKey, setSelectApiKey] = useState(null);
    const [openAddApiDialog, SetOpenAddApiDialog] = useState(false);
    const [onClickFabBtn, setOnClickFabBtn] = useState(false);
    const [permission, setPermision] = useState(null)

    const isMobile = useMediaQuery('(max-width:1000px)');

    //Check OutsideClick to close the nav
    const componentRef = useRef();
    useOutsideClick(componentRef, () => isMobile && setOnClickFabBtn(false));

    /**
     * retrieving user From Redux Store
     */
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch()

    const handleGetIntegrationList = async () => {
        dispatch(setLoading(true));
        const response = await appapi.get(`/integration/${user.enterpriseId}`, {
            page: backendpage,
            limit: rowsPerPage,
        })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data?.keys;
                userData.map((item, index) => {
                    item.Sno = (rowsPerPage * (page)) + index + 1
                    item.isClicked = false
                })
                setLength(data?.data?.length);
                // permission Task
                let find = user?.permission?.find((item) => item?.moduleName === 'integrations');
                setPermision(find);
                setIntegrationList(userData)
            } else {
                setIntegrationList([])
                setLength(0);
            }
            dispatch(setLoading(false))
        } else {
            dispatch(setLoading(false));
            // showmessage(`Something Went Wrong!`, 2000, 'error')
        }
    }

    useEffect(() => {
        handleGetIntegrationList()
    }, [page, rowsPerPage, backendpage, user])

    const handleChangePage = (event, newPage) => {
        setBackendPage(newPage + 1)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setBackendPage(1)
        setPage(0);
    };

    /**
     * Copy Api key
     * @param {Api Key} item 
     */
    const onClickCopyApi = (event, item) => {
        event.stopPropagation()
        navigator.clipboard.writeText(item);
        showmessage(`Api Key Copied`, 'success', 1000)
    }

    const onHandleAction = (item, type) => {
        setOpenDeleteDialog(true)
        setDialogType(type)
        setSelectApiKey(item)
    }

    /**
     * Dialog Close
     * @param {*} type 
     */
    const handleDialogClose = (event, type) => {
        if (type === 'delete') {
            setOpenDeleteDialog(false)
            if (event !== true) {
                if (page === 0 && backendpage === 1) {
                    handleGetIntegrationList();
                } else {
                    if (integrationList.length === 1) {
                        setPage(page === 0 ? 0 : page - 1)
                        setBackendPage(backendpage === 1 ? 1 : backendpage - 1)
                    } else {
                        handleGetIntegrationList();
                    }
                }
                setSelectApiKey(null)
            }
        }

        if (type === 'deactivate') {
            setOpenDeleteDialog(false)
            if (event !== true) {
                handleGetIntegrationList()
                setSelectApiKey(null)
            }
        }

        if (type === 'add') {
            SetOpenAddApiDialog(false)
            if (event !== true) {
                handleGetIntegrationList()
                setSelectApiKey(null)
            }
        }

    }

    const onClickIntegrationCard = (row) => {
        let userList = [...integrationList]
        if (row.isActiveStatus === '0') {
            row.isClicked = false;
            userList.forEach((item) => {
                if (item.integrationId === row.integrationId) {
                    item.isClicked = row.isClicked
                } else {
                    item.isClicked = false
                }
            })
        } else {
            row.isClicked = row.isClicked === true ? false : true
            userList.forEach((item) => {
                if (item.integrationId === row.integrationId) {
                    item.isClicked = row.isClicked
                } else {
                    item.isClicked = false
                }
            })
        }
        setIntegrationList(userList)
    }

    const onHandleFabAction = (event) => {
        event.stopPropagation();
        setOnClickFabBtn(!onClickFabBtn);
    }

    const onHandleAddBtn = (event, flag) => {
        event.stopPropagation();
        SetOpenAddApiDialog(flag)
    }

    return (
        <div className='IntegrationContainer inputFormStyle innerPadding'>
            {/**
             * Top Toolbar
             */}
            <header className={isMobile && 'headerMobile'} style={{ marginBottom: '8px' }}>
                <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                    <Typography className='ff-Roboto fw-medium text-upper' style={{ color: colors.blackMain }}>
                        INTEGRATIONS
                    </Typography>
                    <div className='flex-aligncenter'>
                        {
                            length <= 0 ? '' :
                                <Button onClick={(event) => onHandleAddBtn(event, true)} className='mobileviewStyle' sx={buttonStyle4} variant="contained">
                                    Create API Key
                                </Button>
                        }
                        <Button className='mobileviewStyle' sx={buttonStyle4} variant="contained">
                            Integration docs
                        </Button>
                        {
                            isMobile ? null :
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={length ? length : 1}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                        }

                    </div>
                </Stack>
            </header>
            {/**
             * Table
             */}
            {
                isMobile ?
                    <div style={{ marginBottom: '250px' }}>
                        {integrationList?.length <= 0 &&
                            <div className='createApiKeyNoData'>
                                <div className='innerNoData' style={{ left: '47%', top: '50%' }}>
                                    <img alt='' style={{ transform: 'scale(0.8)' }} src={integration} />
                                </div>
                            </div>
                        }
                        {
                            integrationList && integrationList.map((item, index) => (
                                <div onClick={() => onClickIntegrationCard(item)} key={index}>
                                    <IntegrationCard
                                        payload={item}
                                        onClickCopy={(event, payload) => onClickCopyApi(event, payload.apiKey)}
                                        onHandleAction={(row, type) => onHandleAction(row, type)}
                                        permission={permission}
                                    />
                                </div>
                            ))
                        }
                        {
                            integrationList?.length > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={length ? length : 0}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 5}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </div>
                        }
                        <div className="bottomAction" ref={componentRef}>
                            {
                                onClickFabBtn &&
                                <Stack>
                                    <Button className='fs-16 fw-medium ff-Roboto btnStyle' style={{
                                        background: colors.header,
                                        color: colors.blackMain
                                    }}>
                                        Integration Docs
                                    </Button>
                                    <Button onClick={(event) => onHandleAddBtn(event, true)} className='fs-16 fw-medium ff-Roboto btnStyle' style={{
                                        background: colors.buttonColor,
                                        color: colors.white
                                    }}>
                                        Create API Key
                                    </Button>
                                </Stack>
                            }

                            <IconButton onClick={(event) => onHandleFabAction(event)} className='mobileaddstyle'>
                                {
                                    onClickFabBtn ?
                                        <ClearIcon style={{ color: colors.white }} />
                                        :
                                        <AddIcon style={{ color: colors.white }} />
                                }
                            </IconButton>
                        </div>
                    </div>
                    :
                    <div className='tableContainer'>
                        <TableContainer sx={{ maxHeight: 'calc(100vh - 128px)' }}>
                            <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                                <EnchanedTableHeader headerCell={headerCell} initialWidth={96.875} />
                                <TableBody className='mainUserBodyTable'>
                                    {
                                        integrationList && integrationList.map((item, index) => (
                                            <TableRow
                                                className={item?.isActiveStatus === '0' ? '' : 'tableRowHover'} style={{ color: item?.isActiveStatus === '0' ? '#AAA' : '#000' }} key={index}>
                                                <TableCell align='center' className='color-inherit' style={{ width: 'calc(4.375%)' }}>
                                                    <Typography className={'ff-Roboto fs-12 fw-regular'}>
                                                        {item.Sno}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='color-inherit' style={{ width: 'calc(100% - 76.875%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                        {item.label}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='hoverCell color-inherit' style={{ width: 'calc(100% - 62.5%)' }}>
                                                    <Stack direction='row' alignItems='center' className='showonHover'>

                                                        {
                                                            item?.isActiveStatus === '0' ? <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                                *************************************************
                                                            </Typography> :
                                                                <>
                                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle' style={{ width: '80%' }}>
                                                                        {item.apiKey}
                                                                    </Typography>
                                                                    <IconButton onClick={(event) => onClickCopyApi(event, item.apiKey)}>
                                                                        <ContentCopyIcon style={{ transform: 'scale(0.7276)' }} />
                                                                    </IconButton>
                                                                </>

                                                        }
                                                    </Stack>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle hideonHover'>
                                                        *************************************************
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className='color-inherit' style={{ width: 'calc(100% - 78.125%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                        {Moment(item.createdAt).format('DD MMM, yyyy')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 86.875%)' }}>
                                                    <RenderIf isTrue={permission && permission.activateAccess === '1'}>
                                                        <Tooltip title={item?.isActiveStatus === '0' ? '' : 'Deactivate'} arrow placement="top"
                                                            disableInteractive
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        fontSize: '12px',
                                                                        fontFamily: 'Roboto',
                                                                        fontWeight: '500',
                                                                        bgcolor: colors.dangerLight,
                                                                        color: colors.danger,
                                                                        borderRadius: '20px',
                                                                        '& .MuiTooltip-arrow': {
                                                                            color: colors.dangerLight,
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <IconButton disabled={item?.isActiveStatus === '0'} style={{ opacity: item?.isActiveStatus === '0' ? '0.5' : '1' }} onClick={() => onHandleAction(item, 'deactivate')} className='hoverBlock'>
                                                                <BlockIcon style={{ color: colors.secondarymain, transform: 'scale(0.8)' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </RenderIf>
                                                    <RenderIf isTrue={permission && permission.deleteAccess === '1'}>
                                                        <Tooltip title='Delete'>
                                                            <IconButton onClick={() => onHandleAction(item, 'delete')} className='hoverIconButton'>
                                                                <DeleteIcon style={{ color: colors.primaryHover, transform: 'scale(0.8888)' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </RenderIf>
                                                    <RenderIf isTrue={permission && permission.activateAccess === '0' && permission.deleteAccess === '0'}>
                                                        --
                                                    </RenderIf>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {integrationList?.length <= 0 &&
                            <div className='createApiKeyNoData'>
                                <div className='innerNoData' style={{ left: '50%', top: '55%' }}>
                                    <img alt='integration' src={integration} style={{ transform: 'scale(0.8)' }} />
                                    <Button onClick={(event) => onHandleAddBtn(event, true)} className='mobileviewStyle' sx={{ ...buttonStyle4, width: '201px', marginRight: '11%', marginTop: '27px' }} variant="contained">
                                        Create API Key
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
            }
            <AddApiDialog open={openAddApiDialog} enterpriseUser={user} onHandleAddClose={(event) => handleDialogClose(event, 'add')} />
            <DeleteApiDialog open={openDeleteDialog} enterpriseUser={user} payload={selectApiKey} type={dialogType} onHandleDeleteClose={(event, type) => handleDialogClose(event, type)} />
        </div>
    )
}