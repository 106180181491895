import "./EcoTemplateCard.scss";
import React, { useState } from "react";
import {
  Button,
  Card,
  Stack,
  Typography,
  useMediaQuery,
  Box,
  Tooltip,
} from "@mui/material";
import { colors } from "./../../../../../utils/colors";
import { buttonStyle2 } from "../../../../../utils/commonStyle";

import refuse from "../../../../../assets/images/refuse.svg";
import recycle from "../../../../../assets/images/recycle.svg";
import reuse from "../../../../../assets/images/reuse.svg";

import ecoTemplateBg from "../../../../../assets/images/eco-template-bg.svg";

export default function EcoTemplateCard({
  template,
  actNow,
  itemId,
  permission,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [hoverState, setHoverState] = useState(false);

  const categoryBg =
    template?.categoryType === "reuse"
      ? "#EFF9FF"
      : template?.categoryType === "recycle"
      ? "#E6FFF5"
      : "#FDF1ED";

  const categoryColor =
    template?.categoryType === "reuse"
      ? "#6EA4CE"
      : template?.categoryType === "recycle"
      ? "#57AF8C"
      : "#F48257";

  const categoryImg =
    template?.categoryType === "reuse"
      ? reuse
      : template?.categoryType === "recycle"
      ? recycle
      : refuse;

  return (
    <Card
      variant="outlined"
      className="ecoTemplateCard flex-column"
      sx={{ width: isMobile ? "unset" : 272 }}
      style={
        isMobile
          ? { height: "152px", marginBottom: 14, marginRight: 0 }
          : { marginBottom: 14 }
      }
    >
      <Stack direction="row" flex="1">
        <Stack flex={1} className="pa-16">
          <Tooltip
            title={template?.info}
            placement="top"
            arrow
            enterTouchDelay={0}
            // disableInteractive
            onOpen={() => setHoverState(true)}
            onClose={() => setHoverState(false)}
            leaveTouchDelay={20000}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  bgcolor: colors.white,
                  color: "#9F9F9F",
                  boxShadow: "0px 3px 6px 0px #00000029",
                  borderRadius: "4px",
                  "& .MuiTooltip-arrow": {
                    color: colors.white,
                  },
                },
              },
            }}
          >
            <Box
              style={{
                border: "1px solid #3787C940",
                borderRadius: "6px",
                width: "32px",
                heigth: "12px",
                marginBottom: "4px",
                background:
                  template?.info != null && hoverState
                    ? colors.primaryHover
                    : colors.white,
              }}
              className="flex-center"
            >
              <Typography
                className="ff-Roboto fs-7"
                style={{
                  color:
                    template?.info != null && hoverState
                      ? colors.white
                      : colors.primaryHover,
                }}
              >
                INFO
              </Typography>
            </Box>
          </Tooltip>
          <Typography className="ff-Roboto" style={{ color: colors.blackMain }}>
            {template?.title}
          </Typography>
          <Stack
            onClick={actNow}
            aria-label="act-now"
            sx={{
              width: 88,
              height: 32,
              cursor: permission?.editAccess === "1" ? "pointer" : "default",
              pointerEvents:
                permission?.editAccess === "1" ? "initial" : "none",
              mt: "14px",
            }}
          >
            <Typography
              className="ff-Roboto fs-12 fw-bold"
              style={{
                color: colors.primaryHover,
                opacity: permission?.editAccess === "1" ? 1 : 0.5,
              }}
            >
              ACT NOW
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{ width: "72px", backgroundColor: categoryBg }}
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <img className="categoryImg" src={categoryImg} alt={"categoryImg"} />
          <Typography
            className="ff-Roboto fs-12 fw-bold"
            style={{
              color: categoryColor,
              textTransform: "uppercase",
              position: "absolute",
              bottom: "13px",
            }}
          >
            {template?.categoryType}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
