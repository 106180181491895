import '../UserManagement.scss';
import React, { useState } from 'react';
import { Stack, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { activateStatusType } from '../../../../utils/commonUtils';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { colors } from '../../../../utils/colors';
import { RenderIf } from '../../../../utils/customHooks';

export default function UserCard({ user, handleViewDetial, handleStatuChangeUser, handleDelete, selectedUser, enterpriseUser }) {

    //menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl)

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box className='userCard'>
            <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                <Stack style={{ width: '70%' }}>
                    <Stack direction='row'>
                        <MailOutlineIcon style={{ color: colors.primaryDark, transform: 'scale(0.8)' }} />
                        <Stack style={{ marginLeft: '10px', width: '100%' }}>
                            <Typography style={{ color: colors.primaryDark }} className='ff-Roboto fs-10 fw-semibold'>Email</Typography>
                            <Typography style={{ color: '#000000', width: '98%' }} className='wordBreak ff-Roboto fs-14 fw-regular twoEllipseStyle'> {user?.emailId} </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' style={{ marginTop: '20px' }}>
                        <GroupOutlinedIcon style={{ color: colors.primaryDark, transform: 'scale(0.8)' }} />
                        <Stack style={{ marginLeft: '10px' }}>
                            <Typography style={{ color: colors.primaryDark }} className='ff-Roboto fs-10 fw-semibold'>Roles</Typography>
                            <Typography style={{ color: '#000000' }} className='ff-Roboto fs-14 fw-regular'> {user?.roleName || '-'} </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                {
                    user.isSelected
                        ?
                        <CheckCircleRoundedIcon style={{ color: '#8AB756' }} />
                        :
                        <>
                            <IconButton
                                id='resource-button'
                                onClick={handleMenuOpen}
                                aria-controls={open ? 'resource-menu' : undefined}
                                aria-haspopup='true'
                                aria-expanded={open ? 'true' : undefined}
                                style={{ padding: '0', pointerEvents: (selectedUser && selectedUser.length > 0) ? 'none' : 'initial' }}
                            >
                                <MoreVertIcon style={{ color: '#474747' }} />
                            </IconButton>
                            <Menu PaperProps={{
                                sx: {
                                    width: '128px',
                                    "& .MuiList-root": {
                                        padding: '0'
                                    }
                                },
                            }}
                                className='appbar' id='resource-menu' anchorEl={anchorEl} open={open}
                                MenuListProps={{
                                    "aria-labelledby": 'resource-button',
                                }}
                                onClose={handleMenuClose}
                            >
                                <RenderIf isTrue={user?.activateAccess}>
                                    <MenuItem disabled={(user?.enterpriseUserId === enterpriseUser?.enterpriseUserId && user?.roleId === 1) || user.activateStatus === activateStatusType.invite} className='ff-Roboto fs-12' onClick={() => handleStatuChangeUser(user)} onClose={handleMenuClose}>
                                        <Stack direction='row' alignItems='center'>
                                            {
                                                user.activateStatus === activateStatusType.active ?
                                                    <PersonOffIcon style={{ color: '#8AB756' }} /> :
                                                    <PersonIcon style={{ color: '#8AB756' }} />
                                            }
                                            <Typography className='ff-Roboto fs-12 fw-semibold' style={{ paddingLeft: '16px' }}>
                                                {

                                                    user.activateStatus === activateStatusType.active ?
                                                        'Deactivate' :
                                                        'Activate'
                                                }
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                </RenderIf>
                                <RenderIf isTrue={user?.deleteAccess}>
                                    <MenuItem disabled={(user?.enterpriseUserId === enterpriseUser?.enterpriseUserId && user?.roleId === 1)} className='ff-Roboto fs-12' onClick={() => handleDelete(user)} onClose={handleMenuClose}>
                                        <Stack direction='row' alignItems='center'>
                                            <DeleteIcon style={{ color: '#8AB756' }} />
                                            <Typography className='ff-Roboto fs-12 fw-semibold' style={{ paddingLeft: '16px' }}>
                                                Delete
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                </RenderIf>
                            </Menu>
                        </>
                }
            </Stack>
            <Stack direction='row' alignItems='center' justifyContent='space-between' style={{ marginTop: '16px' }}>
                <Box className='flex-center'>
                    <div className='statusCircle'
                        style={{
                            background: (user.activateStatus === activateStatusType.invite && colors.invited) ||
                                (user.activateStatus === activateStatusType.active && colors.active) ||
                                (user.activateStatus === activateStatusType.deActivated && colors.deactive),
                            marginLeft: '5px'
                        }}
                    >
                    </div>
                    <Typography className='ff-Roboto fs-10 fw-medium'
                        style={{
                            color: (user.activateStatus === activateStatusType.invite && colors.invited) ||
                                (user.activateStatus === activateStatusType.active && colors.active) ||
                                (user.activateStatus === activateStatusType.deActivated && colors.deactive),
                            marginLeft: '16px',
                            textTransform: 'uppercase'
                        }}>
                        {user?.originalStatus}
                    </Typography>
                </Box>
                <RenderIf isTrue={user?.viewAccess}>
                    <Typography className='ff-Roboto fs-12 fw-semibold' onClick={() => handleViewDetial(user)} style={{ color: '#445C37', pointerEvents: (selectedUser && selectedUser.length > 0) ? 'none' : 'initial' }}>View Details</Typography>
                </RenderIf>
            </Stack>
        </Box >
    )
}
