import '../UserManagement.scss';
import React, { useState, useEffect } from 'react';
import { Dialog, Stack, Typography, IconButton, TextField, MenuItem, DialogActions, Button, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { dialogButtonStyle, dialogButtonCancel } from '../../../../utils/commonStyle';
import { useSelector, useDispatch } from 'react-redux';
import appapi from '../../../../api/apiClient';
import { showmessage } from '../../../../utils/toastr';
import { activateStatusType } from '../../../../utils/commonUtils';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { setLoading } from '../../../../redux/features/appSlice';
import { colors } from '../../../../utils/colors';
import { initUserData } from '../../../../redux/features/authSlice';
import { RenderIf } from '../../../../utils/customHooks';

/**
 * Validation Schema for register
 */
const validationSchema = yup.object({
    email: yup
        .string('Enter your Email')
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is Invalid')
        .required('Email is Required'),
    rolePermissionId: yup
        .string('Select your role')
        .required('Role is Required'),
    name: yup
        .string('Enter your Name')
        .min(3, 'Name must minimum of 3 characters')
        .max(100, 'Name must maximum of 100 characters')
        .required('Name is Required')
        .trim(),
    designation: yup
        .string('Enter your Department')
        .required('Designation is Required')
        .max(50, 'Name must maximum of 50 characters')
        .trim(),
    department: yup
        .string('Enter your Department')
        .required('Deparment is Required')
        .max(50, 'Name must maximum of 50 characters')
        .trim(),
    cityId: yup
        .string('Enter your Location')
        .required('Location is Required')
        .trim()
})

export default function EditUserDialog({ userPermission, open, onHandleEditClose, user, enterpriseUser, onHandleDeleteUser, handleChangeStatus }) {

    const isMobile = useMediaQuery('(max-width:1000px)');

    const dispatch = useDispatch()

    /**
     * dialog style
     */
    const sx = {
        "& .MuiDialog-container": {
            alignItems: isMobile ? "end" : "center",
            WebkitAlignItems: isMobile ? 'end' : 'center',
            WebkitBoxAlign: isMobile ? 'end' : 'center'
        },
        "& .MuiPaper-root": {
            width: '360px',
            // minHeight: '424px',
            // height: isMobile ? '620px' : '620px',
            margin: isMobile ? '0' : '8px',
            padding: '0 16px 23px 16px',
            borderRadius: isMobile ? '16px 16px 0 0' : '4px',
        }
    };

    const { rolesList, citiesList } = useSelector(state => state.app);
    const [roles, setRoles] = useState(rolesList);

    const [data, setData] = useState({
        email: user?.emailId || '',
        rolePermissionId: user?.roleId || '',
        name: user?.name || '',
        designation: user?.designation || '',
        department: user?.department || '',
        cityId: user?.cityId || ''
    })

    useEffect(() => {
        setData({
            email: user?.emailId || '',
            rolePermissionId: user?.roleId || '',
            name: user?.name || '',
            designation: user?.designation || '',
            department: user?.department || '',
            cityId: user?.cityId
        })
        if (userPermission?.editAccess === '2') {
            let filteredValue = rolesList.filter(item => item.roleId !== 1)
            setRoles(filteredValue)
        } else {
            setRoles(rolesList)
        }
    }, [user?.cityId, user?.department, user?.designation, user?.emailId, user?.name, user?.roleId, open]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            update(values)
        },
    })

    const { handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty } = formik;

    const update = async (obj) => {
        dispatch(setLoading(true))
        setData(obj)
        let payload = JSON.parse(JSON.stringify(obj));
        delete payload.email;
        let params = {
            params: {
                enterpriseId: enterpriseUser.enterpriseId,
                enterpriseUserId: user.enterpriseUserId
            }
        }
        const response = await appapi.put(`/user/${enterpriseUser.enterpriseId}/${user.enterpriseUserId}`, payload, params)
        if (response.ok) {
            const data = response.data
            if (data.status === 'success') {
                if (enterpriseUser?.enterpriseUserId === user?.enterpriseUserId) {
                    dispatch(initUserData())
                    onHandleEditClose(false)
                } else {
                    onHandleEditClose(false, 'editAnother')
                    dispatch(setLoading(false))
                }
                // showmessage(`Edited Successfully!!`)
            } else {
                showmessage(data.message, 'error')
                dispatch(setLoading(false))
            }
        } else {
            dispatch(setLoading(false))
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        onHandleEditClose(true);
    }

    return (
        <Dialog
            sx={sx}
            open={open}
            onClose={handleClose}
            className='AddUserContainer'
        >
            <Stack style={{ paddingBottom: '10px', marginBottom: '16px', position: 'sticky', top: '0', paddingTop: '23px', zIndex: '99', background: '#FFF' }} direction='row' justifyContent='space-between' alignItems='center' >
                <div className='flex'>
                    {/* <PersonIcon style={{ color: '#8AB756' }} /> */}
                    <Typography style={{ color: colors.blackMain }} className='fw-bold fs-14 ff-Roboto' >EDIT USER</Typography>
                </div>
                <div>
                    {
                        (enterpriseUser?.enterpriseUserId === user?.enterpriseUserId && user?.roleId === 1) ? '' :
                            <>
                                <RenderIf isTrue={user?.deleteAccess}>
                                    <IconButton onClick={() => onHandleDeleteUser(user, 'fromEdit')}>
                                        <DeleteIcon className='svgTransform' style={{ color: colors.primaryTxt }} />
                                    </IconButton>
                                </RenderIf>
                                <RenderIf isTrue={user?.activateAccess}>
                                    {user?.activateStatus === 'invite' ? null :
                                        <IconButton onClick={() => handleChangeStatus(user)}>
                                            {
                                                user?.activateStatus === activateStatusType.active ?
                                                    <PersonOffIcon style={{ color: colors.primaryTxt }} /> :
                                                    <PersonIcon style={{ color: colors.primaryTxt }} />
                                            }
                                        </IconButton>
                                    }
                                </RenderIf>
                            </>
                    }
                    <IconButton onClick={() => onHandleEditClose(true)}>
                        <ClearIcon style={{ color: colors.blackMain, transform: 'scale(1.143)' }} />
                    </IconButton>
                </div>
            </Stack>
            <form onSubmit={handleSubmit} className='editContainer'>
                <Stack>
                    <Stack>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Email</Typography>
                        <TextField
                            fullWidth
                            id='email'
                            name='email'
                            value={values.email || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={values.email ? true : false}
                            // error={touched.email && Boolean(errors.email)}
                            InputProps={{
                                classes: {
                                    disabled: 'disabled'
                                }
                            }}
                        />
                    </Stack>
                    <Stack className='mt-14'>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Select Role</Typography>
                        <TextField
                            fullWidth
                            id='rolePermissionId'
                            name='rolePermissionId'
                            value={values.rolePermissionId || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            select
                        // error={touched.rolePermissionId && Boolean(errors.rolePermissionId)}
                        >
                            {roles.map((item) => (
                                <MenuItem fullWidth key={item.roleId} value={item.roleId}> {item.roleName} </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                    <Stack className='mt-14'>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Name</Typography>
                        <TextField
                            fullWidth
                            id='name'
                            name='name'
                            value={values.name.replace(/^\s/g, '') || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        // error={touched.name && Boolean(errors.name)}
                        />
                    </Stack>
                    <Stack className='mt-14'>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Designation</Typography>
                        <TextField
                            fullWidth
                            id='designation'
                            name='designation'
                            value={values.designation.replace(/^\s/g, '') || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        // error={touched.designation && Boolean(errors.designation)}
                        />
                    </Stack>
                    <Stack className='mt-14'>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Department</Typography>
                        <TextField
                            fullWidth
                            id='department'
                            name='department'
                            value={values.department.replace(/^\s/g, '') || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        // error={touched.department && Boolean(errors.department)}
                        />
                    </Stack>
                    <Stack className='mt-14'>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Location</Typography>
                        <TextField
                            fullWidth
                            id='cityId'
                            name='cityId'
                            value={values.cityId || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            select
                        // error={touched.cityId && Boolean(errors.cityId)}
                        >
                            {citiesList.map((item) => (
                                <MenuItem fullWidth key={item.cityId} value={item.cityId}> {item.cityName} </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                    <DialogActions style={{
                        height: '66px',
                        justifyContent: 'space-between',
                        padding: '0',
                        marginTop: '20px'
                    }}>
                        <Button sx={dialogButtonCancel} variant='outlined' onClick={() => onHandleEditClose(true)}>Cancel</Button>
                        <Button sx={dialogButtonStyle} disabled={!(dirty && isValid)} type='submit' variant="contained">Update</Button>
                    </DialogActions>
                </Stack>
            </form>
            {/* <Loader loading={true} /> */}
        </Dialog>
    )
}
