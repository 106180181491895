import './DesktopModeSetting.scss';
import { Button, Stack, Typography } from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';

import { colors } from './../../../../../utils/colors';
import UserProfile from '../UserProfile/UserProfile';
import ChangePassword from '../ChangePassword/ChangePassword';
import Organization from '../Organization/Organization';
import Contact from '../Contact/Contact';
import wavesbg from '../../../../../assets/images/wavesbg.svg';

const menus = [
    {lable: 'User Profile', icon: <PersonIcon />},
    {lable: 'Change Password', icon: <LockIcon />},
    {lable: 'Organization', icon: <BusinessIcon />},
    {lable: 'Contact Us', icon: <PhoneIcon />},
];
  

export default function DesktopModeSetting({activeMenuIndex = 0, onMenuChange}) {
   const sx = 
    {
        mb: '8px', 
        height: '48px', 
        justifyContent: 'flex-start',
        textTransform: 'none'
    };

    const handleMenuChange = (index) => {
        if(index !== activeMenuIndex) {
            onMenuChange(index);
        }
    }

    return (
        <Stack flex={1} direction="row" className='desktopModeSettingConatiner'>
            <Stack direction="column" sx={{width: '256px'}} className='sideMenu'>
                <Typography className="ff-Roboto fw-bold" sx={{color: colors.blackMain, mb: '45px'}}>SETTINGS</Typography>
                <Stack flexGrow={1}>
                    {menus.map((item, index) => <Stack direction="row">
                        <Button onClick={() => handleMenuChange(index)} disableElevation color="primary" className='ff-Roboto fs-14' sx={sx} fullWidth aria-label={item.lable} key={index} variant={activeMenuIndex === index ? 'contained': 'none'} startIcon={item.icon}>{item.lable}</Button>
                    </Stack> )}
                </Stack>
                <Stack >
                    <Typography className="ff-Roboto fs-10" sx={{color: colors.blackMain}}>Version 1.0.0</Typography>
                    <Typography className="ff-Roboto fs-10" sx={{color: colors.blackMain}}>© 2022 Do It Zero Inc.</Typography>
                </Stack>
            </Stack>
            <Stack flexGrow={1} className="sideMenuContent" style={{ backgroundImage: `url(${wavesbg})`, backgroundPosition: '95% bottom', backgroundRepeat: 'no-repeat', backgroundSize: '350px'}}>
               {activeMenuIndex === 0 && <UserProfile />}
               {activeMenuIndex === 1 && <ChangePassword />}
               {activeMenuIndex === 2 && <Organization />}
               {activeMenuIndex === 3 && <Contact />}
            </Stack>
        </Stack>
    );
}