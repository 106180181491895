import React, { useState } from "react";
import {
  Stack,
  useMediaQuery,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { colors } from "../../../../../../utils/colors";
import { setLoading } from "../../../../../../redux/features/appSlice";
import appapi from "../../../../../../api/apiClient";
import APPCONFIG from "../../../../../../config/apiConfig";
import DragAndDrop from "../../../../../../components/DragAndDrop/DragAndDrop";
import FormTextField from "../../../../../../components/FormTextField";
import { dialogButtonStyle } from "../../../../../../utils/commonStyle";
import { s3FilePathConverter } from "../../../../../../utils/s3FilePathConverter";

export default function EcoCampaignAdditionalInfo() {
  const isMobile = useMediaQuery("(max-width:1000px)");

  const dispatch = useDispatch();

  const { citiesList, countryList } = useSelector((state) => state.app);

  const { user } = useSelector((state) => state.auth);

  const [file, setFile] = useState(null);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
      if(file) {
          handleUploadClick();
      }
  },[file])

  //handle upload will do the upload in aws
  const handleUploadClick = async (e) => {
    dispatch(setLoading(true));
    const ext = file.name.split(".")[1];
    const key = `ecoAction/${Date.now()}.${ext}`;
    const signedUrlResult = await appapi.post("/s3", {
      fileName: key,
      contentType: file.type,
      type: 'app'
    });
    if (signedUrlResult.ok) {
      const signedUrl = signedUrlResult.data.url;
      fetch(signedUrl, { method: "PUT", body: file })
        .then((res) => {
          if (res.ok) {
            setFieldValue("brandLogo", signedUrlResult.data.key);
          } else {
          }
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.log(
            "There has been a problem with fetching signed url: " +
            error.message
          );
        });
    } else {
    }
  };

  const handleFile = (file) => {
    if (file) {
      setFile(file);
    } else {
      setFieldValue("brandLogo", '');
      setFile(null);
    }
  };

  const openFilePicker = () => {
    const fileInput = document.getElementById('brandLogo');
    if(fileInput) {
      fileInput.click();
    }
  }

  return (
    <Stack className={isMobile ? "mt-14" : ""}>
      <Typography
          className="fs-14 ff-Roboto fw-medium mb-7"
          color={colors.blackMain}
        >
          Brand Logo
        </Typography>
      <DragAndDrop url={values.brandLogo ? s3FilePathConverter(values.brandLogo) : null} inputId="brandLogo" handleFile={(files) => handleFile(files)} />
      <Button
        disabled={Boolean(values?.brandLogo)}
        onClick={openFilePicker}
        sx={{ ...dialogButtonStyle, marginTop: "8px", width: "100%" }}
        variant="contained"
      >
        Upload
      </Button>
      {touched.brandLogo && Boolean(errors.brandLogo) && <Typography className="err-msg">
        {(touched.brandLogo || dirty.brandLogo) && errors.brandLogo}
      </Typography>}
      <Stack
        className="mt-16"
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing="8px"
      >
        <Stack flex={1} style={isMobile ? { width: '100%' } : {}}>
          <FormTextField label="Brand*" name="brandName" sx={{ mb: 0 }}  whiteSpace={true} />
        </Stack>
        <Stack flex={1} style={isMobile ? { width: '100%' } : {}}>
          <FormTextField label="Sub Brand" name="subBrandName" sx={{ mb: 0 }}  whiteSpace={true} />
        </Stack>
      </Stack>
      <Stack className="mt-16">
        <FormTextField label="Product" name="product" sx={{ mb: 0 }}  whiteSpace={true} />
      </Stack>
      {/* <Stack className="mt-16">
        <Typography
          className="fs-14 ff-Roboto fw-medium mb-7"
          color={colors.blackMain}
        >
          City*
        </Typography>
        <TextField
          fullWidth
          id="cityId"
          name="cityId"
          value={values.cityId || ""}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          // error={touched.cityId && Boolean(errors.cityId)}
          // helperText={(touched.cityId || dirty.cityId) && errors.cityId}
          select
        >
          {citiesList.map((item) => (
            <MenuItem key={item.cityId} value={item.cityId}>
              {" "}
              {item.cityName}{" "}
            </MenuItem>
          ))}
        </TextField>
      </Stack> */}
      <Stack className="mt-16">
        <Typography
          className="fs-14 ff-Roboto fw-medium mb-7"
          color={colors.blackMain}
        >
          Country*
        </Typography>
        <TextField
          fullWidth
          id="countryId"
          name="countryId"
          value={values.countryId || ""}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          // error={touched.countryId && Boolean(errors.countryId)}
          // helperText={(touched.countryId || dirty.countryId) && errors.countryId}
          select
        >
          {countryList.map((item) => (
            <MenuItem key={item.countryId} value={item.countryId}>
              {" "}
              {item.countryName}{" "}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
}
