import { Box, Stack, Typography } from "@mui/material";
import { colors } from "../../../../../../utils/colors";
import React from "react";
import { useFormikContext } from "formik";
import FormTextField from "../../../../../../components/FormTextField";

export default function EcoCampaignDetails() {
  const { values } = useFormikContext();

  return (
    <React.Fragment>
      <FormTextField label="Name*" name="title" whiteSpace={true} />
      <FormTextField
        label="Short Description* (50 chars)"
        name="shortDescription"
        whiteSpace={true}
      />
      {/* <Stack sx={{ mb: "22px" }}>
        <Typography
          style={{ color: colors.blackMain }}
          className="fs-14 ff-Roboto fw-medium mb-7"
        >
          Detailed Description (500 chars)
        </Typography>
        <Box
          style={{
            padding: "16px",
            backgroundColor: "rgba(73, 96, 115, 0.11)",
            minHeight: '176px'
          }}
        >
          <Typography>
            {values?.detailedDescription}
          </Typography>
        </Box>
      </Stack> */}
      <FormTextField
        label="Detailed Description (500 chars)"
        name="detailedDescription"
        whiteSpace={true}
        multiline
        rows={4}
      />
    </React.Fragment>
  );
}
