import '../Integration.scss';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/features/appSlice';
import appapi from '../../../../api/apiClient';
import { Dialog, Stack, Typography, DialogActions, Button, TextField } from '@mui/material';
import { colors } from '../../../../utils/colors';
import { dialogButtonStyle, dialogButtonCancel } from '../../../../utils/commonStyle';
import { showmessage } from '../../../../utils/toastr';

const sx = {
    "& .MuiDialog-container": {
        alignItems: "center",
    },
    "& .MuiPaper-root": {
        width: '400px',
        minHeight: '160px',
        padding: '24px 16px 16px 16px',
        borderRadius: '4px',
    }
};

export default function DeleteApiDialog({ open, enterpriseUser, payload, onHandleDeleteClose, type }) {

    const dispatch = useDispatch()
    const [confirmVal, setConfirmVal] = useState('');


    const handleDelete = async () => {
        dispatch(setLoading(true))

        let params = {
            enterpriseId: enterpriseUser?.enterpriseId,
            integrationId: payload?.integrationId
        }

        let response;

        if (type === 'delete') {
            response = await appapi.delete(`/integration/${enterpriseUser?.enterpriseId}/${payload?.integrationId}`, params)
        } else {
            response = await appapi.put(`/integration/${enterpriseUser?.enterpriseId}/deactivate/${payload?.integrationId}`, {}, params)
        }

        if (response.ok) {
            dispatch(setLoading(false));
            const data = response.data;
            if (data.status === 'success') {
                // showmessage(`${type === 'delete' ? 'Deleted' : 'Deactivated'} Successfully!!`)
                onHandleDeleteClose(false, type)
                setConfirmVal('')
            } else {
                showmessage(data.message, 'error')
            }
        } else {
            dispatch(setLoading(false));
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setConfirmVal('')
        onHandleDeleteClose(true, type);
    }

    return (
        <Dialog
            sx={sx}
            open={open}
            onClose={handleClose}
            // TransitionComponent={Transition}
            className='AddUserContainer'
        >
            <Stack direction='row'>
                <Stack style={{ width: '100%' }}>
                    <Typography style={{ color: colors.dangerDark, marginBottom: '12px' }} className='ff-Roboto fw-semibold wordBreak'>
                        {type === 'delete' ? 'Delete' : 'Deactivate'} API KEY
                    </Typography>
                    <Typography style={{ color: colors.blackMain }} className='ff-Roboto fw-regular fs-12 wordBreak'>
                        Are you sure you want to permanently {type === 'delete' ? 'delete' : 'deactivate'} your api-key ? This is not a reversible action.
                    </Typography>
                </Stack>
            </Stack>
            <Stack style={{ marginTop: '10px' }}>
                <TextField
                    fullWidth
                    placeholder={`Type "${type}" to confirm`}
                    id='delete'
                    name='delete'
                    value={confirmVal || ''}
                    onChange={(e) => setConfirmVal(e.target.value.toLowerCase())}
                />
            </Stack>
            <DialogActions style={{
                height: '66px',
                padding: '0',
                marginTop: '20px',
                width: '100%'
            }}>
                <Button sx={dialogButtonCancel} style={{ width: '128px' }} variant='outlined' onClick={() => { onHandleDeleteClose(true, type); setConfirmVal(''); }}>Cancel</Button>
                <Button sx={dialogButtonStyle}
                    style={{
                        width: '136px', marginLeft: '24px', background: '#C71212',
                        opacity: confirmVal === type ? '1' : '0.5', cursor: confirmVal === type ? 'pointer' : 'default',
                        pointerEvents: confirmVal === type ? 'initial' : 'none'
                    }} onClick={handleDelete} variant="contained">
                    {type === 'delete' ? 'Delete' : 'Deactivate'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
