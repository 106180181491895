import './ReportSection.scss';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Stack, Typography, IconButton, Box, CircularProgress } from '@mui/material';
import { colors } from '../../../../../utils/colors';
import ClearIcon from '@mui/icons-material/Clear';
import downloadcircle from '../../../../../assets/images/downloadcircle.svg';

export default function ReportCard({ item, index, handleGetDownload, handleDownloadReport }) {
    return (
        <div className='reportMobileCard' key={index}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <div>
                    <Typography className='fs-14 ff-Roboto fw-medium capitalize' color={colors.primaryHover}>
                        {item?.reportType || '-'}
                    </Typography>
                    <Typography className='fs-12 ff-Roboto fw-regular black'>
                        {item?.downloadedDate || '-'}
                    </Typography>
                </div>
                {
                    item?.getdownloads &&
                    <IconButton onClick={() => handleGetDownload(item)} style={{ height: '32px', width: '32px' }}>
                        <DownloadIcon style={{ transform: 'scale(0.706)' }} />
                    </IconButton>
                }
                {
                    item?.pending &&
                    <IconButton className='flex-center' style={{ position: 'relative', cursor: 'pointer' }}>
                        <ClearIcon style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%) scale(0.5)' }} />
                        <CircularProgress
                            size={20}
                            color="success" />
                    </IconButton>
                }
                {
                    item?.download &&
                    <IconButton onClick={() => handleDownloadReport(item)} style={{ height: '32px', width: '32px' }}>
                        <img src={downloadcircle} alt="downloadcircle" />
                    </IconButton>
                }
            </Stack>
        </div>
    )
}
