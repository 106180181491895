import "./EcoActionsContainer.scss"
import { Stack, Typography, useMediaQuery } from "@mui/material"
import dizlogoOrigin from "../../../../assets/images/dizlogoOrigin.svg"
import apple from "../../../../assets/images/apple.svg"
import playstore from "../../../../assets/images/playStore.svg"
import { useSelector } from "react-redux"

export default function EcoActionsContainer({ children }) {
  const isMobile = useMediaQuery("(max-width:1000px)")
  const { isLoading } = useSelector(state => state.app)

  return (
    <div className="container">
      <div style={isMobile ? { padding: "24px" } : { padding: 45 }}>
        <img className="logo" alt="dizLogo" src={dizlogoOrigin} />
      </div>
      <Stack flex={1} className="mt-9" direction={"row"} alignItems={"center"} justifyContent={"center"}>
        {children}
      </Stack>
      {!isLoading && (
        <Stack flex={1} className="mt-24" direction={"row"} alignItems={"center"} justifyContent={"center"}>
          <a href="https://d.doitzero.com/app" rel="noreferrer" target="_blank">
            <img className="icon" alt="dizLogo" src={apple} />
          </a>
          <a href="https://d.doitzero.com/app" rel="noreferrer" target="_blank">
            <img className="icon" alt="dizLogo" src={playstore} />
          </a>
        </Stack>
      )}
      {!isLoading && (
        <Stack flex={1} className="mt-24" direction={"row"} alignItems={"center"} justifyContent={"center"} style={{ paddingBottom: 24 }}>
          <Typography className="ff-Roboto fs-10" sx={{ color: "#666666" }}>
            © 2022 Do It Zero Inc.
          </Typography>
        </Stack>
      )}
    </div>
  )
}
