import './ReportSection.scss'
import React, { useEffect, useState } from 'react';
import { TablePagination, useMediaQuery, Typography, Stack } from '@mui/material';
import { colors } from '../../../../../utils/colors';
import TablePaginationActions from '../../../../../components/TablePaginationActions';
import ReportTable from './ReportTable';
import ReportCard from './ReportCard';
import appapi from '../../../../../api/apiClient';
import { useSelector } from 'react-redux';

export default function ReportSection() {

    const isMobile = useMediaQuery('(max-width:1000px)');
    const { user } = useSelector(state => state.auth);

    const [reportList, setReportList] = useState(null);

    const [length, setLength] = useState(null);
    const [page, setPage] = useState(0);
    const [backendpage, setBackendPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [getDownload, setGetDownload] = useState(false)

    const handleChangePage = (event, newPage) => {
        setBackendPage(newPage + 1)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setBackendPage(1)
        setPage(0);
    };

    //initial Call
    useEffect(() => {
        handleGetReportList()
    }, []);

    const handleGetDownload = (row) => {
        let arr = [...reportList];
        arr.map((item) => {
            if (item.reportType === row.reportType) {
                item.getdownloads = false;
                item.pending = true;
                setGetDownload(true)
            }
        })
        setReportList(arr)
    }

    useEffect(() => {
        if (getDownload) {
            let arr = [...reportList];

            arr.map((item) => {
                if (item.pending === true) {
                    let timer = setTimeout(() => {

                        item.pending = false;
                        item.download = true;
                        clearTimeout(timer)
                        console.log(item)
                        console.log(arr)
                        setReportList(arr)
                    }, 2000);
                }
            })

            setGetDownload(false)
        }
    }, [getDownload, reportList]);

    const handleDownloadReport = async (row) => {
        console.log(row);
        const response = await appapi.get(`/analytics/${user.enterpriseId}/generateReports`, {
            enterpriseId: user.enterpriseId,
            filter: row.reportType,
            downloadedBy: user.name
        }, { observe: 'response', responseType: 'blob' })
        // console.log(response)
        if (response.ok) {
            // console.log(response.data)
            const url = window.URL.createObjectURL(response.data);
            // console.log(url)
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `${row.reportType}-${new Date().getTime()}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            row.download = false;
            row.getdownloads = true;
            let arr = [...reportList];
            arr.map((item) => {
                if (item.reportType === row.reportType) {
                    item.download = false;
                    item.getdownloads = true;
                }
            })
            setReportList(arr)
        }
    }

    const handleGetReportList = async () => {
        const response = await appapi.get(`/analytics/${user.enterpriseId}/reportsList`, {
            enterpriseId: user.enterpriseId,
        })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data;
                userData.map((item) => {
                    item.getdownloads = true;
                    item.pending = false;
                    item.download = false;
                })
                setReportList(userData);
            }
        }
    }

    return (
        <div className='inputFormStyle' style={{ paddingBottom: '20px' }}>
            {/**
               * Top Toolbar
               */}
            <header className={isMobile && 'headerMobile'} style={{ marginBottom: '8px', padding: isMobile ? '0' : '8px  0', position: 'initial' }}>
                <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                    <Typography className='ff-Roboto fw-medium text-upper' style={{ color: colors.blackMain, paddingTop: isMobile ? '17px' : '0' }}>
                        Reports
                    </Typography>
                    <div className='flex-aligncenter'>
                        {/* {
                            isMobile ? null :
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={length ? length : 1}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                        } */}
                    </div>
                </Stack>
            </header>
            {
                isMobile ?
                    <>
                        {
                            reportList?.map((item, index) => (
                                <ReportCard item={item} index={index}
                                    handleGetDownload={(row) => handleGetDownload(row)}
                                    handleDownloadReport={(row) => handleDownloadReport(row)}
                                />
                            ))
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            {/* <TablePagination
                                rowsPerPageOptions={[]}
                                count={length ? length : 1}
                                rowsPerPage={rowsPerPage ? rowsPerPage : 5}
                                page={page ? page : 0}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            /> */}
                        </div>
                    </>
                    :
                    <ReportTable
                        length={length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        reportList={reportList}
                        handleGetDownload={(row) => handleGetDownload(row)}
                        handleDownloadReport={(row) => handleDownloadReport(row)}
                    />
            }
        </div>
    )
}
