import React, { useState, useEffect } from 'react'
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Box, useTheme, IconButton, TextField, MenuItem } from '@mui/material'

export default function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const [pageArr, setPageArr] = useState([]);

    useEffect(() => {
        let totalPage = Math.ceil(count / rowsPerPage);
        let arr = []
        for (let i = 1; i <= totalPage; i++) {
            arr.push(i);
        }
        setPageArr(arr);
    }, [count, rowsPerPage]);

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handlePageSelect = (event) => {
        onPageChange(event, event.target.value - 1)
    }

    return (
        <Box className='tablepagination' sx={{ flexShrink: 0, ml: 2.5 }}>
            <div className='textFieldSelect'>
                <TextField
                    id='pageSelect'
                    name='pageSelect'
                    value={pageArr?.length > 0 ? page + 1 : ''}
                    onChange={handlePageSelect}
                    select
                >
                    {pageArr && pageArr.map((item) => (
                        <MenuItem key={item} value={item}> {item} </MenuItem>
                    ))}
                </TextField>
            </div>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}