import { Button, MenuItem, Stack, TextField, Typography, useMediaQuery } from "@mui/material"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import appapi from "../../../../../api/apiClient"
import DragAndDrop from "../../../../../components/DragAndDrop/DragAndDrop"
import FormTextField from "../../../../../components/FormTextField"
import APPCONFIG from "../../../../../config/apiConfig"
import { setLoading } from "../../../../../redux/features/appSlice"
import { initUserData, logout } from "../../../../../redux/features/authSlice"

import { colors } from "../../../../../utils/colors"
import { buttonStyle2 } from "../../../../../utils/commonStyle"
import { s3FilePathConverter } from "../../../../../utils/s3FilePathConverter"
import { showmessage } from "../../../../../utils/toastr"
import DeleteAccountConfirmation from "../DeleteAcountConfirmation"

/**
 * Validation Schema
 */
const validationSchema = yup.object({
  email: yup
    .string("Enter your Email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is Invalid")
    .required("Email is Required"),
  mobile: yup
    .string()
    .min(10, "Mobile Number must be 10 digits")
    .matches(/^[0-9]*$/, "Mobile Number should be only numbers")
    .label("Mobile Number"),
  name: yup.string("Enter your Name").min(3, "Name must minimum of 3 characters").max(100, "Name must maximum of 100 characters").required("Name is Required").trim(),
  designation: yup.string("Enter your Designation").required("Designation is Required").max(50, "Designation must maximum of 50 characters").trim(),
  department: yup.string("Enter your Department").required("Deparment is Required").max(50, "Deparment must maximum of 50 characters").trim(),
  cityId: yup.string("Enter your Location").required("Location is Required").trim(),
})

export default function UserProfile() {
  const isMobile = useMediaQuery("(max-width:600px)")

  const containerSX = isMobile ? { mt: "19px" } : { width: "718px", mt: "21px" }

  const textFieldDirection = isMobile ? "column" : "row"

  const formRef = useRef()

  const dispatch = useDispatch()

  const { user } = useSelector(state => state.auth)

  const { citiesList } = useSelector(state => state.app)

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [file, setFile] = useState(null)

  const handleFile = file => {
    if (file) {
      setFile(file)
    } else {
      formRef?.current?.setFieldValue("profileImage", null)
      setFile(null)
    }
  }

  useEffect(() => {
    if (file) {
      handleUploadClick()
    }
  }, [file])

  const handleUploadClick = async () => {
    dispatch(setLoading(true))
    const ext = file.name.split(".")[1]
    const key = `orgProfile/${Date.now()}.${ext}`
    const signedUrlResult = await appapi.post("/s3", {
      fileName: key,
      contentType: file.type,
    })
    if (signedUrlResult.ok) {
      const signedUrl = signedUrlResult.data.url
      fetch(signedUrl, { method: "PUT", body: file })
        .then(res => {
          if (res.ok) {
            formRef.current?.setFieldValue("profileImage", signedUrlResult.data.key)
            // setFileUploaded(true);
          } else {
          }
          dispatch(setLoading(false))
        })
        .catch(error => {
          dispatch(setLoading(false))
          console.log("There has been a problem with fetching signed url: " + error.message)
        })
    } else {
    }
  }

  const updateProfile = async values => {
    dispatch(setLoading(true))
    let payload = {
      ...values,
      profileImage: values?.profileImage || null,
      rolePermissionId: user.roleId,
    }
    delete payload.email
    const response = await appapi.put(`/user/${user.enterpriseId}/${user.enterpriseUserId}`, payload)
    if (response.ok) {
      const data = response.data
      if (data.status === "success") {
        dispatch(initUserData())
      } else {
        showmessage(data.message, "error")
      }
      dispatch(setLoading(false))
    } else {
      dispatch(setLoading(false))
      showmessage(`Something Went Wrong!`, "error", 2000)
    }
  }

  const openFilePicker = () => {
    const fileInput = document.getElementById("profileImage")
    if (fileInput) {
      fileInput.click()
    }
  }

  const deleteAccount = () => {
    setDeleteConfirmation(true)
  }

  const handleDeleteConfirmationClose = val => {
    setDeleteConfirmation(false)
    if (!val) {
      localStorage.removeItem("diz")
      dispatch(logout())
    }
  }

  return (
    <Stack>
      <Typography className="ff-Roboto fw-bold" sx={{ color: colors.primaryHover }}>
        USER PROFILE
      </Typography>
      <Stack sx={containerSX}>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            email: user?.emailId || "",
            name: user?.name || "",
            designation: user?.designation || "",
            department: user?.department || "",
            cityId: user?.cityId,
            profileImage: user?.profileImage || null,
            mobile: user?.mobile || "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => updateProfile(values)}
        >
          {({ handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty }) => (
            <Form noValidate autoComplete="off">
              {/* <Stack sx={{ mb: isMobile ? 0 : "22px" }}  spacing={isMobile ? 0 : "62px"}>
                                <Stack flex={1}>
                                    <FormTextField label="Email" name="email" sx={{ mb: isMobile ? '22px' : 0 }} disabled={values.email ? true : false} />
                                </Stack>

                                <Stack flex={1}>
                                    <FormTextField label="Name" name="name" sx={{ mb: isMobile ? '22px' : 0 }} inputSx={{ textTransform: 'capitalize' }} whiteSpace={true} />
                                </Stack>
                            </Stack>
                            <Stack sx={{ mb: isMobile ? 0 : "22px" }}  spacing={isMobile ? 0 : "62px"}>
                                <Stack flex={1}>
                                    <FormTextField label="Designation" name="designation" sx={{ mb: isMobile ? '22px' : 0 }} whiteSpace={true} />
                                </Stack>
                                <Stack flex={1}>
                                    <FormTextField label="Department" name="department" sx={{ mb: isMobile ? '22px' : 0 }} whiteSpace={true} />
                                </Stack>
                            </Stack>
                            <Stack sx={{ mb: isMobile ? 0 : "22px" }} direction={textFieldDirection} spacing={isMobile ? 0 : "62px"}>
                                <Stack flex={1} sx={{ mb: isMobile ? '22px' : 0 }}>
                                    <Typography
                                        style={{ color: colors.blackMain }}
                                        className="fs-14 ff-Roboto fw-medium mb-7"
                                    >
                                        Location
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id='cityId'
                                        name='cityId'
                                        value={values.cityId || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        select
                                    // error={touched.cityId && Boolean(errors.cityId)}
                                    >
                                        {citiesList.map((item) => (
                                            <MenuItem fullWidth key={item.cityId} value={item.cityId}> {item.cityName} </MenuItem>
                                        ))}
                                    </TextField>
                                </Stack>
                                <Stack flex={1}></Stack>
                            </Stack> */}
              <Stack direction={textFieldDirection} spacing={isMobile ? 0 : "32px"}>
                <Stack sx={isMobile ? { width: "100%", mb: "22px" } : { width: "232px" }}>
                  <DragAndDrop
                    backgroundColor="#FFFFFF"
                    helpText="Upload your profile picture. (File dimensions should be 300x300 px upto 1 MB)"
                    url={values?.profileImage ? s3FilePathConverter(values?.profileImage) : null}
                    inputId="profileImage"
                    handleFile={files => handleFile(files)}
                    showTitle={false}
                  />
                  <Button type="button" sx={{ borderColor: "#106AA580", color: colors.primary, width: isMobile ? "100%" : "232px", alignSelf: "center", mt: "12px" }} variant="outlined" disabled={Boolean(values?.profileImage)} onClick={openFilePicker}>
                    Upload
                  </Button>
                </Stack>
                <Stack sx={isMobile ? { width: "100%" } : { width: "328px" }}>
                  <FormTextField label="Email" name="email" disabled={values.email ? true : false} />
                  <FormTextField label="Mobile Number" inputProps={{ maxLength: 10 }} name="mobile" sx={{ mb: 0 }} />
                  <FormTextField label="Name" name="name" inputSx={{ textTransform: "capitalize" }} whiteSpace={true} />
                  <FormTextField label="Designation" name="designation" whiteSpace={true} />
                  <FormTextField label="Department" name="department" whiteSpace={true} />
                  <Stack flex={1} sx={{ mb: "22px" }}>
                    <Typography style={{ color: colors.blackMain }} className="fs-14 ff-Roboto fw-medium mb-7">
                      Location
                    </Typography>
                    <TextField
                      fullWidth
                      id="cityId"
                      name="cityId"
                      value={values.cityId || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      select
                      // error={touched.cityId && Boolean(errors.cityId)}
                    >
                      {citiesList.map(item => (
                        <MenuItem fullWidth key={item.cityId} value={item.cityId}>
                          {" "}
                          {item.cityName}{" "}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Button sx={{ ...buttonStyle2, width: isMobile ? "100%" : "328px" }} disabled={!(dirty && isValid)} type="submit">
                    SAVE
                  </Button>
                  <Button onClick={() => deleteAccount()} variant="outlined" sx={{ width: isMobile ? "100%" : "328px", mt: "22px", height: "56px" }} type="button" color="error">
                    DELETE PROFILE
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
      <DeleteAccountConfirmation open={deleteConfirmation} onHandleDeleteClose={handleDeleteConfirmationClose} />
    </Stack>
  )
}
