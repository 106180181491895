import './Organization.scss';
import { Button, MenuItem, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { useEffect } from 'react';

import { colors } from "../../../../../utils/colors";
import { buttonStyle2 } from "../../../../../utils/commonStyle";
import FormTextField from '../../../../../components/FormTextField';
import { setLoading } from '../../../../../redux/features/appSlice';
import { showmessage } from '../../../../../utils/toastr';
import appapi from '../../../../../api/apiClient';
import { initUserData } from '../../../../../redux/features/authSlice';
import APPCONFIG from '../../../../../config/apiConfig';
import DragAndDrop from '../../../../../components/DragAndDrop/DragAndDrop';
import { s3FilePathConverter } from '../../../../../utils/s3FilePathConverter';

/**
 * Validation Schema 
 */
const validationSchema = yup.object({
    logo: yup
        .string('Enter your Enterprise logo').nullable(),
    // .required('Enterprise logo is Required'),
    enterpriseName: yup
        .string('Enter your Enterprise Name')
        .min(3, 'Enterprise Name must minimum of 3 characters')
        .max(100, 'Enterprise Name must maximum of 100 characters')
        .required('Enterprise Name is Required')
        .trim(),
    address: yup
        .string('Enter your Enterprise')
        // .required('Enterprise Address is Required')
        .max(200, 'Enterprise Address must maximum of 200 characters')
        .trim(),
    countryId: yup
        .string('Enter your Enterprise Country'),
    pincode: yup
        .string('Enter your Enterprise Pincode')
        // .required('Enterprise Pincode is Required')
        .min(6, 'Enterprise Pincode must minimum of 6 characters')
        .max(6, 'Enterprise Pincode must maximum of 6 characters')
        .trim(),
    gstNumber: yup
        .string('Enter your GSTIN')
        // .required('GSTIN Number is Required')
        .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'GSTIN is Invalid')
        .trim()
});

export default function Organization() {

    const isMobile = useMediaQuery("(max-width:600px)");

    const containerSX = isMobile ? { mt: '50px', width: '100%' } : { width: '328px', mt: '67px' };

    const formRef = useRef();

    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    // const [fileUploaded, setFileUploaded] = useState(false);

    const { user } = useSelector((state) => state.auth);
    const { countryList } = useSelector((state) => state.app);

    const [access, setAccess] = useState(null)

    const { permission } = user;

    const updateEnterprise = async (payload) => {
        dispatch(setLoading(true));
        const response = await appapi.put(`/enterprise/${user.enterpriseId}`, {
            ...payload,
            pincode: payload.pincode ? parseInt(payload.pincode) : null,
            gstNumber: payload.gstNumber.trim(),
            logo: payload.logo ? payload.logo : null
        })
        if (response.ok) {
            const data = response.data
            if (data.status === 'success') {
                dispatch(initUserData())
            } else {
                showmessage(data.message, 'error')
            }
            dispatch(setLoading(false))
        } else {
            dispatch(setLoading(false))
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    };

    const handleFile = (file) => {
        if (file) {
            setFile(file);
            // setFileUploaded(false);

        } else {
            formRef?.current?.setFieldValue("logo", '');
            setFile(null);
        }
    };

    useEffect(() => {
        if (file) {
            handleUploadClick();
        }
    }, [file])

    useEffect(() => {
        let find = permission?.find((item) => item.moduleName === 'organization')
        setAccess(find)
    }, [permission])


    const handleUploadClick = async () => {
        dispatch(setLoading(true));
        const ext = file.name.split(".")[1];
        const key = `orgProfile/${Date.now()}.${ext}`;
        const signedUrlResult = await appapi.post("/s3", {
            fileName: key,
            contentType: file.type,
        });
        if (signedUrlResult.ok) {
            const signedUrl = signedUrlResult.data.url;
            fetch(signedUrl, { method: "PUT", body: file })
                .then((res) => {
                    if (res.ok) {
                        formRef.current?.setFieldValue("logo", signedUrlResult.data.key);
                        // setFileUploaded(true);
                    } else {
                    }
                    dispatch(setLoading(false));
                })
                .catch((error) => {
                    dispatch(setLoading(false));
                    console.log(
                        "There has been a problem with fetching signed url: " +
                        error.message
                    );
                });
        } else {
        }
    };

    const openFilePicker = () => {
        const fileInput = document.getElementById('enterpriseLogo');
        if (fileInput) {
            fileInput.click();
        }
    }

    //get the access 
    const getAccess = () => {
        if (access?.editAccess === '1') {
            return false
        }
        return true
    }

    return (
        <Stack className='organizationProfile'>
            <Stack className='fixed-title'>
                <Typography className="ff-Roboto fw-bold" sx={{ color: colors.primaryHover }}>ORGANIZATION</Typography>
            </Stack>
            <Stack alignSelf={isMobile ? "unset" : "center"} sx={containerSX}>
                <Formik
                    innerRef={formRef}
                    enableReinitialize
                    initialValues={{
                        logo: user?.enterpriseLogo || null,
                        enterpriseName: user?.enterpriseName || '',
                        address: user?.address || '',
                        countryId: user?.countryId || 103,
                        pincode: user?.pincode ? user?.pincode.toString() : '',
                        gstNumber: user?.gstNumber || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => updateEnterprise(values)}
                >
                    {({ isValid, dirty, values, handleChange, handleBlur }) => (
                        <Form noValidate autoComplete="off">
                            <Stack sx={{ mb: '34px', opacity: getAccess() ? '0.7' : '1', pointerEvents: getAccess() ? 'none' : 'initial' }}>
                                <Typography
                                    style={{ color: colors.blackMain }}
                                    className="fs-14 ff-Roboto fw-medium"
                                >
                                    Upload Logo Here
                                </Typography>
                                <Typography
                                    style={{ color: colors.blackMain }}
                                    className="fs-10 ff-Roboto  mb-7"
                                >
                                    File dimensions should be 300x300 px upto 1 MB
                                </Typography>
                                <DragAndDrop helpText={false} url={values.logo ? s3FilePathConverter(values.logo) : null} inputId="enterpriseLogo" handleFile={(files) => handleFile(files)} />
                                <Button type='button' sx={{ borderColor: '#106AA580', color: colors.primary, width: '168px', alignSelf: 'center', mt: '12px' }} variant="outlined" disabled={Boolean(values?.logo)} onClick={openFilePicker}>Upload</Button>
                            </Stack>

                            <FormTextField label="Organization Name" name="enterpriseName" sx={{ mb: 0 }} whiteSpace={true} disabled={getAccess()} />

                            <Typography className="ff-Roboto fw-medium" sx={{ color: colors.blackMain, mt: '24px', mb: '15px' }}>Billing Information</Typography>

                            <FormTextField label="Address" name="address" whiteSpace={true} disabled={getAccess()} />
                            <Stack sx={{ mb: '22px' }}>
                                <Typography
                                    className="fs-14 ff-Roboto fw-medium mb-7"
                                    color={colors.blackMain}
                                >
                                    Country
                                </Typography>
                                <TextField
                                    disabled={getAccess()}
                                    fullWidth
                                    id="countryId"
                                    name="countryId"
                                    value={values.countryId || ""}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // error={touched.countryId && Boolean(errors.countryId)}
                                    // helperText={(touched.countryId || dirty.countryId) && errors.countryId}
                                    select
                                >
                                    {countryList.map((item) => (
                                        <MenuItem key={item.countryId} value={item.countryId}>
                                            {" "}
                                            {item.countryName}{" "}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                            <FormTextField label="Pincode" name="pincode" disabled={getAccess()} />
                            <FormTextField label="GSTIN" name="gstNumber" disabled={getAccess()} />

                            <Button sx={{ ...buttonStyle2, width: '100%' }} disabled={!(dirty && isValid)} type='submit'>SAVE</Button>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Stack>
    );
}