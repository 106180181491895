import React from 'react';
import { colors } from '../utils/colors';
import { TableHead, TableRow, TableCell, Typography, Checkbox, IconButton, Stack } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function EnchanedTableHeader({ headerCell, checkbox = false, initialWidth, handleChangeAll, allSelected, preference = false, infoIconLast = false }) {

    const handleChange = (event, value) => {
        handleChangeAll(event, value)
    }

    return (
        <TableHead className='mainUserTableHead'>
            <TableRow sx={{
                "& th": {
                    backgroundColor: colors.secondary
                }
            }}>
                {
                    checkbox ?
                        <TableCell style={{ width: 'calc(100% - 96.25%)', textAlign: 'center' }}>
                            <Checkbox onChange={handleChange} indeterminate={allSelected} />
                        </TableCell> :
                        <TableCell style={{ width: `calc(100% - ${initialWidth})`, textAlign: 'center', color: colors.primaryDark }}>
                            #
                        </TableCell>
                }
                {
                    headerCell?.map((header, index) => (
                        <TableCell key={index} style={{ width: header.width }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent={
                                    index === headerCell.length - 1
                                        ? "space-between"
                                        : header.marginFlag ? 'flex-end' : "flex-start"
                                }
                            >
                                <Stack direction="row"
                                    alignItems="center"
                                    justifyContent={
                                        index === headerCell.length - 1
                                            ? "space-between"
                                            : header.marginFlag ? 'flex-end' : "flex-start"
                                    }
                                >
                                    <Typography className='ff-Roboto fs-12 fw-medium' style={{ color: colors.primaryDark, marginRight: header.marginFlag ? '40px' : '0' }}>
                                        {header.name}
                                    </Typography>
                                    {
                                        infoIconLast === true && index === headerCell.length - 1 ?
                                            <InfoOutlinedIcon style={{ marginLeft: '9px', color: 'rgb(73, 96, 115, 0.5)', transform: 'scale(0.8)', cursor: 'pointer' }} />
                                            : ''
                                    }
                                </Stack>

                                {preference === true && index === headerCell.length - 1 && (
                                    <IconButton
                                        style={{ padding: '0', marginRight: '8px', color: colors.primaryDark }}
                                        aria-label="moreColumn"
                                        component="label"
                                    >
                                        <MoreVertIcon style={{ color: colors.gray }} />
                                    </IconButton>
                                )}
                            </Stack>

                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )
}
