import React, { useEffect, useContext } from "react"
import "./Congrats.scss"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, UNSAFE_NavigationContext } from "react-router-dom"
import { Button, Stack, Typography } from "@mui/material"
import { buttonStyle1, buttonOutlineStyle } from "../../../utils/commonStyle"
import EcoActionsContainer from "../Components/EcoActionsContainer/EcoActionsContainer"
import ShareIcon from "@mui/icons-material/Share"
import tone from "../../../assets/tones/tone.mp3"
import Lottie from "lottie-react"
import congrats from "../../../assets/animation/congrats.json"
import { isMobile } from "react-device-detect"
import {Buffer} from 'buffer';

export default function Congrats() {
  const audio = new Audio(tone)
  const navigate = useNavigate()
  const navigation = useContext(UNSAFE_NavigationContext).navigator
  const { state } = useLocation()
  const { points, content, image, actionType, isSkippedVerification, ecoAction, digiCertiUrl } = state

  console.log(digiCertiUrl)
/*
  var points = 10
  var content = 'hello'
  var actionType = 'ewastecollection'
  var image = ''
  var isSkippedVerification = true

*/
  useEffect(() => {
    audio.play()
  }, [])

  const claimEcoPoints = () => {
    window.open("https://d.doitzero.com/app", "_blank")
  }

  const share = async () => {
    if (actionType === "ewastecollection" || actionType === "plasticwastecollection") {
      if (digiCertiUrl){
        var response  = await fetch(digiCertiUrl)
        var image     = await response.blob()    
      }
      navigate(`/webform/ecoActionShare`, {
        state: {
          image
        },
      })
    }
    if (actionType === "formtemplate3") {
      navigate(`/webform/workShopShare`)
    }
  }

  return (
    <EcoActionsContainer>
      <div className="congratsContainer" style={{ width: isMobile ? "100%" : "384px", marginLeft: "24px", marginRight: "24px" }}>
        <Lottie style={{ height: 500 }} height={500} animationData={congrats} loop={false} />
        <Stack className="congratsCon">
          <Stack className="congratsSubCon">
            <div className="logoShareImg" align='center' style={{ marginBottom : '40px'}}>
            <img src={localStorage.getItem('/diz/brandLogo')} height='60px'/>
            </div>
            <div className="animContainer">
              {points > 0 && (
                <Typography className="ff-Roboto fw-bold" sx={{ textAlign: "center", color: "#3787C9", fontSize: "40px", lineHeight: "40px" }}>
                  {points}
                </Typography>
              )}
              {points > 0 && (
                <Typography className="ff-Roboto fw-medium" sx={{ textAlign: "center", color: "rgba(0,0,0,0.5)" }}>
                  Eco Points
                </Typography>
              )}
              <Typography className="mt-16 ff-Roboto fw-medium" sx={{ textAlign: "center", color: "#404040", fontSize: "14px" }}>
                {content}
              </Typography>
            </div>
            <Stack justifyContent={"center"} className="congratsBtnCon">
              <Button onClick={() => claimEcoPoints()} sx={{ ...buttonStyle1, width: "100%", mt: "24px", textTransform: "none" }} type="button">
                {isSkippedVerification ? "Explore more Eco Actions" : "Track my Eco Points"}
              </Button>
              { (navigator.share || true) && (
                <Button onClick={() => share()} variant="outlined" sx={{ ...buttonOutlineStyle, mt: "8px", textTransform: "none" }} type="button" endIcon={<ShareIcon />}>
                  Share on Social Media
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    </EcoActionsContainer>
  )
}
