import { Stack, Typography, useMediaQuery } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { colors } from "../../../../../utils/colors";

export default function Contact() {

    const isMobile = useMediaQuery("(max-width:600px)");

    const ContainerSX = isMobile ? {mt: '75px', pr: '40px', pl: '40px'} : {mt: '160px'};

    const infoSX = isMobile ? {mb: '29px'} : {mb: '31px'};

    const iconSX = isMobile ? {color: colors.primaryHover, mr: '16px', transform: 'scale(0.8)'} : {color: colors.primaryHover, mr: '30px', transform: 'scale(0.8)'};

    return (
        <Stack flex={1}>
            <Typography className="ff-Roboto fw-bold" sx={{color: colors.primaryHover}}>CONTACT US</Typography>
            <Stack flexGrow={1} alignItems={isMobile? "flex-start" :"center"} sx={ContainerSX}>
                <Stack>
                    <Stack direction="row" sx={infoSX} alignItems="flex-start">
                        <EmailIcon  sx={iconSX} />
                        <Typography className="ff-Roboto fs-14" sx={{color: colors.blackMain}}>support@doitzero.com</Typography>
                    </Stack>
                    <Stack direction="row" sx={infoSX} alignItems="flex-start">
                        <PhoneIcon  sx={iconSX} />
                        <Typography className="ff-Roboto fs-14" sx={{color: colors.blackMain}}>+91 9849321616  </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" >
                        <LocationOnIcon  sx={iconSX} />
                        <Typography className="ff-Roboto fs-14" sx={{color: colors.blackMain, borderBottom: '1px solid rgba(112, 112, 112, 0.3)',pb: '20px'}}>
                        Do It Zero (India) Private Limited<br/>
                        703, Block 5, Hill Ridge Springs, <br/>
                        ISB Road, Gachibowli, Hyderabad, <br/>
                        Telangana, India, 500032 
                        </Typography>
                    </Stack>
                    <Stack direction="row"  alignItems="flex-start" sx={{mt: '13px'}}>
                        <WhatsAppIcon  sx={iconSX} />
                        <Typography className="ff-Roboto fs-14" sx={{color: colors.blackMain}}>+91 9849321616  </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}