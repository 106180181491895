import React from 'react'
import { Stack, Typography } from '@mui/material';
import notFound from '../../assets/images/notFound.png'

export default function NotFound() {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
      <img src={notFound} alt="Not found" />
      <Typography className='fw-medium' sx={{
        color: '#3787C9',
        fontSize: '30px',
        marginTop: '30px'
      }}>Page Not Found</Typography>
    </Stack>
  )
}
