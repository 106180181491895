import { Stack } from "@mui/material";
import EcoCampaignCard from "../EcoCampaignCard/EcoCampaignCard";

export default function EcoCampaignListView({ ecoInitiatives, onStatus, permission }) {

    return (
        <Stack >
            {ecoInitiatives.map((item, index) => <EcoCampaignCard onStatus={onStatus} campaign={item} key={index} permission={permission} />)}
        </Stack>
    );
}