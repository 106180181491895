import './AnalyticsChart.scss';
import React, { useState, useEffect } from 'react';
import { Stack, Typography, TextField, MenuItem, useMediaQuery, Box, Menu, FormControlLabel, Radio, FormControl, RadioGroup } from '@mui/material';
import { colors } from '../../../../../utils/colors';
import { chartFilterData, barDataType } from '../../../../../utils/commonUtils';
import LineChart from '../Chart/LineChart';
import BarChart from '../Chart/BarChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { reportSelectAlternative } from '../../../../../utils/commonUtils';
import appapi from '../../../../../api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../redux/features/appSlice';
import { months, quarter } from '../../../../../utils/commonUtils';

export default function AnalyticsChart() {

    const [barType, setBarType] = useState('bar');
    const [barData, setBarData] = useState('uniqueuser');
    const [selectedFilterOption, setSelectedFilterOption] = useState(new Date().getFullYear())

    const isMobile = useMediaQuery('(max-width:1000px)');

    const [chartData, setChartData] = useState(null)

    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const handleDataSelect = (e) => {
        setBarData(e.target.value)
    }

    // const handleChartType = (e) => {
    //     setBarType(e.target.value)
    // }

    //Options menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        handleGetchartData()
    }, [selectedFilterOption, barData])

    const handleGetchartData = async () => {
        const response = await appapi.get(`/analytics/${user.enterpriseId}/chart`, {
            enterpriseId: user.enterpriseId,
            year: selectedFilterOption,
            graphTitle: barData
        })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data;
                if (selectedFilterOption === new Date().getFullYear()) {
                    months.map((item) => {
                        let find = userData.find((l) => item.value === l.month);
                        if (find) {
                            item.count = find.count
                        } else {
                            item.count = 0
                        }
                    })
                    setChartData([...months])
                } else if (selectedFilterOption === 1) {
                    quarter.map((item) => {
                        let find = userData.find(l => item.quarter === l.quarter)
                        if (find) {
                            item.count = find.count
                        }
                    })
                    setChartData([...quarter])
                } else {
                    setChartData([...userData])
                }
            }
        }
    }

    return (
        <Stack className='analyticChartCont' direction={isMobile ? 'column' : 'row'}>
            {/**
             * Input Section
             */}
            <Stack style={{ width: isMobile ? '100%' : '232px' }}>
                <Typography color={colors.blackMain} className={isMobile ? 'fw-bold ' : 'fw-medium ' + 'ff-Roboto fs-14'}>Plot Graphs</Typography>
                <Stack className='mt-13'>
                    <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Data</Typography>
                    <TextField
                        fullWidth
                        id='graphData'
                        name='graphData'
                        value={barData}
                        onChange={(e) => handleDataSelect(e)}
                        select
                    >
                        {chartFilterData.map((item) => (
                            <MenuItem className='fs-12 ff-Roboto fw-regular' key={item.name} value={item.value}> {item.name} </MenuItem>
                        ))}
                    </TextField>
                </Stack>
                <Stack className={isMobile ? 'mt-6' : 'mt-22'}>
                    <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}> Filter Type </Typography>
                    <Box
                        // className='optionsSelectionStyle diz-dropdown'
                        className={`optionsSelectionStyle diz-dropdown cursor-pointer ${open ? "dd-opened" : ""}`}
                        id='resource-button'
                        onClick={handleMenuOpen}
                        aria-controls={open ? 'resource-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                    >

                        {
                            reportSelectAlternative.map((item) => (
                                item.value === selectedFilterOption &&
                                <Typography className='ff-Roboto fw-regular fs-12 dropdown-label' style={{ color: colors.black, flex: '1' }}>
                                    {item.name}
                                </Typography>
                            ))
                        }
                        <KeyboardArrowDownIcon style={{ color: 'rgb(0, 0, 0, 0.3)' }} className="dropdown-icon" />
                    </Box>
                    <Menu className='reportBar popup-menu filter' id='resource-menu' anchorEl={anchorEl} open={open}
                        MenuListProps={{
                            "aria-labelledby": 'resource-button',
                        }}
                        onClose={handleMenuClose}
                        PaperProps={{
                            style: {
                                width: isMobile ? '100%' : '229px',
                            },
                        }}
                    >
                        {
                            <FormControl>
                                {/* <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    checked={selectedFilterOption}
                                    name="radio-buttons-group"
                                > */}
                                {
                                    reportSelectAlternative.map((item, index) => (
                                        <MenuItem
                                            disableTouchRipple
                                            key={item.name}
                                            disabled={selectedFilterOption === item.value}
                                            selected={selectedFilterOption === item.value}
                                            onClick={() => { setSelectedFilterOption(item.value); handleMenuClose() }}
                                            onClose={handleMenuClose}
                                            sx={{ margin: '0px 8px', marginTop: index === 0 ? '8px' : '0' }}
                                        >
                                            {/* <FormControlLabel value={item.name}
                                                    control={<Radio
                                                        size="small"
                                                        checked={item.value === selectedFilterOption}
                                                        sx={{
                                                            color: colors.black,
                                                            padding: '0 0 0 8px',
                                                            '&.Mui-checked': {
                                                                color: colors.black
                                                            },
                                                        }}
                                                        onChange={() => { setSelectedFilterOption(item.value); handleMenuClose() }}
                                                    />} label={item.name} /> */}
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                                {/* </RadioGroup> */}
                            </FormControl>
                        }
                    </Menu>
                </Stack>


                {/* <Stack className={isMobile ? 'mt-6' : 'mt-22'}>
                    <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Graph Type</Typography>
                    <TextField
                        fullWidth
                        id='graphData'
                        name='graphData'
                        value={barType}
                        onChange={(e) => handleChartType(e)}
                        select
                    >
                        {barDataType.map((item) => (
                            <MenuItem key={item.type} value={item.type}> {item.name} </MenuItem>
                        ))}
                    </TextField>
                </Stack> */}
            </Stack>
            {/**
             * Chart Section
             */}
            {
                isMobile ?
                    <Typography style={{ marginTop: '13px' }} className='fs-12 ff-Roboto fw-medium' color={colors.blackMain}>
                        {barData}
                    </Typography>
                    : ''
            }
            <div className='mobileBar' style={{ padding: '5px 5px', marginLeft: isMobile ? '0' : '12px', width: isMobile ? 'auto' : 'calc(100% - 244px)', height: isMobile ? '152px' : '250px' }}>
                {
                    barType === 'bar' ?
                        <BarChart chartData={chartData} selectedFilterOption={selectedFilterOption} isMobile={isMobile} />
                        :
                        <LineChart isMobile={isMobile} />
                }
            </div>
        </Stack>
    )
}
