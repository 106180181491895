import './Analytics.scss';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { colors } from '../../../utils/colors';
import { analyticData } from '../../../utils/commonUtils';
import AnalyticsHeader from './components/AnalyticsHeader/AnalyticsHeader';
import EcoAnalyticsCard from './components/EcoAnalyticsCard/EcoAnalyticsCard';
import AnalyticsChart from './components/AnalyticsChart/AnalyticsChart';
import CampaignSection from './components/CampaignSection/CampaignSection';
import ReportSection from './components/ReportSection/ReportSection';
import HorizontalScroll from '../../../components/HorizontalScroll/HorizontalScroll';
import appapi from '../../../api/apiClient';
import { setLoading } from '../../../redux/features/appSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Analytics() {

    //States
    // const [barType, setBarType] = useState('bar');
    // const [barData, setBarData] = useState('Trees planted');

    const [analyticsList, setAnalyticslist] = useState(analyticData)

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)

    const isMobile = useMediaQuery('(max-width:1000px)');

    //dropdown filter for top header Slides
    const [selectedSlidesOption, SetSelectedSlidesOption] = useState('monthly');

    //Scroll into Report Table View
    const handleReport = () => {
        const elemToView = document.getElementById('ref')
        if (elemToView != null) {
            elemToView.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
    }

    useEffect(() => {
        getEcoAnalyticsData()
    }, [selectedSlidesOption]);

    //ecoanalytics card data
    const getEcoAnalyticsData = async () => {
        // dispatch(setLoading(true))
        const response = await appapi.get(`/analytics/${user.enterpriseId}`, {
            enterpriseId: user.enterpriseId,
            filter: selectedSlidesOption
        })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data;
                let map = new Map(Object.entries(userData))
                analyticData.map((item) => {
                    if (map.has(item.type)) {
                        item.data = map.get(item.type)
                    }
                })
                setAnalyticslist([...analyticData])
            }
            dispatch(setLoading(false))
        } else {
            dispatch(setLoading(false))
        }
    }

    return (
        <div className='Analytics'>
            {/**
             * Top Toolbar
             */}
            <AnalyticsHeader isMobile={isMobile} colors={colors}
                selectedOption={selectedSlidesOption} SetSelectedOption={(event) => SetSelectedSlidesOption(event)}
                handleReport={handleReport} />
            <div className='innerDivPadding'>
                {/**
                 * Eco Analytics Cards
                 */}
                <div >
                    {
                        isMobile ?
                            <div>
                                {
                                    analyticsList?.map((item, index) => (
                                        <EcoAnalyticsCard item={item} index={index} isMobile={isMobile} />
                                    ))
                                }
                            </div>
                            :
                            <HorizontalScroll>
                                {
                                    analyticsList?.map((item, index) => (
                                        <EcoAnalyticsCard item={item} index={index} isMobile={isMobile} />
                                    ))
                                }
                            </HorizontalScroll>
                    }

                </div>
                {/**
                 * Chart Data
                 */}
                <div className='chartSection'>
                    <AnalyticsChart
                    // barType={barType}
                    // setBarType={((e) => setBarType(e))}
                    // barData={barData}
                    // setBarData={((e) => setBarData(e))}
                    />
                </div>
                {/**
                 * Campaigns List
                 */}
                <div className='CampaignSection'>
                    <CampaignSection />
                </div>
                {/**
                 * Report List
                 */}
                <div className='ReportSection' id='ref'>
                    <ReportSection />
                </div>
            </div>
        </div>
    )
}
