import APPCONFIG from "../config/apiConfig";

export function s3FilePathConverter(filename) {
    if(filename) {
        if(filename.startsWith('http')) {
            return filename;
        }
        return APPCONFIG.s3Path + filename;
    }
    return null;
}