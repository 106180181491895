import { useState } from "react"
import { Button, Stack, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { colors } from "../utils/colors"

export default function FormButtonField({ name, label, data, subTitle, onSelect, style, ...otherProps }) {

  const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext()

  const onPress = item => {
    setTimeout(() => {
      setFieldValue(name, item)
      setFieldTouched(name)
    }, 10)
    if (onSelect) {
      onSelect(item)
    }
  }

  return (
    <Stack flex={1} direction={"row"} justifyContent={"center"} alignItems={'flex-start'} sx={{ mb: "22px" }}>
      {data.map((item, index) => (
        <Stack key={index} alignItems={"center"} justifyContent={"center"} style={style}>
          <Button variant="outlined" style={{ borderColor: "#3787C9", backgroundColor: values[name]?.title == item.title ? "#3787C9" : "transparent", color: values[name]?.title == item.title ? colors.white : "#3787C9" }} onClick={() => onPress(item)} sx={{ mt: "2.3px" }} type="button">
            {item.title}
          </Button>
          {subTitle && (
            <Typography className="ff-regular fs-12" style={{ color: "#49454F", marginTop: 8 }}>
              {item.points}
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  )
}
