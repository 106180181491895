import { createSlice } from '@reduxjs/toolkit'
import appapi from '../../api/apiClient'

//Initial State of authslice
const initialState = {
    user: null,
    isLoggedIn: false,
    fromLogout: false,
    sideMenuList: null
}

const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        //Login
        login(state, action) {
            state.isLoggedIn = true
            state.user = action.payload
        },
        //Logout
        logout(state) {
            // window.grecaptcha.reset();
            localStorage.removeItem('diz')
            state.isLoggedIn = false
            state.user = null;
            state.fromLogout = true
        },
        refreshUserData(state, action) {
            state.user = action.payload
        },
        updateSideMenuList(state, action) {
            state.sideMenuList = action.payload
        }
    }
})

/**
 * @function initUserData
 * @returns The updated logged In User Data
 */
export const initUserData = () => {
    return async (dispatch, getState) => {
        const { user } = getState().auth;
        if (user) {
            const response = await appapi.get(`/user/${user.enterpriseId}/${user.enterpriseUserId}`);
            if (response.ok) {
                const data = response.data;
                if (data.status === 'success') {
                    dispatch(refreshUserData(data.data));
                }
            }
        }
    };
};

export const { login, logout, refreshUserData, updateSideMenuList } = authSlice.actions
export default authSlice.reducer