const host = window.location.hostname;
const isDev = host === 'enterpriseportal-dev.doitzero.com' || host === 'webform-dev.doitzero.com';
const APPCONFIG = {

  endPoint: isDev ? "https://api-ep-dev.doitzero.com/api" : "https://api-ep-prod.doitzero.com/api",
  // endPoint: isDev ? "https://275c-2401-4900-4df8-e48-106-41b9-3b89-3f7b.ngrok-free.app/api" : "https://api-ep-prod.doitzero.com/api",
  mobileEndPoint: isDev ? "https://api-mobile-dev.doitzero.com/api" : "https://api-mobile-prod.doitzero.com/api",
  // prod
  
  // endPoint: "http://localhost:3001/api",
  // razorPayKey: 'rzp_test_jH7YyXHrGykXP4'
  razorPayKey: "rzp_test_5CWpeKd18hQRZh",
  // endPoint: 'http://localhost:3001/api',
  // razorPayKey: 'rzp_test_jH7YyXHrGykXP4',
  s3Path: "https://enterpriseportal-dev-do-it-zero.s3.us-east-2.amazonaws.com/",
  siteKey: "6Lcvf6IjAAAAAJ61s8szfM5KqatMVNI597p2jI2F",
};
export default APPCONFIG;
