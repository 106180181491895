import "./ResetPassword.scss";
import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "../../../components/react-google-recaptcha-enterprise";

import appapi from "../../../api/apiClient";
import { showmessage } from "../../../utils/toastr";
import { buttonStyle1 } from "../../../utils/commonStyle";
import mobilewavybg from "../../../assets/images/mobilewavybg.svg";
import diztextlogo from "../../../assets/images/diztextlogo.svg";
import wavesbg from "../../../assets/images/wavesbg.svg";
import { colors } from "../../../utils/colors";
import { setLoading } from "../../../redux/features/appSlice";
import { useDispatch } from "react-redux";
import SocialCard from "../../../components/SocialCard";
import dizlogoMobile from "../../../assets/images/dizlogoMobile.svg";
import FormTextField from "../../../components/FormTextField";

/**
 * Validation Schema for register
 */
const validationSchema = yup.object({
  email: yup
    .string("Enter your Email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is Invalid")
    .required("Email is Required"),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { email } = state;

  const formRef = useRef();

  const [hasFocused, setHasFocused] = useState(false);

  const [data, setData] = useState({
    email: "",
  });

  useEffect(() => {
    if (email) {
      setData({ email: email });
      setHasFocused(true);
    } else {
      setHasFocused(false);
    }
  }, [email]);

  const [formSubmitted, SetFormSubmitted] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [resend, SetResend] = useState(false);

  /**
   *
   * @param {email} obj
   * This submit is for reseting password
   */
  const resetPassword = async (obj, resend = false) => {
    dispatch(setLoading(true));
    let params = {
      params: {
        emailId: obj?.email ? obj.email : data.email,
        token: recaptchaToken
      },
    };
    const response = await appapi.post(`/auth/forgot-password`, {}, params);
    if (response.ok) {
      const data = response.data;
      dispatch(setLoading(false));
      if (data.status === "success") {
        // showmessage(data.message)
        if (resend === true) {
          SetResend(true);
        }
        SetFormSubmitted(true);
      } else {
        showmessage(data.message, "error");
      }
    } else {
      dispatch(setLoading(false));
      // showmessage(`Something Went Wrong!`, 2000, 'error')
    }
  };

  const onChange = (value) => {
    setRecaptchaToken(value ? value : null);
   }

  /**
   *
   * @returns Redirect to initial page which is login
   */
  const onClickLoginRoute = () => navigate(`/login`);

  //emailForm
  const form = () => {
    return (
      <>
        <Typography
          className="fw-bold ff-Roboto createHeading"
          color={colors.primaryTxt}
        >
          {" "}
          Reset Password{" "}
        </Typography>
        <Typography
          className="fs-12 ff-Roboto fw-regular"
          color={colors.blackMain}
        >
          Enter your registered email address.
        </Typography>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            email: data?.email || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => resetPassword(values)}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <Form style={{ minHeight: "484px" }} noValidate autoComplete="off">
              <Stack flex={1} className="mt-9" sx={{mb: '14px'}}>
                <FormTextField
                  label="Email"
                  name="email"
                  sx={{ mb: 0 }}
                  lowerCase={true}
                  hasFocused={hasFocused}
                  setHasFocused={(val) => setHasFocused(val)}
                />
              </Stack>
              <div className="g-recaptcha">

              <ReCAPTCHA action="forgotPassword" sitekey="6LcQc-8jAAAAAN3MpjpfKCMhVYFZuBarUVwQq-IZ" onChange={onChange} />
              </div>
              <Button
                sx={{ ...buttonStyle1, marginBottom: "0", width: "100%", mt: '2.3px' }}
                disabled={
                 ( !hasFocused ? !(dirty && isValid) : !isValid || !hasFocused) || !Boolean(recaptchaToken)
                }
                type="submit"
              >
                SEND
              </Button>
              <Stack direction="row" className="mt-16" justifyContent="center">
                <Typography
                  className="ff-Roboto fw-regular fs-12"
                  color={colors.gray}
                >
                  Go back to{" "}
                </Typography>
                <Typography
                  onClick={onClickLoginRoute}
                  className="ff-Roboto fw-bold fs-12"
                  color={colors.primaryDark}
                  style={{ marginLeft: "7px", cursor: "pointer" }}
                >
                  Login
                </Typography>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  const formSubmittedContent = () => {
    return (
      <>
        <Typography
          className="fw-bold ff-Roboto createHeading"
          color={colors.primaryTxt}
        >
          {" "}
          Successful!{" "}
        </Typography>
        <Typography
          className="fs-12 ff-Roboto fw-regular"
          color={colors.blackMain}
        >
          Your request has been submitted successfully. Please check your email
          for further instructions.
        </Typography>
        {resend ? (
          ""
        ) : (
          <Stack direction="row" style={{ marginTop: "7px" }} spacing={0.5}>
            <Typography
              className="ff-Roboto fw-regular fs-12"
              color={colors.gray}
            >
              Click here to
            </Typography>
            <Typography
              onClick={() => resetPassword({}, true)}
              className="ff-Roboto fw-bold fs-12"
              color={colors.primaryDark}
              style={{ cursor: "pointer" }}
            >
              Resend
            </Typography>
            <Typography
              className="ff-Roboto fw-regular fs-12"
              color={colors.gray}
            >
              mail
            </Typography>
          </Stack>
        )}
        <Button
          sx={{
            ...buttonStyle1,
            marginBottom: "0",
            textTransform: "capitalize",
            width: "100%",
          }}
          onClick={onClickLoginRoute}
          type="submit"
        >
          Go Back To Login
        </Button>
      </>
    );
  };

  return (
    <div
      className="resetContainer"
      style={{
        padding: isMobile ? "16px" : "0",
        minHeight: isMobile ? "auto" : "100vh",
      }}
    >
      {isMobile ? (
        <div
          className="mobileViewContainer"
          style={{ minHeight: "calc(100vh - 32px)" }}
        >
          <div
            style={{
              backgroundImage: `url(${mobilewavybg})`,
              minHeight: "calc(100vh - 32px)",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="logoImg" style={{ marginLeft: "8px" }}>
              <img alt="dizLogo" src={dizlogoMobile} />
            </div>
            <div
              className="innerContainer"
              style={{ marginTop: formSubmitted ? "240px" : "160px" }}
            >
              {formSubmitted ? <>{formSubmittedContent()}</> : <>{form()}</>}
            </div>
          </div>
        </div>
      ) : (
        <Stack
          style={{ maxWidth: "1368px", margin: "auto", minHeight: "758.64px" }}
          direction="row"
          justifyContent="space-between"
        >
          <Stack className="leftStack" style={{ width: "100%" }}>
            <div className="logoImg">
              <img
                style={{ visibility: "hidden" }}
                alt="dizLogo"
                src={diztextlogo}
              />
            </div>
            <div
              className="detailContainer"
              style={{ backgroundImage: `url(${wavesbg})` }}
            ></div>
            <Stack className="formSubmittedStyle">
              <div className="logoImg">
                <img alt="dizLogo" src={diztextlogo} />
              </div>
              <div
                style={{
                  marginTop: formSubmitted ? "22.916666666667vh" : "15.625vh",
                }}
              >
                {formSubmitted ? <>{formSubmittedContent()}</> : <>{form()}</>}
              </div>
            </Stack>
          </Stack>
        </Stack>
      )}
      {isMobile ? (
        <Stack
          className="footer"
          direction="row"
          justifyContent="space-between"
        >
          <Stack className="socialContainer" direction="row">
            {<SocialCard />}
          </Stack>
          <Typography className="fs-10 ff-Roboto fw-medium black dizcopyrights">
            © 2022 Do It Zero Inc.
          </Typography>
        </Stack>
      ) : (
        <Stack className="footerWeb">
          <Stack
            className="socialContainer flex-justifycenter"
            style={{
              margin: "3.125vh auto 3.125vh",
            }}
            direction="row"
          >
            {<SocialCard />}
          </Stack>
          <Typography className="fs-10 ff-Roboto fw-medium black dizcopyrights">
            © 2022 Do It Zero Inc.
          </Typography>
        </Stack>
      )}
    </div>
  );
}
