import "./EcoInitiativeCampaignBrand.scss";
import { Stack, Typography } from "@mui/material";

import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

import refuse from "../../../../../assets/images/refuse.svg";
import recycle from "../../../../../assets/images/recycle.svg";
import reuse from "../../../../../assets/images/reuse.svg";
import dizlogoOrigin from "../../../../../assets/images/dizlogoOrigin.svg"

import { colors } from "../../../../../utils/colors";
import APPCONFIG from "../../../../../config/apiConfig";
import { s3FilePathConverter } from "../../../../../utils/s3FilePathConverter";

export default function EcoInitiativeCampaignBrand({ campaign }) {

  const categoryImg =
    campaign?.categoryType === "reuse"
        ? reuse
        : campaign?.categoryType === "recycle"
        ? recycle
        : refuse; 

  return campaign.brandName ? (
    <Stack direction="row" style={{ height: "100%" }} className="row-hover">
      <Stack justifyContent="center">
        <img className="brandImg" src={ campaign?.brandLogo ? s3FilePathConverter(campaign?.brandLogo) : dizlogoOrigin} alt="brandImg" />
      </Stack>
      <Stack justifyContent="center">
        <Typography
          style={{ color: colors.deactive }}
          className="ff-Roboto fw-medium fs-14 capitalize"
        >
          {campaign?.brandName}
        </Typography>
        {Boolean(campaign?.subBrandName) && <Stack direction="row" alignItems="center">
          <TrendingFlatIcon
            style={{ color: colors.primaryHover, marginRight: "14px" }}
            fontSize="10px"
          />
          <Typography className="fs-12 ff-Roboto fw-regular ellipseStyle capitalize">
            {campaign?.subBrandName || 'N/A'}
          </Typography>
        </Stack>}
      </Stack>
    </Stack>
  ) : (
    <Stack direction="row" style={{ height: "100%" }} alignItems="center" className="row-hover">
      <Typography className="fs-12 ff-Roboto fw-regular ellipseStyle">
      -
    </Typography>
    </Stack>
    
  );
}
