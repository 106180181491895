import { createSlice } from '@reduxjs/toolkit'

//Initial State of appslice
const initialState = {
    citiesList: [],
    countryList: [],
    rolesList: [],
    isLoading: false,
    initialIndexSetting: 0
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        //cities
        setCities(state, action) {
            state.citiesList = action.payload
        },
        //roles
        setRoles(state, action) {
            state.rolesList = action.payload
        },
        setCountries(state, action) {
            state.countryList = action.payload
        },
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        setIndex(state, action) {
            state.initialIndexSetting = action.payload
        }
    }
})



export const { setCities, setRoles, setLoading, setCountries, setIndex } = appSlice.actions
export default appSlice.reducer