import "./Legal.scss";
import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import wavesbg from "../../assets/images/wavesbg.svg";
import { Stack } from "@mui/system";

export default function Privacy() {
  const isMobile = useMediaQuery("(max-width:1000px)");

  return (
    <div
      className="legal-container"
      style={{
        padding: isMobile ? "16px" : "32px",
        minHeight: isMobile ? "auto" : "100vh",
        backgroundImage: `url(${wavesbg})`,
        backgroundPosition: "bottom 95% ",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={10} lg={7}>
          <Stack>
            <Typography
              className="fw-bold c-sp-16"
              style={{ textAlign: "center", fontSize: "24px" }}
            >
              PRIVACY POLICY
            </Typography>
            <Stack direction="row" sx={{ textAlign: "justify" }}>
              <Stack>
                <Typography className="c-sp-16 fs-14">
                  Do It Zero (Private) Limited (DIZ) takes the privacy of your
                  information seriously. This privacy notice (
                  <span className="fw-bold">"Privacy Notice"</span>) describes
                  the types of personal information we collect from you through
                  our website (including sub-domains and microsites) and mobile
                  applications. It also describes the purposes for which we
                  collect that personal information, the other parties with whom
                  we may share it and the measures we take to protect the
                  security of your data. It also tells you about your rights and
                  choices with respect to your personal information, and how you
                  can contact us about our privacy practices.
                  <br />
                  You are advised to carefully read this Privacy Notice before
                  using or availing any of our products and/or services.
                </Typography>

                <Typography>DEFINITIONS</Typography>
                <Typography className="c-sp-16 fs-14">
                  In this Privacy Notice, the following definitions are used:
                </Typography>

                <Typography className="fw-bold">Cookies</Typography>
                <Typography className="c-sp-16 fs-14">
                  a small file placed on your device by our website or mobile
                  application when you either visit or use certain features of
                  our website or mobile application. A cookie generally allows a
                  website to remember your actions or preference for a certain
                  period of time.
                </Typography>

                <Typography className="fw-bold">Data</Typography>
                <Typography className="c-sp-16 fs-14">
                  includes non-personal information, personal information and
                  sensitive personal information about you, which either
                  directly or indirectly in combination with other information,
                  could allow you to be identified when you visit our stores,
                  website and/or mobile application.
                </Typography>

                <Typography className="fw-bold">
                  Data Protection Laws
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  any applicable law for the time being in force relating to the
                  processing of Data.
                </Typography>

                <Typography className="fw-bold">Partners</Typography>
                <Typography className="c-sp-16 fs-14">
                  select third parties with whom we have contracts for the
                  businesses described in this Privacy Notice.
                </Typography>

                <Typography className="fw-bold">Service Providers</Typography>
                <Typography className="c-sp-16 fs-14">
                  includes entities to whom we will disclose your Data in order
                  to process information for a specific purpose pursuant to
                  written contract.
                </Typography>

                <Typography className="fw-bold">DIZ</Typography>
                <Typography className="c-sp-16 fs-14">
                  Do It Zero (India) Private Limited, a company whose registered
                  office is at 703, Block 5, Hill Ridge Springs, ISB Road,
                  Gachibowli, Hyderabad 500032
                </Typography>

                <Typography className="fw-bold">User or you</Typography>
                <Typography className="c-sp-16 fs-14">
                  the natural person who accesses our website or mobile
                  application.
                </Typography>

                <Typography>WHAT DATA DO WE COLLECT ABOUT YOU</Typography>
                <Typography className="c-sp-16 fs-14">
                  DIZ collects Data for various purposes set out in this Privacy
                  Notice.
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This Data includes, without limitation, the following
                  categories:
                </Typography>
                <ul>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Contact information:</span>{" "}
                      name, email address, pin code, city, country, phone number
                      and other similar contact data.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Financial information:</span>{" "}
                      payment instrument information, transactions, transaction
                      history, preferences, method, mode and manner of payment,
                      spending pattern or trends, and other similar data.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Technical information:</span>{" "}
                      website, device and mobile app usage, Internet Protocol
                      (IP) address and similar information collected via
                      automated means, such as cookies, pixels and similar
                      technologies.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Transaction information:</span>{" "}
                      the date of the transaction, total amount, transaction
                      history and preferences and related details.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">
                        Product and service information:
                      </span>{" "}
                      Your account membership number, registration and payment
                      information, and program-specific information, when you
                      request products and/or services directly from us, or
                      participate in marketing programs.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Personal information:</span>{" "}
                      Age, gender, home energy consumption, travel related
                      information, dietary preference and household waste
                      management or any other personal information provided in
                      responses to surveys or questionnaires.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="c-sp-16 fs-14">
                      Your reviews, feedback and opinions about our products,
                      programmes and services..
                    </Typography>
                  </li>
                </ul>

                <Typography>HOW WE COLLECT DATA</Typography>
                <Typography className="c-sp-16 fs-14">
                  We collect Data in the following ways:
                </Typography>
                <ul>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Information You Give Us:</span>{" "}
                      We receive and store any information you enter on our
                      website or mobile application or give us in any other way
                      (e.g., at outlets, stores, hotels, kiosks). Please see the
                      section titled "Data Shared by You" for more information.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">
                        Automatic Information We Collect:
                      </span>{" "}
                      We use "cookies", pixels and similar technologies to
                      receive and store certain types of information whenever
                      you interact with us. Please see the section below, titled
                      "Data that is Collected Automatically" for more
                      information.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">E-mail Communications:</span> To
                      help us make e-mails more relevant and interesting, we
                      often receive a confirmation (if your device supports such
                      capabilities) when you open e-mail from us or click on a
                      link in the e-mail. You can choose not to receive
                      marketing emails from us by clicking on the unsubscribe
                      link in any marketing email.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">
                        Automatic Information We Collect from Other Websites:
                      </span>{" "}
                      We receive and store certain types of information when you
                      interact with third-party websites that use our technology
                      or with whom we have a specific agreement. Because we
                      process this information on behalf of the applicable
                      website operators, collection, processing, and use of such
                      information is subject to the applicable website
                      operators’ privacy policies and is not covered by our
                      Privacy Notice.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">
                        Information from Other Sources:
                      </span>{" "}
                      We may obtain information from other sources. An example
                      of this is when you authorize a third-party website to
                      interact directly with our website or mobile application
                      to provide or receive Data about you. In that case, we
                      might receive such Data used by that third-party website
                      to identify your account with that website.
                    </Typography>
                  </li>
                </ul>

                <Typography className="c-sp-16 fs-14">
                  You can make choices about our collection and use of your
                  Data. For example, you may want to access, edit or remove your
                  Data on our website or mobile application. When you are asked
                  to provide Data, you may decline.
                </Typography>

                <Typography>DATA SHARED BY YOU</Typography>
                <Typography className="fs-14">
                  DIZ may collect your Data in several ways from your use of our
                  website or mobile application. <br />
                  For instance:
                </Typography>

                <ul>
                  <li>
                    <Typography className="fs-14">
                      when you register with us to receive our products and/or
                      services;
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      when you conduct a transaction with us or attempt a
                      transaction at our agreement with third-party stores, on
                      our website or mobile application;
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      when you complete surveys conducted by or for us;
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      when you elect to receive any communications (including
                      promotional offers) from us;
                    </Typography>
                  </li>

                  <li>
                    <Typography className="c-sp-16 fs-14">
                      from the information gathered by your visit to our website
                      or mobile application;
                    </Typography>
                  </li>
                </ul>

                <Typography>DATA THAT IS COLLECTED AUTOMATICALLY</Typography>
                <ul>
                  <li>
                    <Typography className="fs-14">
                      We automatically collect some information when you visit
                      our website or use our mobile application. This
                      information helps us to make improvements to our content
                      and navigation. The information collected automatically
                      includes your IP address.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="fs-14">
                      Our web servers or affiliates who provide analytics and
                      performance enhancement services collect IP addresses,
                      operating system details, browsing details, device details
                      and language settings. This information is aggregated to
                      measure the number of visits, average time spent on the
                      site, pages viewed and similar information. DIZ uses this
                      information to measure the site usage, improve content and
                      to ensure safety and security, as well as enhance
                      performance of our website or mobile application.
                    </Typography>
                  </li>

                  <li>
                    <Typography className="c-sp-16 fs-14">
                      We may collect your Data automatically via Cookies, pixels
                      and similar technologies in line with settings on your
                      browser. For more information about Cookies, please see
                      the section below, titled{" "}
                      <span className="fw-bold">"Cookies"</span>.
                    </Typography>
                  </li>
                </ul>

                <Typography>OUR USE OF DATA</Typography>
                <Typography className="c-sp-16 fs-14">
                  Any or all the above Data may be required by us from time to
                  time to provide information relating to DIZ and to work on the
                  experience when using our website or mobile application.
                  Specifically, Data may be used by us for the following
                  reasons:
                </Typography>
                <ul>
                  <li>
                    <Typography className="fs-14">
                      carry out our obligations arising from any contract
                      entered into between you and us;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      provide products and/or services and communicate with you
                      about products and/or services offered by us;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      enable DIZ and Partners to offer their products and/or
                      services and communicate with you about such products
                      and/or services;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      processing, disclosing, transmitting, and/or sharing the
                      data/information with other third parties which have
                      business or contractual dealings with us;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      provide you with offers (including for financial products
                      and/or services), personalized services and
                      recommendations and improve your experience on our website
                      and mobile application;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      operate, evaluate and improve our business, website and
                      mobile application;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      generate aggregated data to prepare insights to enable us
                      to understand customer behaviour, patterns and trends with
                      a view to learning more about your preferences or other
                      characteristics;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      provide privileges and benefits to you, marketing and
                      promotional campaigns based on your profile;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      in connection with loyalty programs owned and operated by
                      us;
                    </Typography>
                  </li>
                  <li>
                    {" "}
                    <Typography className="fs-14">
                      communicate with you (including to respond to your
                      requests, questions, feedback, claims or disputes) and to
                      customize and improve our services;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      to enhance your experience and bring you access to
                      membership programs, rewards from DIZ
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      enforce the terms of use of our website and mobile
                      application;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      protect against and prevent fraud, illegal activity, harm,
                      financial loss and other legal or information security
                      risks; and
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      serve other purposes for which we provide specific notice
                      at the time of collection, and as otherwise authorized or
                      required by applicable law.
                    </Typography>
                  </li>
                </ul>

                <Typography className="c-sp-16 fs-14">
                  We treat these inferences as personal information (or
                  sensitive personal information, as the case may be), where
                  required under applicable law. Some of the above grounds for
                  processing will overlap and there may be several grounds which
                  justify our use of your personal information. <br />
                  Where required under applicable law, we will only use your
                  personal information (including sensitive personal
                  information) with your consent; as necessary to provide you
                  with products and/or services; to comply with a legal
                  obligation; or when there is a legitimate interest that
                  necessitates the use.
                </Typography>

                <Typography>SHARING OF DATA</Typography>
                <Typography className="c-sp-16 fs-14">
                  We may share your Data with/ for:
                </Typography>
                <ul>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Partners:</span> We may make
                      available to you services, products, or applications
                      provided by Partners for use on or through our website or
                      mobile application. If you choose to use such service,
                      customer information related to those transactions may be
                      shared with such Partner. Such Partners will be required
                      to respect the security of your Data and to treat it in
                      accordance with this privacy policy and applicable law.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Service Providers:</span> DIZ
                      may share your Data with Service Providers. Examples
                      include storing and analyzing Data, protecting and
                      securing our systems, providing search results and links,
                      providing customer service, credit analysis, processing
                      your information for profiling, user analysis and payment
                      processing.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">
                        Information from Other Sources:
                      </span>{" "}
                      We may obtain information from other sources. An example
                      of this is when you authorize a third-party website (such
                      as the website of DIZ partner), to interact directly with
                      our website or mobile application to provide or receive
                      Data about you. In that case, we might receive such Data
                      used by that third-party website to identify your account
                      with that website. These Service Providers will be
                      required to only process Data in accordance with express
                      instructions and as necessary to perform services for
                      purposes set forth in this Privacy Notice. The Service
                      Providers will also be required to safeguard the security
                      and confidentiality of the Data they process by
                      implementing appropriate technical and organizational
                      security measures and confidentiality obligations binding
                      employees accessing Data.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Protecting DIZ:</span> We may
                      release Data when we believe release is appropriate to
                      comply with applicable law or legal process, enforce or
                      apply the Terms of Use of our website or mobile
                      application and other agreements, protect DIZ against harm
                      or financial loss, when we believe disclosure is necessary
                      to protect individuals’ vital interests, or in connection
                      with an investigation of suspected or actual fraudulent or
                      illegal activity. This may include exchanging information
                      with other companies and organizations for fraud
                      protection, risk management and dispute resolution. This
                      does not include selling or otherwise disclosing personal
                      information of users for commercial purposes in violation
                      of this Privacy Notice.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Business Transfers:</span> As we
                      continue to develop our business, we might sell or buy
                      subsidiaries or business units. Your Data (including in
                      relation to loyalty programs) may be transferred as part
                      of such transaction. Any Data that we receive from a third
                      party pursuant to such transactions will be processed in
                      accordance with this Privacy Notice and applicable law.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      <span className="fw-bold">Third Parties:</span> We may
                      also share your Data with other third parties where:
                      <ul>
                        <li>
                          <Typography className="fs-14">
                            You request or authorize us to do so;
                          </Typography>
                        </li>
                        <li>
                          <Typography className="fs-14">
                            We need to comply with applicable law or respond to
                            valid legal process; or
                          </Typography>
                        </li>
                        <li>
                          <Typography className="fs-14">
                            We need to operate and maintain the security of our
                            website or mobile application, including to prevent
                            or stop an attack on our computer systems or
                            networks.
                          </Typography>
                        </li>
                        <li>
                          <Typography className="c-sp-16 fs-14">
                            We require these third parties by contract to only
                            process sensitive personal data in accordance with
                            our instructions and as necessary to perform
                            services on our behalf or in compliance with
                            applicable law. We also require them to safeguard
                            the security and confidentiality of the sensitive
                            personal data they process on our behalf by
                            implementing appropriate confidentiality, technical
                            and organizational security measures.
                          </Typography>
                        </li>
                      </ul>
                    </Typography>
                  </li>
                </ul>

                <Typography>KEEPING DATA SECURE</Typography>
                <Typography className="c-sp-16 fs-14">
                  We will use technical and organisational measures to safeguard
                  your Data and we store your Data on secure servers. Technical
                  and organisational measures include measures to deal with any
                  suspected data breach. If you suspect any misuse or loss or
                  unauthorised access to your Data, please let us know
                  immediately by contacting us by e-mail at our email address
                  below.
                </Typography>

                <Typography>RETENTION OF DATA</Typography>
                <Typography className="c-sp-16 fs-14">
                  DIZ retains Data for as long as necessary for the use of our
                  products and/or services or to provide access to and use of
                  our website or mobile application, or for other essential
                  purposes such as complying with our legal obligations,
                  resolving disputes, enforcing our agreements and as long as
                  processing and retaining your Data is necessary and is
                  permitted by applicable law. Because these needs can vary for
                  different data types and purposes, actual retention periods
                  can vary significantly.
                  <br />
                  Even if we delete your Data, including on account of exercise
                  of your right, it may persist on backup or archival media for
                  audit, legal, tax or regulatory purposes.
                </Typography>

                <Typography>YOUR RIGHTS AND CHOICES</Typography>
                <Typography className="fs-14">
                  When we process Data about you, we do so with your consent
                  and/or as necessary to operate our business, meet our
                  contractual and legal obligations, protect the security of our
                  systems and our customers, or fulfil other legitimate
                  interests of DIZ as described in this Privacy Notice.You have
                  the following rights in relation to your sensitive personal
                  information and you can exercise it by submitting a request as
                  described in the{" "}
                  <span className="fw-bold">"How to Contact Us"</span> section
                  below.
                </Typography>
                <ul>
                  <li>
                    <Typography className="fs-14">
                      Right to Access, Review and Modify
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Right to Correction
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Right to Withdraw Consent
                    </Typography>
                  </li>
                </ul>

                <Typography className="c-sp-16 fs-14">
                  It is important that the Data we hold about you is accurate
                  and current. Please keep us informed if your personal
                  information changes during the period for which we hold it.
                </Typography>

                <Typography>WHERE WE STORE DATA</Typography>
                <Typography className="c-sp-16 fs-14">
                  Data collected under this Privacy Notice is hosted on servers
                  located in India.
                </Typography>

                <Typography>PROCESSING YOUR DATA</Typography>
                <Typography className="c-sp-16 fs-14">
                  We take steps to ensure that the Data we collect under this
                  Privacy Notice is processed according to the provisions of
                  this Privacy Notice and the requirements of applicable law.
                  <br />
                  To ensure that your Data receives an adequate level of
                  protection, we have put in place appropriate written contracts
                  with Partners and Service Providers that we share your Data
                  with. This ensures your Data is treated by such parties in a
                  way that is consistent with applicable law.
                </Typography>

                <Typography>APP PERMISSIONS THAT WE CAPTURE</Typography>
                <Typography className="c-sp-16 fs-14">
                  We ask for the following app permissions while onboarding, in
                  order to optimize the experience for you:
                </Typography>

                <Typography className="fw-bold">Location</Typography>
                <Typography className="c-sp-16 fs-14">
                  It is recommended that you set your location sharing 'Always'
                  as it helps us to show you location specific data like
                  availability of products. You can change this anytime.
                </Typography>

                <Typography className="fw-bold">Camera</Typography>
                <Typography className="c-sp-16 fs-14">
                  To allow you to take a photo wherever asked within the app &
                  directly upload it to the app.
                </Typography>

                <Typography className="fw-bold">Photos/Media/Files</Typography>
                <Typography className="c-sp-16 fs-14">
                  Media access permission is needed to store and retrieve your
                  uploads such as photo uploads of a eco-action on your device.
                </Typography>

                <Typography className="fw-bold">SMS</Typography>
                <Typography className="c-sp-16 fs-14">
                  To support automatic OTP confirmation, so that you don't have
                  to enter the authentication code manually.
                </Typography>

                <Typography className="fw-bold">Receive SMS</Typography>
                <Typography className="c-sp-16 fs-14">
                  This helps us to send you payment related SMS by our payment
                  partner
                </Typography>

                <Typography className="fw-bold">Access Wifi State</Typography>
                <Typography className="c-sp-16 fs-14">
                  This helps us to optimize your experience based on the Wifi’s
                  strength and signals, especially for optimizing videos and
                  animations.
                </Typography>

                <Typography>SEVERABILITY</Typography>
                <Typography className="c-sp-16 fs-14">
                  If any court or competent authority finds that any provision
                  of this Privacy Notice (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  Privacy Notice will not be affected.
                </Typography>

                <Typography>CHANGES TO THIS PRIVACY NOTICE</Typography>
                <Typography className="c-sp-16 fs-14">
                  Our business changes constantly and our Privacy Notice may
                  also change . We may e-mail periodic reminders of our notices
                  and conditions, unless you have instructed us not to, but you
                  should check our website and mobile application frequently to
                  see recent changes. The updated version will be effective as
                  soon as it is accessible. Any changes will be immediately
                  posted on our website and mobile application and you are
                  deemed to have accepted the terms of the updated Privacy
                  Notice on your first use of our website or mobile application
                  or first purchase of the products and/or services following
                  the alterations. We encourage you to review this Privacy
                  Notice frequently to be informed of how we are protecting your
                  information.
                </Typography>

                <Typography>HOW TO CONTACT US</Typography>
                <Typography className="c-sp-16 fs-14">
                  To request to access, review, update, or withdraw your consent
                  for your personal information or to otherwise reach us, please
                  submit a request by e-mailing us at support@doitzero.com
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
