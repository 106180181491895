import "./EcoInitiatives.scss";
import {
  useMediaQuery,
  Stack,
  Typography,
  Menu,
  MenuItem,
  TablePagination,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FilterListIcon from '@mui/icons-material/FilterList';

import { colors } from "./../../../utils/colors";
import EcoTemplateCard from "./components/EcoTemplateCard/EcoTemplateCard";
import EcoStatusConfirmationDialog from "./components/EcoStatusConfirmationDialog/EcoStatusConfirmationDialog";
import TablePaginationActions from "../../../components/TablePaginationActions";
import EcoInitiativeMobileFooter from "./components/EcoInitiativeMobileFooter/EcoInitiativeMobileFooter";
import EcoCampaignTableView from "./components/EcoCampaignTableView/EcoCampaignTableView";
import EcoCampaignListView from "./components/EcoCampaignListView/EcoCampaignListView";
import EditEcoCampaign from "./components/EditEcoCampaign/EditEcoCampaign";
import HorizontalScroll from "../../../components/HorizontalScroll/HorizontalScroll";
import appapi from "../../../api/apiClient";
import { showmessage } from "../../../utils/toastr";
import { setLoading } from "../../../redux/features/appSlice";
import { CancelToken } from "apisauce";

const templateFilter = ["all", "refuse", "reuse", "recycle"];

export default function EcoInitiatives() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const dispatch = useDispatch();

  const [ecoInitiativesTemplateData, setEcoInitiativesTemplateData] = useState(
    []
  );
  const [ecoInitiativesData, setEcoInitiativesData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showTemplate, setShowTemplate] = useState(true);
  const anchorRef = useRef(null);
  const [openTemplateFilter, setOpenTemplateFilter] = useState(false);
  const [selectedTemplateFilter, setSelectedTemplateFilter] = useState("all");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [page, setPage] = useState(0);
  const [backendpage, setBackendPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [length, setLength] = useState(20);
  const [activeTab, setActiveTab] = useState(0);
  const [permission, setPermision] = useState(null);

  let source1 = CancelToken.source();
  let source2 = CancelToken.source();

  /**
   * retrieving user From Redux Store
   */
  const { user } = useSelector((state) => state.auth);

  const { isLoading } = useSelector((state) => state.app);

  useEffect(() => {
    // permission Task
    let find = user?.permission?.find(
      (item) => item?.moduleName === "eco_initiatives"
    );
    // console.log(find)
    setPermision(find);
  }, []);

  useEffect(() => {
    getEcoInitiativeTemplates();
  }, [selectedTemplateFilter]);

  useEffect(() => {
    source2.cancel();
    source2 = CancelToken.source();
    getEcoCampaignList();
  }, [page, rowsPerPage, backendpage]);

  const getEcoInitiativeTemplates = async () => {
    source1.cancel();
    source1 = CancelToken.source();
    const response = await appapi.get(
      `/ecoinitiativeTemplates/${user.enterpriseId}`,
      {
        filter:
          selectedTemplateFilter === "all" ? null : selectedTemplateFilter,
      },
      {
        cancelToken: source1.token,
      }
    );
    if (response.ok) {
      const data = response.data;
      if (data.status === "success") {
        setEcoInitiativesTemplateData(data.data);
      } else {
        setEcoInitiativesTemplateData([]);
      }
    } else {
      // showmessage(`Something Went Wrong!`, 3000, "error");
    }
  };

  const getEcoCampaignList = async () => {
    dispatch(setLoading(true));
    const response = await appapi.get(
      `/ecoinitiative/${user.enterpriseId}`,
      {
        page: backendpage,
        limit: rowsPerPage,
      },
      {
        cancelToken: source2.token,
      }
    );
    if (response.ok) {
      const data = response.data;
      if (data.status === "success") {
        setLength(data?.data?.length || 0);
        setEcoInitiativesData(data?.data?.campaignList);
      } else {
        setEcoInitiativesData([]);
        setLength(0);
      }
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      // showmessage(`Something Went Wrong!`, 3000, "error");
    }
  };

  const cancelCampaign = async () => {
    dispatch(setLoading(true));
    const response = await appapi.put(
      `/ecoinitiative/${user.enterpriseId}/${selectedTemplate.ecoInitiativeScheduleId}/cancel`,
      {},
      { params: { updatedBy: user.name } }
    );
    if (response.ok) {
      const data = response.data;
      if (data.status === "success") {
        getEcoCampaignList();
        setSelectedTemplate({});
      } else {
      }
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      // showmessage(`Something Went Wrong!`, 2000, "error");
    }
  };

  const pauseOrStartCampaign = async () => {
    dispatch(setLoading(true));
    const response = await appapi.put(
      `/ecoinitiative/${user.enterpriseId}/${selectedTemplate.ecoInitiativeScheduleId}/pauseAndResume`,
      {
        isActiveStatus:
          selectedTemplate.campaignStatus === "running" ? "0" : "1",
        updatedBy: user.name,
      }
    );
    if (response.ok) {
      const data = response.data;
      if (data.status === "success") {
        getEcoCampaignList();
        setSelectedTemplate({});
      } else {
      }
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      // showmessage(`Something Went Wrong!`, 2000, "error");
    }
  };

  /**
   * handle eco campaign schedule
   * @param {*} item
   * eco template Item
   */
  const handleActNow = (item) => {
    // open dialog for schedule
    setSelectedTemplate(item);
    setShowEditCampaign(true);
  };

  /**
   * handle eco campaign schedule
   * @param {*} item
   * eco template Item
   */
  const handleDuplicateTemplateContent = (item) => {
    const clone = {
      ...item,
      isScheduleStatus: "0",
      ecoInitiativeScheduleId: null,
    };
    setSelectedTemplate(clone);
    setShowEditCampaign(true);
  };

  const handleEditCampaignClose = (refresh) => {
    setShowEditCampaign(false);
    setSelectedTemplate({});
    if (refresh) {
      getEcoCampaignList();
    }
  };

  /**
   * handle edit campaign
   * @param {*} item
   * eco schedule Item
   */
  const handleEditCampaign = (item) => {
    setSelectedTemplate({ ...item, isEdit: true });
    setShowEditCampaign(true);
  };

  /**
   * handle eco campaign status
   * @param {*} item
   * eco schedule Item
   */
  const handleStatus = (item) => {
    setSelectedTemplate(item);
    setShowConfirmation(true);
  };

  const handleEcoStatusDialogClose = (confirm) => {
    setShowConfirmation(false);
    if (confirm) {
      if (selectedTemplate.campaignStatus === "draft") {
        setShowEditCampaign(true);
      }
      if (selectedTemplate.campaignStatus === "scheduled") {
        cancelCampaign();
      }
      if (
        selectedTemplate.campaignStatus === "running" ||
        selectedTemplate.campaignStatus === "paused"
      ) {
        pauseOrStartCampaign();
      }
    } else {
      setSelectedTemplate({});
    }
  };

  /**
   * handle hide/show template
   */
  const handleShowTemplate = () => {
    setShowTemplate(!showTemplate);
  };

  /**
   * handle template filter show
   */
  const handleTemplateFilterClick = (event) => {
    setOpenTemplateFilter((prevOpen) => !prevOpen);
  };

  /**
   * handle template filter hide
   */
  const handleTemplateFilterClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenTemplateFilter((prevOpen) => !prevOpen);
  };

  /**
   * handle template filter hide
   * @param {*} filter
   * template filter
   */
  const handleTemplateFilterSelection = (filter) => {
    setOpenTemplateFilter(false);
    setSelectedTemplateFilter(filter);
    setShowTemplate(true);
  };

  /**
   *
   * @function handleChangePage
   * @function handleChangeRowsPerPage
   * handle Pagination
   */
  const handleChangePage = (event, newPage) => {
    setBackendPage(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setBackendPage(1);
    setPage(0);
  };

  return (
    <div
      className="ecoInitoativesContainer innerPadding "
      style={isMobile ? {} : { padding: "14px 0px 16px 0px" }}
    >
      <header
        className={isMobile ? "headerMobile" : ""}
        style={{ marginBottom: isMobile ? "12px" : "10px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          className={isMobile ? "" :"horizontal-padding"}
        >
          <Typography
            className="ff-Roboto fw-bold text-upper"
            style={{ color: colors.blackMain }}
          >
            ECO-INITIATIVES
          </Typography>

          {activeTab === 0 && isMobile && <IconButton
              aria-label="more"
              id="template-filter-button"
              aria-controls={
                openTemplateFilter ? "template-filter-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openTemplateFilter ? "true" : undefined}
              ref={anchorRef}
              onClick={handleTemplateFilterClick}
          >
            <FilterListIcon />
          </IconButton>}
        </Stack>
      </header>

      <Menu
              id="template-filter-menu"
              aria-labelledby="template-filter-button"
              anchorEl={anchorRef.current}
              open={openTemplateFilter}
              onClose={handleTemplateFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              className="popup-menu filter"
              style={{ marginTop: "8px" }}
            >
              {templateFilter.map((option, index) => (
                <MenuItem
                  key={option}
                  disabled={selectedTemplateFilter === option}
                  selected={selectedTemplateFilter === option}
                  onClick={(event) => handleTemplateFilterSelection(option)}
                  style={{ textTransform: "capitalize" }}
                  sx={{
                    width: 160,
                    margin: "0px 8px",
                    marginTop: index === 0 ? "8px" : "0",
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
      <div className={isMobile ? "" : "ecoInitoativesContainerBody"}>
        {!isMobile && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
            style={{ marginBottom: 13 }}
            className="horizontal-padding "
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              className={`diz-dropdown cursor-pointer ${
                openTemplateFilter ? "dd-opened" : ""
              }`}
              id="template-filter-button"
              aria-controls={
                openTemplateFilter ? "template-filter-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openTemplateFilter ? "true" : undefined}
              ref={anchorRef}
              onClick={handleTemplateFilterClick}
            >
              <Typography className="ff-Roboto fs-12 dropdown-label">
                Templates
              </Typography>
              <KeyboardArrowDownOutlinedIcon className="dropdown-icon" />
            </Stack>
            <Stack
              className="cursor-pointer"
              direction="row"
              alignItems={"center"}
              onClick={handleShowTemplate}
            >
              {showTemplate ? (
                <VisibilityOutlinedIcon
                  fontSize="10px"
                  style={{ color: colors.secondarymain, marginRight: "8px" }}
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  fontSize="10px"
                  style={{ color: colors.secondarymain, marginRight: "8px" }}
                />
              )}
              <Typography
                className="fs-12 ff-Roboto"
                style={{ color: colors.secondarymain }}
              >
                Hide Templates
              </Typography>
            </Stack>
          </Stack>
        )}

        {!isMobile && (
          <div
            style={{
              height: showTemplate ? "166px" : 0,
              opacity: showTemplate ? 1 : 0,
            }}
            className="ecoTemplate-container"
          >
            <HorizontalScroll>
              {ecoInitiativesTemplateData.map((item, index) => (
                <EcoTemplateCard
                  template={item}
                  itemId={index}
                  key={index}
                  actNow={() => handleActNow(item)}
                  permission={permission}
                />
              ))}
            </HorizontalScroll>
          </div>
        )}

        {activeTab === 0 && isMobile && (
          <Stack sx={{ pb: "57px" }}>
            {ecoInitiativesTemplateData.map((item, index) => (
              <EcoTemplateCard
                template={item}
                itemId={index}
                key={index}
                actNow={() => handleActNow(item)}
                permission={permission}
              />
            ))}
          </Stack>
        )}

        {!isMobile && (
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ marginBottom: 8 }}
            className="horizontal-padding "
          >
            <Typography
              className="ff-Roboto fw-bold"
              style={{ color: colors.deactive }}
            >
              Campaigns
            </Typography>
            {length > 0 && (
              <div className="inputFormStyle">
                <TablePagination
                  rowsPerPageOptions={[]}
                  count={length ? length : 1}
                  rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                  page={page ? page : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            )}
          </Stack>
        )}

        <div className={isMobile ? "" : "horizontal-padding"}>
          {!isMobile && (
            <>
              <EcoCampaignTableView
                ecoInitiatives={ecoInitiativesData}
                onStatus={handleStatus}
                onEdit={handleEditCampaign}
                onDuplicate={handleDuplicateTemplateContent}
                permission={permission}
              />
              {ecoInitiativesData?.length <= 0 && !isLoading ? (
                /**
                 * No Data Found
                 */
                <div className="noData">No Data Found</div>
              ) : (
                ""
              )}
            </>
          )}
          {isMobile && activeTab === 1 && (
            <Stack sx={{ pb: "60px" }}>
              <EcoCampaignListView
                ecoInitiatives={ecoInitiativesData}
                onStatus={handleStatus}
                permission={permission}
              />
              <Stack
                direction="row"
                justifyContent="flex-end"
                style={{ marginTop: "30px" }}
                className="inputFormStyle"
              >
                <TablePagination
                  rowsPerPageOptions={[]}
                  count={length ? length : 1}
                  rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                  page={page ? page : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Stack>
            </Stack>
          )}
        </div>
      </div>
      {isMobile && (
        <EcoInitiativeMobileFooter onTabChange={(tab) => setActiveTab(tab)} />
      )}
      <EcoStatusConfirmationDialog
        open={showConfirmation}
        handleClose={handleEcoStatusDialogClose}
        type={selectedTemplate.campaignStatus}
      />
      {showEditCampaign && (
        <EditEcoCampaign
          open={showEditCampaign}
          handleCloseDialog={handleEditCampaignClose}
          template={selectedTemplate}
        />
      )}
    </div>
  );
}
