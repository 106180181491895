import '../UserManagement.scss';
import React, { useState } from 'react';
import { Dialog, Stack, Typography, DialogActions, Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { dialogButtonStyle, dialogButtonCancel } from '../../../../utils/commonStyle';
import appapi from '../../../../api/apiClient';
import { showmessage } from '../../../../utils/toastr'
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/features/appSlice';

// //Transtion of dialog from downwards to up
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const sx = {
    "& .MuiDialog-container": {
        alignItems: "center",
    },
    "& .MuiPaper-root": {
        width: '376px',
        minHeight: '160px',
        padding: '24px 16px 16px 16px',
        borderRadius: '4px',
    }
};

export default function DeleteUserDialog({ open, onHandleDeleteClose, user, enterpriseUser, selectedUser, deleteChecker }) {

    const dispatch = useDispatch();
    const [confirmVal, setConfirmVal] = useState('');

    const handleDelete = async () => {
        dispatch(setLoading(true))
        let payload = []
        let params = {
            params: {
                enterpriseId: enterpriseUser.enterpriseId,
            }
        }
        if (deleteChecker) {
            selectedUser.map((item) => {
                payload.push(item.enterpriseUserId)
            })
        } else {
            payload = [user.enterpriseUserId]
        }
        const response = await appapi.delete(`user/${enterpriseUser.enterpriseId}`, params, {
            data: payload
        })
        if (response.ok) {
            dispatch(setLoading(false))
            const data = response.data;
            if (data.status === 'success') {
                // showmessage(`Delete Successfully!!`)
                onHandleDeleteClose(false)
                setConfirmVal('')
            } else {
                showmessage(data.message, 'error')
            }
        } else {
            dispatch(setLoading(false))
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setConfirmVal('')
        onHandleDeleteClose(true);
    }

    return (
        <Dialog
            sx={sx}
            open={open}
            onClose={handleClose}
            // TransitionComponent={Transition}
            className='AddUserContainer'
        >
            <Stack direction='row'>
                {/* <div className='deleteIconContainer'>
                    <DeleteIcon style={{ color: '#E62323' }} />
                </div> */}
                <Stack style={{ width: '100%' }}>
                    <Typography style={{ color: '#E62323', marginBottom: '12px' }} className='ff-Roboto fw-semibold wordBreak'>
                        Do you want to delete {(user?.name) ? (user?.name) : (user?.emailId)}?
                    </Typography>
                    <Typography style={{ color: '#474747' }} className='ff-Roboto fw-regular fs-12 wordBreak'>
                        Once deleted {(user?.name) ? (user?.name) : (user?.emailId)} will no longer have access to the portal.
                    </Typography>
                </Stack>
            </Stack>
            <Stack style={{ marginTop: '10px' }}>
                <TextField
                    fullWidth
                    placeholder='Type "delete" to confirm'
                    id='delete'
                    name='delete'
                    value={confirmVal || ''}
                    onChange={(e) => setConfirmVal(e.target.value.toLowerCase())}
                />
            </Stack>
            <DialogActions style={{
                height: '66px',
                padding: '0',
                marginTop: '20px',
                width: '100%'
            }}>
                <Button sx={dialogButtonCancel} style={{ width: '128px' }} variant='outlined' onClick={() => { onHandleDeleteClose(true); setConfirmVal('') }}>Cancel</Button>
                <Button sx={dialogButtonStyle}
                    style={{ width: '136px', marginLeft: '24px', background: '#C71212', opacity: confirmVal === 'delete' ? '1' : '0.5', cursor: confirmVal === 'delete' ? 'pointer' : 'default', pointerEvents: confirmVal === 'delete' ? 'initial' : 'none' }} onClick={handleDelete} variant="contained">Delete</Button>
            </DialogActions>
        </Dialog>
    )
}
