import React, { useState, useRef, useEffect } from "react"
import "./EWasteForm.scss"
import { useNavigate } from "react-router-dom"
import { Button, Stack, Typography, MenuItem, ListItemText, TextField, CircularProgress } from "@mui/material"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import FormTextField from "../../../../components/FormTextField"
import { buttonStyleForWebForm } from "../../../../utils/commonStyle"
import FieldTitle from "../../../../components/FieldTitle"
import { colors } from "../../../../utils/colors"
import FormButtonField from "../../../../components/FormButtonField"
import ImageUpload from "../../../../components/ImageUpload/ImageUpload"
import appapi from "../../../../api/apiClient"
import { uploadFileWithoutToken } from "../../../../utils/commonUtils"

const validationSchema = Yup.object().shape(
  {
    zone: Yup.string().required().label("Collection Zone"),
    eWaste: Yup.string().required().trim().label("Waste"),
    name: Yup.string().required().trim().label("Name"),
/*    bag: Yup.object()
      .when("bagWeight", {
        is: value => !value,
        then: Yup.object().required(),
      })
      .nullable()
      .label("Bag Size"),

    bagWeight: Yup.number()
      .when("bag", {
        is: value => !(value && value.title),
        then: Yup.number().moreThan(0).max(1000).typeError("Please enter the weight in numbers").required(),
      })
      .label("Bag Weight"),*/

    image: Yup.string().required().label("Uploading a picture"),
  },
  [["bag", "bagWeight"]]
)

const bagSize = [
  { title: "s", points: "<250 gm" },
  { title: "m", points: "250-500 gm" },
  { title: "l", points: ">500 gm" },
]

export default function EWasteForm({ scheduleid, locationid, ecoAction }) {
  const formRef = useRef()
  const navigate = useNavigate()
  const [zones, setZones] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (scheduleid) {
      getZonesList()
    }
  }, [scheduleid])

  useEffect(() => {
    if (zones.length > 0) {
      formRef.current.setFieldValue("zone", locationid)
      if (locationid) {
        const isSelectedZoneAvailable = zones.filter(zone => zone.collectionZoneId == locationid).length > 0
        if (!isSelectedZoneAvailable) {
          navigate("/")
        }
      }
    }
  }, [zones])

  const getZonesList = async () => {
    const response = await appapi.get(`webform/collectionZone/${scheduleid}`)
    if (response.ok) {
      const data = response.data
      if (data.status === "success") {
        setZones(data.data)
      } else {
        navigate("/")
      }
    } else {
      navigate("/")
    }
  }

  const submit = async values => {
    setLoading(true)
    const imageKey = await uploadFileWithoutToken(values.image, "ecoAction")
    const payload = {
      ecoInitiativeScheduleId: scheduleid,
      questions: [
        {
          ecoActionsQuestionBankId: 29,
          answer: values.zone.toString(),
        },
        {
          ecoActionsQuestionBankId: 30,
          answer: values.eWaste,
        },
        {
          ecoActionsQuestionBankId: 37,
          answer: values.name,
        },
     /*   {
          ecoActionsQuestionBankId: 31,
          answer: values.bag?.title || values.bagWeight,
        },*/
        {
          ecoActionsQuestionBankId: 32,
          answer: imageKey,
        },
      ],
    }
    setLoading(false)
    navigate(`/webform/verifyMobile`, {
      state: {
        data: payload,
        actionType: ecoAction?.actionType,
        image: values.image,
      },
    })
  }

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        zone: "",
        bag: null,
        eWaste: "",
        name : "",
        bagWeight: "",
        image: "",
      }}
      validationSchema={validationSchema}
      onSubmit={values => submit(values)}
    >
      {({ handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          {ecoAction?.formHeaderText && (
            <Typography className="ff-Roboto fw-regular fs-12" sx={{ color: "#49454F", mb: "20px" }}>
              {ecoAction?.formHeaderText}
            </Typography>
          )}
          <Stack flex={1} className="mt-9">
            <FieldTitle number={1} title={"Select your collection zone"} />
            <Stack>
              <TextField
                id="zone"
                name="zone"
                disabled={locationid ? true : false}
                variant="filled"
                value={values.zone || "Select"}
                onChange={e => {
                  handleChange(e)
                }}
                onBlur={handleBlur}
                select
                SelectProps={{
                  sx: {
                    "#zone": {
                      padding: "0px !important",
                      paddingLeft: "12px !important",
                      backgroundColor: "transparent !important",
                    },
                  },
                }}
                sx={{
                  "& .MuiFilledInput-root": {
                    backgroundColor: "#FAFAFA",
                    height: "56px !important",
                  },
                  "& .MuiFilledInput-root.Mui-focused": {
                    backgroundColor: "#FAFAFA !important",
                  },
                  "& .MuiFilledInput-root:hover": {
                    backgroundColor: "#FAFAFA !important",
                  },
                  "& .MuiFilledInput-root.Mui-disabled": {
                    backgroundColor: "#F3F3F3 !important",
                  },
                }}
              >
                {!locationid && (
                  <MenuItem value={"Select"} disabled>
                    <ListItemText primary={"Select"} />
                  </MenuItem>
                )}
                {zones.map(zone => (
                  <MenuItem key={zone.collectionZoneId} value={zone.collectionZoneId}>
                    <ListItemText primary={zone.collectionZoneName} />
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>

          <Stack flex={1} className="mt-14">
            <FieldTitle number={2} title={"Describe your waste"} />
            <FormTextField variant="filled" inputSx={{ backgroundColor: "#FAFAFA" }} inputProps={{ maxLength: 299 }} whiteSpace={false} name="eWaste" sx={{ mb: "2px" }} />
          </Stack>

          <Stack flex={1} className="mt-14">
            <FieldTitle number={3} title={"Let us know your name"} />
            <FormTextField variant="filled" inputSx={{ backgroundColor: "#FAFAFA" }} inputProps={{ maxLength: 30 }} whiteSpace={false} name="name" sx={{ mb: "2px" }} />
          </Stack>



          {/*
          <Stack flex={1} className="mt-14">
            <FieldTitle number={3} title={"Let us know your bag size "} />
            <FormButtonField
              name="bag"
              data={bagSize}
              subTitle={true}
              style={{ paddingRight: 24 }}
              onSelect={item => {
                setFieldValue("bagWeight", "")
              }}
            />
          </Stack>

          <Stack flex={1} alignItems={"center"}>
            <Typography className="ff-Roboto fw-medium fs-12" style={{ color: colors.black }}>
              OR
            </Typography>
          </Stack>

          <Stack flex={1} className="mt-14" direction={"row"} justifyContent={"center"}>
            <Stack justifyContent={"center"}>
              <Typography className="ff-Roboto fw-regular fs-16" style={{ color: colors.blackSecondary }}>
                Enter weight (gm)
              </Typography>
            </Stack>
            <Stack style={{ width: 100, paddingLeft: 25 }}>
              <FormTextField
                variant="filled"
                placeholder="- -"
                name="bagWeight"
                inputSx={{ backgroundColor: "#FAFAFA" }}
                sx={{ mb: "14px" }}
                onChange={e => {
                  setFieldValue("bag", "")
                }}
              />
            </Stack>
          </Stack>

          */}

          <Stack flex={1} className="mt-14">
            <FieldTitle number={4} title={"Upload"} />
            <ImageUpload
              url={values.image}
              inputId="image"
              showTitle={false}
              helpText="Picture of your E-waste"
              handleFile={file => {
                if (file) {
                  setFieldValue("image", file)
                } else {
                  setFieldValue("image", "")
                }
              }}
            />
          </Stack>

          <Button disabled={!(dirty && isValid) || loading} sx={{ ...buttonStyleForWebForm, width: "100%", mt: "24px" }} type="submit">
            NEXT <span>&nbsp;&nbsp;</span> {loading && <CircularProgress style={{color: 'white'}} size={"1rem"} />}
          </Button>
          <Stack direction="row" justifyContent="center">
            {ecoAction?.formFooterText && (
              <Typography className="ff-Roboto fw-regular fs-11" sx={{ color: "#49454F", mt: "10px", textAlign: "center" }}>
                {ecoAction?.formFooterText}
              </Typography>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
