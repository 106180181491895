import React from "react"
import { detailArr } from "../utils/commonUtils"
import { Stack, Typography } from "@mui/material"
import { colors } from "../utils/colors"

export default function FieldTitle({ number, title, isMandatory = true }) {
  return (
    <Stack direction="row" alignItems="center" style={{ marginBottom: 12 }}>
      <Typography className="ff-Roboto fw-regular fs-16" style={{ color: colors.blackSecondary, paddingRight: 2 }}>
        {number}
        {"."}
      </Typography>
      {isMandatory && (
        <Typography className="ff-Roboto fw-regular fs-16" style={{ color: '#D94848', paddingRight: 2 }}>
          {"*"}
        </Typography>
      )}
      <Typography className="ff-Roboto fw-regular fs-16" style={{ color: colors.blackSecondary, }}>
        {title}
      </Typography>
    </Stack>
  )
}
