import "./SignUp.scss"
import React, { useState, useRef } from "react"
import diztextlogo from "../../../assets/images/diztextlogo.svg"
import wavesbg from "../../../assets/images/wavesbg.svg"
import mobilewavybg from "../../../assets/images/mobilewavybg.svg"
import * as yup from "yup"
import { Formik, Form } from "formik"
import { Stack, Typography, useMediaQuery, Button, FormControlLabel, Checkbox } from "@mui/material"
import ReCAPTCHA from "../../../components/react-google-recaptcha-enterprise"

import { setLoading } from "../../../redux/features/appSlice"
import { colors } from "../../../utils/colors"
import { buttonStyle1 } from "../../../utils/commonStyle"
import { useNavigate } from "react-router-dom"
import appapi from "../../../api/apiClient"
import { useDispatch } from "react-redux"
import { showmessage } from "../../../utils/toastr"
import SocialCard from "../../../components/SocialCard"
import DetailCard from "../../../components/DetailCard"
import dizlogoMobile from "../../../assets/images/dizlogoMobile.svg"
import FormTextField from "../../../components/FormTextField"

/**
 * Validation Schema for register
 */
const validationSchema = yup.object({
  email: yup
    .string("Enter your Email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is Invalid")
    .required("")
    .trim(),
  mobile: yup
    .string()
    .min(10, "Mobile Number must be 10 digits")
    .matches(/^[0-9]*$/, "Mobile Number should be only numbers")
    .label("Mobile Number"),
  name: yup.string("Enter your Name").min(3, "Name must be minimum of 3 characters").max(100, "Name must be maximum of 100 characters").required("").trim(),
  organization: yup.string("Enter your Organization").required("").max(50, "Organization must be maximum of 50 characters").trim(),
  password: yup
    .string("Enter your Password")
    // .min(8, 'Password must be minimum of 6 characters')
    // .max(12, 'Password must be maximum of 12 characters')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/, "Enter a password of minimum 8 and maximum 12 characters with a combination of capital letters, small letters, symbols, and numbers.")
    .required("Password is requried")
    .trim(),
  termsandCondition: yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
})

export default function SignUp() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width:1000px)")
  const formRef = useRef()

  const [visibility, setVisibility] = useState(false)
  const [formSubmitted, SetFormSubmitted] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [message, setMessage] = useState({
    status: "",
    msg: "",
  })

  const [data, setData] = useState({
    email: "",
    name: "",
    organization: "",
    password: "",
    termsandCondition: false,
    mobile: "",
  })

  /**
   *
   * @returns Redirect to initial page which is login
   */
  const onClickLoginRoute = () => navigate(`/login`)

  /**
   * @param {email, password} obj
   * Sign Up new user
   */
  const signUp = async obj => {
    dispatch(setLoading(true))
    let payload = {
      emailId: obj.email,
      name: obj.name,
      password: obj.password,
      organization: obj.organization,
      token: recaptchaToken,
      mobile: obj.mobile,
    }
    const response = await appapi.post(`/auth/signup`, payload)
    if (response.ok) {
      const data = response.data
      dispatch(setLoading(false))
      if (data.status === "success") {
        setMessage({
          status: data?.status,
          msg: data?.message,
        })
        SetFormSubmitted(true)
      } else {
        setMessage({
          status: data.status,
          msg: data.message,
        })
        showmessage(data.message, "error")
      }
    } else {
      dispatch(setLoading(false))
      // showmessage(`Something Went Wrong!`, 2000, 'error')
    }
  }

  const onChange = value => {
    setRecaptchaToken(value ? value : null)
  }

  const SignUpForm = () => {
    return (
      <>
        <Typography className="fw-bold ff-Roboto createHeading" color={colors.primaryTxt}>
          {" "}
          Sign up{" "}
        </Typography>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            email: data?.email || "",
            name: data?.name || "",
            organization: data?.organization || "",
            password: data?.password,
            termsandCondition: data?.termsandCondition,
            mobile: data?.mobile || "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => signUp(values)}
        >
          {({ handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty }) => (
            <Form noValidate autoComplete="off" style={{ minHeight: isMobile ? "670px" : "initial" }}>
              <Stack flex={1} className="mt-17">
                <FormTextField label="Email" name="email" sx={{ mb: 0 }} lowerCase={true} />
              </Stack>
              <Stack flex={1} className="mt-17">
                <FormTextField label="Mobile Number" inputProps={{ maxLength: 10 }} name="mobile" sx={{ mb: 0 }} />
              </Stack>
              <Stack flex={1} className="mt-14">
                <FormTextField label="Name" name="name" sx={{ mb: 0 }} whiteSpace={true} />
              </Stack>
              <Stack flex={1} className="mt-14">
                <FormTextField label="Organization" name="organization" sx={{ mb: 0 }} whiteSpace={true} />
              </Stack>
              <Stack flex={1} className="mt-14">
                <FormTextField label="Password" name="password" type="password" sx={{ mb: 0 }} visibility={visibility} setVisibility={val => setVisibility(val)} />
              </Stack>
              <Stack className="mt-17 checkboxStyle" sx={{ mb: "14px" }}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: colors.primaryTxt,
                        },
                      }}
                      id="termsandCondition"
                      name="termsandCondition"
                      checked={!!values.termsandCondition}
                      onChange={handleChange}
                    />
                  }
                  label={<Typography className="fs-12 ff-Roboto fw-regular black">I agree to the Terms of Service and Privacy Policy</Typography>}
                />
              </Stack>
              <div className="g-recaptcha">
                <ReCAPTCHA action="signup" sitekey="6LcQc-8jAAAAAN3MpjpfKCMhVYFZuBarUVwQq-IZ" onChange={onChange} />
              </div>
              <Button sx={{ ...buttonStyle1, width: "100%", mt: "2.3px" }} disabled={!(dirty && isValid) || !Boolean(recaptchaToken)} type="submit">
                Sign up
              </Button>
              <Stack direction="row" className="mt-16" justifyContent="center">
                <Typography className="ff-Roboto fw-regular fs-12" color={colors.gray}>
                  Already have an account?{" "}
                </Typography>
                <Typography onClick={onClickLoginRoute} className="ff-Roboto fw-bold fs-12" color={colors.primaryDark} style={{ marginLeft: "11px", cursor: "pointer" }}>
                  Login Here
                </Typography>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    )
  }

  const SignUpFormSubmitted = () => {
    return (
      <>
        <Stack>
          <Typography className="fw-bold ff-Roboto createHeading" color={colors.primaryTxt}>
            {" "}
            {message.status === "success" ? "Successful!" : "Failed!"}{" "}
          </Typography>
          <Typography className="fs-12 ff-Roboto fw-regular" color={colors.blackMain} style={{ marginBottom: "6px" }}>
            {message.msg}
          </Typography>
          <Button
            sx={{
              ...buttonStyle1,
              textTransform: "capitalize",
              marginBottom: "0",
            }}
            onClick={onClickLoginRoute}
            type="submit"
          >
            Go Back To Login
          </Button>
        </Stack>
      </>
    )
  }

  return (
    <div
      className="signUpContainer"
      style={{
        padding: isMobile ? "16px" : "0",
        minHeight: isMobile ? "auto" : "100vh",
      }}
    >
      {
        <>
          {formSubmitted ? (
            <>
              {isMobile ? (
                <div className="mobileViewContainer" style={{ minHeight: "calc(100vh - 32px)" }}>
                  <div
                    style={{
                      backgroundImage: `url(${mobilewavybg})`,
                      minHeight: "calc(100vh - 32px)",
                      backgroundPosition: "center bottom",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="logoImg" style={{ marginLeft: "8px" }}>
                      <img alt="dizLogo" src={dizlogoMobile} />
                    </div>
                    <div className="innerContainer" style={{ marginTop: "240px" }}>
                      {SignUpFormSubmitted()}
                    </div>
                  </div>
                </div>
              ) : (
                <Stack style={{ maxWidth: "1368px", margin: "auto" }} direction="row" justifyContent="space-between">
                  <Stack className="leftStack" style={{ width: "100%" }}>
                    <div className="logoImg">
                      <img style={{ visibility: "hidden" }} alt="dizLogo" src={diztextlogo} />
                    </div>
                    <div className="detailContainer" style={{ backgroundImage: `url(${wavesbg})` }}></div>
                    <Stack className="formSubmittedStyle">
                      <div className="logoImg">
                        <img alt="dizLogo" src={diztextlogo} />
                      </div>
                      <div style={{ marginTop: "22.916666666667vh" }}>{SignUpFormSubmitted()}</div>
                      <Stack
                        className="socialContainer"
                        style={{
                          margin: "30.208333333333vh auto 0 auto",
                        }}
                        direction="row"
                      >
                        {<SocialCard />}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </>
          ) : (
            <>
              {isMobile ? (
                <div className="mobileViewContainer" style={{ minHeight: "calc(100vh - 32px)" }}>
                  <div
                    style={{
                      backgroundImage: `url(${mobilewavybg})`,
                      minHeight: "calc(100vh - 32px)",
                      backgroundPosition: "center bottom",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="logoImg" style={{ marginLeft: "8px" }}>
                      <img alt="dizLogo" src={dizlogoMobile} />
                    </div>
                    <div className="innerContainer" style={{ marginTop: "28px" }}>
                      {SignUpForm()}
                    </div>
                  </div>
                </div>
              ) : (
                <Stack
                  style={{
                    maxWidth: "1368px",
                    margin: "auto",
                    minHeight: "768px",
                  }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Stack className="leftStack" style={{ width: "50%" }}>
                    <div className="logoImg">
                      <img alt="dizLogo" src={diztextlogo} />
                    </div>
                    <div className="detailContainer" style={{ backgroundImage: `url(${wavesbg})` }}>
                      <Stack className="detailsList">{<DetailCard />}</Stack>
                      <Stack className="socialContainer" direction="row">
                        {<SocialCard />}
                      </Stack>
                    </div>
                  </Stack>
                  <Stack className="rightStack" style={{ width: "50%" }}>
                    <div className="innerContainer">{SignUpForm()}</div>
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </>
      }
      {isMobile ? (
        <Stack className="footer" direction="row" justifyContent="space-between">
          <Stack className="socialContainer" direction="row">
            {<SocialCard />}
          </Stack>
          <Typography className="fs-10 ff-Roboto fw-medium black dizcopyrights">© 2022 Do It Zero Inc.</Typography>
        </Stack>
      ) : (
        <Typography className="fs-10 ff-Roboto fw-medium black dizcopyrights">© 2022 Do It Zero Inc.</Typography>
      )}
    </div>
  )
}
