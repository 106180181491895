import './EcoAnalyticsCard.scss';
import React from 'react';
import millify from "millify";
import { Stack, Typography, Box } from '@mui/material';
import { colors } from '../../../../../utils/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function EcoAnalyticsCard({ item, index, isMobile }) {

    return (
        <>
            {
                isMobile ?
                    <Box key={index} className='mobileAnalyticsCard'>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography style={{ width: '87px' }} className='ff-Roboto fs-14 fw-medium black'>
                                {item.name}
                            </Typography>
                            <Stack alignItems='flex-end'>
                                <Typography className='ff-Roboto fs-10 fw-medium' color={colors.active}>
                                    {
                                        item.slides ? item.slides : ''
                                    }
                                </Typography>
                                <Typography className='fs-32 ff-Roboto fw-bold'
                                    color={item.name === 'Unique Users Engaged' ? colors.primaryHover :
                                        item.name === 'Trees planted' ? colors.active : colors.blackMain}>
                                    {millify(item.data)}
                                </Typography>
                            </Stack>
                        </Stack>

                    </Box>
                    :
                    <Stack key={index} className='analyticsCard'>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography style={{ width: '87px' }} className='ff-Roboto fs-14 fw-medium black'>
                                {item.name}
                            </Typography>
                            {
                                item.name === 'Total GHG Impact' ?
                                    <InfoOutlinedIcon style={{ color: 'rgb(73, 96, 115, 0.5)', transform: 'scale(0.8)', cursor: 'pointer' }} /> :
                                    <Typography className='ff-Roboto fs-10 fw-medium' color={colors.active}>
                                        {
                                            item.slides ? item.slides : ''
                                        }
                                    </Typography>
                            }
                        </Stack>
                        <Typography className='fs-32 ff-Roboto fw-bold' color={colors.primaryHover}>
                            {millify(item.data)}
                        </Typography>
                    </Stack>
            }

        </>

    )
}
