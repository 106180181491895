import { Typography } from "@mui/material";
import { colors } from "../utils/colors";

export default function FormTextFieldLabel({ label, forgotPassword, onClickForgotPassword }) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}> {label} </Typography>
        {
          forgotPassword ?
            <Typography style={{ cursor: 'pointer' }} onClick={onClickForgotPassword} className='fs-14 ff-Roboto fw-regular mb-7' color={'rgb(6, 29, 48, 0.5)'}>Forgot Password?</Typography>
            : ''
        }

      </div>
      {/* <Typography
      style={{ color: colors.blackMain }}
      className="fs-14 ff-Roboto fw-medium mb-7"
    >
      {label}
    </Typography> */}
    </>

  );
}
