import React from 'react'
import { socialImgArr } from '../utils/commonUtils'

export default function SocialCard() {
    return (
        socialImgArr.map((item, index) => (
            <a href={item.link} target='_blank' rel="noreferrer" style={{ cursor: 'pointer', marginRight: index === socialImgArr.length - 1 ? '0' : '24px' }} key={index}>
                <img src={item.iconName} alt={item.name} />
            </a>
        ))
    )
}
