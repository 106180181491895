import '../Integration.scss';
import React, { useState } from 'react';
import { Stack, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { colors } from '../../../../utils/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Moment from 'moment';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';

export default function IntegrationCard({ payload, onClickCopy, onHandleAction, permission }) {

    //menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl)

    const handleMenuOpen = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    const onHandleMenuAction = (event, row, type) => {
        handleMenuClose(event)
        event.stopPropagation()
        onHandleAction(row, type)
    }


    return (
        <Box className='integrationCard'>
            <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                <Stack style={{ width: '91%' }}>
                    <Typography className='fs-12 ff-Roboto fw-medium' style={{ color: payload?.isActiveStatus === '0' ? '#AAA' : colors.primaryHover, marginBottom: '4px' }}>
                        {payload?.label}
                    </Typography>

                    <Typography className='ellipseStyle fs-14 ff-Roboto fw-regular' style={{ color: payload?.isActiveStatus === '0' ? '#AAA' : '#000', width: '99%' }}>
                        {
                            payload.isClicked ?
                                <>
                                    {payload.apiKey}
                                </>
                                :
                                <>
                                    *****************************************
                                </>
                        }
                    </Typography>
                </Stack>
                {
                    <>
                        <IconButton
                            id='resource-button'
                            onClick={handleMenuOpen}
                            aria-controls={open ? 'resource-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            style={{ padding: '0' }}
                        >
                            <MoreVertIcon style={{ color: '#A4AFB9' }} />
                        </IconButton>
                        <Menu PaperProps={{
                            sx: {
                                width: '128px',
                                "& .MuiList-root": {
                                    padding: '0'
                                }
                            },
                        }}
                            className='appbar' id='resource-menu' anchorEl={anchorEl} open={open}
                            MenuListProps={{
                                "aria-labelledby": 'resource-button',
                            }}
                            onClose={handleMenuClose}
                        >
                            <MenuItem disabled={permission && permission.deleteAccess === '0'} onClick={(event) => onHandleMenuAction(event, payload, 'delete')} className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                <Stack direction='row' alignItems='center'>
                                    <DeleteIcon style={{ color: colors.blackMain, transform: 'scale(0.8888)' }} />
                                    <Typography className='ff-roboto fs-12 fw-semibold' style={{ paddingLeft: '16px' }}>
                                        Delete
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem disabled={payload?.isActiveStatus === '0' || (permission && permission.activateAccess === '0')} onClick={(event) => onHandleMenuAction(event, payload, 'deactivate')} className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                <Stack direction='row' alignItems='center'>
                                    <BlockIcon style={{ color: colors.blackMain, transform: 'scale(0.7276)' }} />
                                    <Typography className='ff-roboto fs-12 fw-semibold' style={{ paddingLeft: '16px' }}>
                                        Deactivate
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        </Menu>
                    </>
                }
            </Stack>
            <Stack style={{ marginTop: '23px' }} direction='row' alignItems='center' justifyContent='space-between'>
                <Stack style={{ width: '91%' }}>
                    <Typography className='fs-12 ff-Roboto fw-regular' style={{ color: payload?.isActiveStatus === '0' ? '#AAA' : '#000' }}>
                        {Moment(payload.createdAt).format('DD MMM, yyyy')}
                    </Typography>
                </Stack>
                {
                    payload?.isActiveStatus === '0' ? '' :
                        <IconButton onClick={(event) => onClickCopy(event, payload)} style={{ padding: '0' }}>
                            <ContentCopyIcon style={{ transform: 'scale(0.7276)' }} />
                        </IconButton>
                }

            </Stack>
        </Box >
    )
}
