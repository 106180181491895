export const colors = {
    primaryDark: '#106AA5',
    primaryHover: '#3787C9',
    primary: '#3689C9', //cyanBllue Azure
    primaryTxt: '#0C78BF',
    primaryLight: '#2B80BE',
    secondary: '#E2F2FF',
    secondaryLight: '#F2F9FF',
    white: '#FFF',
    black: '#000',
    blackPrimary: '#7F7F7F',
    blackSecondary: '#707070',
    blackMain: '#061D30',
    gray: '#474747',
    active: '#48B549',
    deactive: '#061D30',
    invited: 'rgb(55, 135, 201)',
    hover: '#3787C912',
    header: '#EDEDED',
    secondarymain: '#496073',
    buttonColor: '#8AC865',
    danger: '#A61B30',
    dangerLight: '#FFE9E8',
    dangerDark: '#C71212',
    philippineGray: '#8c8c8c',
    due30Daysbg: '#D7F5DD',
    due30Dayscolor: '#1A7D36',
    overdue30bg: '#FFE9E8',
    overdue30color: '#A61B30',
    overdue5bg: '#FFF8C5',
    overdue5color: '#543508',
}