import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import "./EcoStatusConfirmationDialog.scss";
import { colors } from './../../../../../utils/colors';

export default function EcoStatusConfirmationDialog({ open, handleClose, type }) {
    let title, subTitle, btnType = 'success', btnText;

    if(type === 'draft') {
      title = 'Do you want to start editing?';
      subTitle = 'You can save your changes while editing the campaigns and come back later to finish it.';
      btnText = 'EDIT';
    }

    if(type === 'scheduled') {
      btnType = 'warn';
      title = 'Do you want to cancel Campagin?';
      subTitle = 'You can save your changes while editing the campaigns and come back later to finish it.';
      btnText = 'STOP';
    }

    if(type === 'delete') {
      btnType = 'warn';
      title = 'Do you want to delete Campagin?';
      subTitle = 'You can save your changes while editing the campaigns and come back later to finish it.';
      btnText = 'DELETE';
    }

    if(type === 'running') {
      btnType = 'warn';
      title = 'Do you want to pause campaign?';
      subTitle = 'You can save your changes while editing the campaigns and come back later to finish it.';
      btnText = 'PAUSE';
    }

    if(type === 'paused') {
      title = 'Do you want to restart the campign?';
      subTitle = 'You can save your changes while editing the campaigns and come back later to finish it.';
      btnText = 'START';
    }


    const btnColor = btnType === 'warn' ? '#C71212' : '#8AC865';
    
  return (
      <Dialog open={open} onClose={() => handleClose(false)} maxWidth="xs" aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
        <DialogTitle style={{color: btnType === 'warn' ? colors.dangerDark : colors.blackMain}} className="ff-Roboto fs-16 fw-medium" id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{color: colors.blackMain}} className="ff-Roboto fs-12 fw-regular">
              {subTitle}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="ff-Roboto fs-14 fw-medium cancelBtn" variant="outlined" onClick={() => handleClose(false)}>CANCEL</Button>
              <Button onClick={() => handleClose(true)} className="ff-Roboto fs-14 fw-medium successBtn" variant="contained" style={{backgroundColor: btnColor}} >{btnText}</Button>
            </DialogActions>
      </Dialog>
  );
}
