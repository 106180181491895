import '../UserManagement.scss';
import React from 'react';
import { Dialog, Stack, Typography, IconButton, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import accountTie from '../../../../assets/images/accountTie.svg';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import accountCircle from '../../../../assets/images/accountCircle.svg';
import { activateStatusType } from '../../../../utils/commonUtils';
import { colors } from '../../../../utils/colors';
import { RenderIf } from '../../../../utils/customHooks';

// //Transtion of dialog from downwards to up
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function ViewUserDialog({ open, onHandleUserClose, user, onHandleEditDialog }) {

  const isMobile = useMediaQuery('(max-width:1000px)');

  /**
   * dialog style
   */
  const sx = {
    "& .MuiDialog-container": {
      alignItems: isMobile ? "end" : "center",
      WebkitAlignItems: isMobile ? 'end' : 'center',
      WebkitBoxAlign: isMobile ? 'end' : 'center'
    },
    "& .MuiPaper-root": {
      width: '376px',
      minHeight: '424px',
      margin: isMobile ? '0' : '8px',
      padding: '23px 16px',
      borderRadius: isMobile ? '16px 16px 0 0' : '4px',
    }
  };

  const IconContainer = ({ children, backgroundColor }) => {
    return (
      <div className='iconBox flex-center' style={{ background: backgroundColor, borderRadius: '4px', width: '32px', height: '32px' }}>
        {children}
      </div>
    )
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    onHandleUserClose();
  }

  return (
    <Dialog
      sx={sx}
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
      className='AddUserContainer'
    >
      <Stack style={{ borderBottom: '1px solid rgb(112,112,112,0.3)', paddingBottom: '10px', marginBottom: '16px' }} direction='row' justifyContent='space-between' alignItems='center' >
        <div className='flex'>
          {/* <PersonIcon style={{ color: '#8AB756' }} /> */}
          <Typography style={{ color: colors.blackMain }} className='fw-bold fs-14 ff-Roboto' >USER DETAILS</Typography>
        </div>
        <div>
          <RenderIf isTrue={user?.editAccess}>
            <IconButton onClick={() => onHandleEditDialog(user)}>
              <EditIcon className='svgTransform' style={{ color: colors.blackMain }} />
            </IconButton>
          </RenderIf>
          <IconButton onClick={onHandleUserClose}>
            <ClearIcon style={{ color: colors.blackMain, transform: 'scale(1.143)' }} />
          </IconButton>
        </div>

      </Stack>
      <Stack>
        <Stack direction='row' style={{ marginBottom: '16px' }}>
          <IconContainer backgroundColor={colors.secondary}>
            <MailOutlineIcon style={{ color: colors.primaryTxt, transform: 'scale(0.8)' }} />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Email</Typography>
            <Typography style={{ color: colors.blackMain }} className='ff-Roboto fs-14 fw-regular wordBreak'>{user?.emailId}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row' style={{ marginBottom: '16px' }}>
          <IconContainer backgroundColor={colors.secondary}>
            <GroupOutlinedIcon style={{ color: colors.primaryTxt, transform: 'scale(0.8)' }} />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Roles</Typography>
            <Typography style={{ color: colors.blackMain }} className='ff-Roboto fs-14 fw-regular wordBreak'>{user?.roleName || '-'}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row' style={{ marginBottom: '16px' }}>
          <IconContainer backgroundColor={colors.secondary}>
            <PersonOutlineIcon style={{ color: colors.primaryTxt }} />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Name</Typography>
            <Typography style={{ color: colors.blackMain }} className='ff-Roboto fs-14 fw-regular wordBreak'>{user?.name || '-'}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row' style={{ marginBottom: '16px' }}>
          <IconContainer backgroundColor={colors.secondary}>
            <img src={accountTie} style={{ filter: 'invert(32%) sepia(52%) saturate(2091%) hue-rotate(181deg) brightness(96%) contrast(91%)' }} alt='accountTie' />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Designation</Typography>
            <Typography style={{ color: colors.blackMain }} className='ff-Roboto fs-14 fw-regular wordBreak'>{user?.designation || '-'}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row' style={{ marginBottom: '16px' }}>
          <IconContainer backgroundColor={colors.secondary}>
            <DomainIcon style={{ color: colors.primaryTxt, transform: 'scale(0.8)' }} />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Department</Typography>
            <Typography style={{ color: colors.secondarymain }} className='ff-Roboto fs-14 fw-regular wordBreak'>{user?.department || '-'}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row' style={{ marginBottom: '16px' }}>
          <IconContainer backgroundColor={colors.secondary}>
            <FmdGoodOutlinedIcon style={{ color: colors.primaryTxt, transform: 'scale(0.8)' }} />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Location</Typography>
            <Typography style={{ color: colors.secondarymain }} className='ff-Roboto fs-14 fw-regular wordBreak'>{user?.cityName || '-'}</Typography>
          </Stack>
        </Stack>
        <Stack direction='row'>
          <IconContainer backgroundColor={user?.activateStatus === activateStatusType.active ? colors.secondary : '#E4E4E4'}>
            <img style={{ filter: user?.activateStatus === activateStatusType.active ? 'invert(32%) sepia(52%) saturate(2091%) hue-rotate(181deg) brightness(96%) contrast(91%)' : 'invert(29%) sepia(0%) saturate(0%) hue-rotate(359deg) brightness(92%) contrast(94%)' }} src={accountCircle} alt='accountTie' />
          </IconContainer>
          <Stack style={{ marginLeft: '16px', width: 'calc(100% - 48px)' }}>
            <Typography style={{ color: colors.primaryHover }} className='ff-Roboto fs-10 fw-semibold'>Status</Typography>
            <Typography style={{ color: colors.secondarymain }} className='ff-Roboto fs-14 fw-regular'>{user?.originalStatus || '-'}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}
