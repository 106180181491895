import React, { useEffect } from "react"
import { Route, Routes, Navigate, Outlet } from "react-router-dom"
import { AppRoute, AuthRoute, PermissionRoute } from "./ProtectedRoutes"
import { useDispatch, useSelector } from "react-redux"
import { login, logout } from "../redux/features/authSlice"
import { CircularProgress, Backdrop } from "@mui/material"
import { decrypt } from "../utils/Crpyto"
import loadable from "@loadable/component"
import SignUp from "../pages/Auth/signUp/SignUp"
import Login from "../pages/Auth/login/Login"
import ResetPassword from "../pages/Auth/resetPassword/ResetPassword"
import EcoInitiatives from "../pages/Layout/EcoInitiatives/EcoInitiatives"
import Analytics from "../pages/Layout/Analytics/Analytics"
import Payment from "../pages/Layout/Payment/Payment"
import Setting from "../pages/Layout/Setting/Setting"
import Integration from "../pages/Layout/integration/Integration"
import UserManagement from "../pages/Layout/usermanagment/UserManagement"
import appapi from "../api/apiClient"
import Privacy from "../pages/Legal/Privacy"
import Terms from "../pages/Legal/Terms"
import NotFound from "./../pages/NotFound/NotFound"
import AppRedirect from "../pages/AppRedirect/AppRedirect"
import PGSuccess from "../pages/PGSuccess/PGSuccess"
import ActNow from "../pages/EcoActions/ActNow/ActNow"
import VerifyMobile from "../pages/EcoActions/VerifyMobile/VerifyMobile"
import VerifyOTP from "../pages/EcoActions/VerifyOTP/VerifyOTP"
import Congrats from "../pages/EcoActions/Congrats/Congrats"
import EcoActionShare from "../pages/EcoActions/EcoActionShare/EcoActionShare"
import WorkShopShare from "../pages/EcoActions/WorkShopShare/WorkShopShare"

//lazy Load
//Auth
const Register = loadable(() => import("../pages/Auth/register/Register"))
const ResetConfirm = loadable(() => import("../pages/Auth/resetConfirmPassword/ResetConfirm"))
const FinalizeSignup = loadable(() => import("../pages/Auth/finalizeSignup/FinalizeSignup"))
// Dashboard
const Dashboard = loadable(() => import("../pages/Layout/Dashboard"))

export default function RootNavigation() {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.app)

  useEffect(() => {
    const value = localStorage.getItem("diz")
    if (value) {
      const decyptedData = decrypt(value)
      const userData = JSON.parse(decyptedData)
      dispatch(login(userData))
    }
  }, [dispatch])

  useEffect(() => {
    const tokenMonitor = response => {
      const { ok, status, config } = response
      if (!ok && status === 403) {
        dispatch(logout())
      }
    }

    appapi.axiosInstance.interceptors.request.use(
      config => {
        // console.log(config);
        const data = localStorage?.getItem("diz") || null
        if (data) {
          const decyptedData = decrypt(data)
          const userData = JSON.parse(decyptedData)
          const token = userData?.accessToken
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }
        }

        return config
      },
      error => {
        Promise.reject(error)
      }
    )

    appapi.addMonitor(tokenMonitor)
  }, [])

  /**
   * Route has been Protected based on user Logged In or not.
   */

  const host = window.location.hostname
  const isLegalHost = host === "legal.doitzero.com"
  const isAppHost = host === "d.doitzero.com"
  const isWebForm = host === "webform-dev.doitzero.com" || host === "webform.doitzero.com" || host.includes('localhost')
  return (
    <>
      <Routes>
        {isAppHost && <Route path="/app" element={<AppRedirect />} />}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        {isWebForm && (
          <Route path="/" element={<Outlet />}>
            <Route path=":scheduleid" element={<ActNow />} />
            <Route path=":scheduleid/:locationid" element={<ActNow />} />
            <Route path="" element={<NotFound />} />
          </Route>
        )}
        {isWebForm && (
          <Route path="/webform" element={<Outlet />}>
            <Route path="verifyMobile" element={<VerifyMobile />} />
            <Route path="VerifyOtp" element={<VerifyOTP />} />
            <Route path="congrats" element={<Congrats />} />
            <Route path="ecoActionShare" element={<EcoActionShare />} />
            <Route path="workShopShare" element={<WorkShopShare />} />
          </Route>
        )}
        {(isLegalHost || isAppHost) && <Route path="/" element={<NotFound />} />}
        {!(isLegalHost || isAppHost) && !isWebForm && (
          <>
            <Route
              path="/login"
              element={
                <AppRoute>
                  <Login />
                </AppRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <AppRoute>
                  <SignUp />
                </AppRoute>
              }
            />
            <Route
              path="/verify/:verificationToken"
              element={
                <AppRoute>
                  <FinalizeSignup />
                </AppRoute>
              }
            />
            <Route
              path="/forgotPassword"
              element={
                <AppRoute>
                  <ResetPassword />
                </AppRoute>
              }
            />
            <Route
              path="/register/:verificationToken"
              element={
                <AppRoute>
                  <Register />
                </AppRoute>
              }
            />
            <Route
              path="/resetPassword/:verificationToken"
              element={
                <AppRoute>
                  <ResetConfirm />
                </AppRoute>
              }
            />
            <Route
              path="/pgcallback/:mtid"
              element={
                <AppRoute>
                  <PGSuccess />
                </AppRoute>
              }
            />
            <Route
              path="/"
              element={
                <AuthRoute>
                  <Dashboard />
                </AuthRoute>
              }
            >
              {/* <Route index element={<Navigate to="userManagement" replace />} /> */}
              <Route
                path="userManagement"
                element={
                  <PermissionRoute name="User Management">
                    <UserManagement />
                  </PermissionRoute>
                }
              />
              <Route
                path="integrations"
                element={
                  <PermissionRoute name="Integrations">
                    <Integration />
                  </PermissionRoute>
                }
              />
              <Route
                path="analytics"
                element={
                  <PermissionRoute name="Analytics">
                    <Analytics />
                  </PermissionRoute>
                }
              />
              <Route
                path="ecoInitiatives"
                element={
                  <PermissionRoute name="Eco Initiatives">
                    <EcoInitiatives />
                  </PermissionRoute>
                }
              />
              <Route
                path="payments"
                element={
                  <PermissionRoute name="Payments">
                    <Payment />
                  </PermissionRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <PermissionRoute name="Settings">
                    <Setting />
                  </PermissionRoute>
                }
              />
            </Route>
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.modal + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
