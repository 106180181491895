import './Payment.scss';
import React, { useState, useEffect } from 'react';
import { colors } from '../../../utils/colors';
import { Stack, Typography, useMediaQuery, TablePagination, TableContainer, Table, TableBody, TableRow, TableCell, Button, Menu, MenuItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useSelector, useDispatch } from 'react-redux';
import EnchanedTableHeader from '../../../components/EnchanedTableHeader';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { buttonStyle4 } from '../../../utils/commonStyle';
import reportDownload from '../../../assets/images/reportDownload.svg';
import PaymentCard from './components/PaymentCard';
import appapi from '../../../api/apiClient';
import { setLoading } from '../../../redux/features/appSlice';
import { showmessage } from '../../../utils/toastr';
import Moment from 'moment';
import useRazorpay from "react-razorpay";
import APPCONFIG from '../../../config/apiConfig';

const headerCell = [
    {
        name: 'ID',
        width: '23.28625%'
    },
    {
        name: 'Invoice Date',
        width: '12.5%'
    },
    // {
    //     name: 'Description',
    //     width: '20.3225%'
    // },
    {
        name: 'Amount',
        marginFlag: true,
        width: '9.6875%'
    },
    {
        name: 'Due Date',
        width: '21.25%'
    },
    {
        name: 'Invoice',
        width: '22.66125%',
    },
    {
        name: 'Action',
        width: '7.5%'
    }
]

export default function Payment() {

    const [length, setLength] = useState(null);
    const [page, setPage] = useState(0);
    const [backendpage, setBackendPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paymentList, setPaymentList] = useState(null);
    const [overDueList, setOverDueList] = useState([]);
    const [permission, setPermision] = useState(null)

    const isMobile = useMediaQuery('(max-width:1000px)');

    const Razorpay = useRazorpay();

    //menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl)

    const handleMenuOpen = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    /**
     * retrieving user From Redux Store
     */
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();


    const handleChangePage = (event, newPage) => {
        setBackendPage(newPage + 1)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setBackendPage(1)
        setPage(0);
    };

    const handleGetPayments = async (fromTimer = true) => {
        dispatch(setLoading(fromTimer));
        const response = await appapi.get(`/payment/invoice/${user.enterpriseId}`, {
            enterpriseId: user.enterpriseId,
            page: backendpage,
            limit: rowsPerPage,
        })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data?.data;
                let arr = []
                userData.map((item, index) => {
                    item.Sno = (rowsPerPage * (page)) + index + 1
                    if (item.invoicePaymentStatus !== '1') {
                        let dueDate = item?.invoiceDueDate;
                        let date = Math.floor(Date.now() / 1000);
                        let dateNow = date * 1000;
                        let currentDate = Moment(dateNow).utc().format('MM/DD/YYYY');
                        let due = dueDate;
                        let dueEndDate = Moment(due).utc().format('MM/DD/YYYY');
                        let dayLeft = datediff(
                            parseDate(currentDate),
                            parseDate(dueEndDate)
                        );
                        item[`dayLeft`] = dayLeft
                        if (dayLeft < 0) {
                            arr.push(item)
                        }
                    }
                })
                setOverDueList(arr);
                setLength(data?.data?.length);
                // permission Task
                let find = user?.permission?.find((item) => item?.moduleName === 'integrations');
                setPermision(find);
                setPaymentList(userData);
            } else {
                setPaymentList([])
                setLength(0);
            }
            dispatch(setLoading(false))
        } else {
            dispatch(setLoading(false))
            // showmessage(`Something Went Wrong!`, 2000, 'error')
        }
    }

    useEffect(() => {
        handleGetPayments()
    }, [page, rowsPerPage, backendpage])

    useEffect(() => {
        const timer = setInterval(() => {
            let checkProcessing = paymentList?.some((item) => item.invoicePaymentStatus === '2')
            if (checkProcessing) {
                handleGetPayments(false)
            } else {
                clearInterval(timer)
            }
        }, 30000);
        return () => {
            clearInterval(timer)
        };
    }, [paymentList]);

    const handleDownload = (item, type) => {
        if (type === 'proforma') {
            window.open(item?.proFormaInvoice, "_blank")
        }
        else if (type === 'tax') {
            window.open(item?.taxInvoice, "_blank")
        } else {
            window.open(item?.receipt, "_blank")
        }
    }

    const createOrder = async (params) => {
        dispatch(setLoading(true));
        const response = await appapi.post(`/payment/${params.enterpriseId}/${params.invoiceDetailsId}`, {})
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let orderId = data?.data?.order_id;
                dispatch(setLoading(false))
                return orderId
            } else {
                dispatch(setLoading(false))
                showmessage(data.message)
            }
        } else {
            dispatch(setLoading(false))
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    }

    const onCloseRazorPayModal = async (params) => {
        const response = await appapi.put(`/payment/failed/${params.enterpriseId}/${params.invoiceDetailsId}`, {})
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                // console.log(data);
            } else {
                showmessage(data.message)
            }
        } else {
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    }

    /**
     * @function HandlePayment
     * @param {enterpriseId, invoiceDetailsId} params 
     */
    const handlePayment = async (params) => {
        const orderId = await createOrder(params);
        var options = {
            key: APPCONFIG.razorPayKey, // Enter the Key ID generated from the Dashboard
            order_id: orderId,
            //Success
            handler: function (response) {
                handleGetPayments()
            },
            //on Dialog or Modal Close
            modal: {
                ondismiss: function async() {
                    onCloseRazorPayModal(params)
                }
            }

        };

        const rzp1 = new Razorpay(options);

        //Failure
        rzp1.on("payment.failed", function (response) {
        });

        rzp1.open();
    }

    const datediff = (first, second) => {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    const parseDate = (str) => {
        var mdy = str.split('/');
        return new Date(mdy[2], mdy[0] - 1, mdy[1]);
    }

    return (
        <div className='paymentContainer inputFormStyle'>
            {
                overDueList && overDueList?.length > 0 ?
                    (overDueList?.length == 1 ?
                        <Stack className='showDuePayment' direction='row' justifyContent={isMobile ? 'flex-start' : 'center'} alignItems={isMobile ? 'flex-start' : 'center'}>
                            <WarningIcon style={{ color: colors.danger, transform: 'scale(0.604)', marginLeft: isMobile ? '15px' : '0', padding: isMobile ? '6px 0 6px 0' : '0' }} />
                            <Typography className='ff-Roboto fs-12 fw-regular' color={colors.danger} style={{ marginLeft: '10px', padding: isMobile ? '6px 0 6px 0' : '0', width: isMobile ? '264px' : 'initial' }}>
                                Invoice {overDueList[0].invoiceId} is overdue. Please pay immediately to avoid service disruption.
                            </Typography>
                        </Stack> :
                        <Stack className='showDuePayment' direction='row' justifyContent={isMobile ? 'flex-start' : 'center'} alignItems={isMobile ? 'flex-start' : 'center'}>
                            <WarningIcon style={{ color: colors.danger, transform: 'scale(0.604)', marginLeft: isMobile ? '15px' : '0', padding: isMobile ? '6px 0 6px 0' : '0' }} />
                            <Typography className='ff-Roboto fs-12 fw-regular' color={colors.danger} style={{ marginLeft: '10px', padding: isMobile ? '6px 0 6px 0' : '0', width: isMobile ? '264px' : 'initial' }}>
                                Some Invoice is overdue. Please pay immediately to avoid service disruption.
                            </Typography>
                        </Stack>
                    )
                    :
                    null
            }

            <div className='innerPadding' style={{ paddingTop: isMobile ? '5px' : '8px' }}>
                {/**
             * Top Toolbar
             */}
                <header className={isMobile && 'headerMobile'} style={{ marginBottom: '8px' }}>
                    <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                        <Typography className='ff-Roboto fw-medium text-upper' style={{ color: colors.blackMain, paddingTop: isMobile ? '17px' : '0' }}>
                            PAYMENT
                        </Typography>
                        <div className='flex-aligncenter'>
                            {
                                isMobile ? null :
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        count={length ? length : 1}
                                        rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                                        page={page ? page : 0}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                            }

                        </div>
                    </Stack>
                </header>
                {/**
             * Table
             */}
                {
                    isMobile ?
                        <div style={{ marginBottom: '40px' }}>
                            {
                                paymentList && paymentList.map((item, index) => (
                                    <div key={index}>
                                        <PaymentCard
                                            data={item}
                                            handleDownload={(payload, type) => handleDownload(payload, type)}
                                            handlePayment={(payload) => handlePayment(payload)}
                                            permission={permission}
                                        />
                                    </div>
                                ))
                            }
                            {paymentList?.length <= 0 ?
                                /**
                                 * No Data Found
                                 */
                                <div className='noData'>
                                    No Data Found
                                </div> : ''
                            }
                            {
                                paymentList?.length > 0 &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        count={length ? length : 0}
                                        rowsPerPage={rowsPerPage ? rowsPerPage : 5}
                                        page={page ? page : 0}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <div className='tableContainer'>
                            <TableContainer sx={{ maxHeight: 'calc(100vh - 128px)' }}>
                                <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                                    <EnchanedTableHeader headerCell={headerCell} initialWidth={96.875} />
                                    <TableBody className='mainUserBodyTable'>
                                        {
                                            paymentList && paymentList.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align='center' className='color-inherit' style={{ width: 'calc(100% - 96.875%)' }}>
                                                        <Typography className={'ff-Roboto fs-12 fw-regular'}>
                                                            {item?.Sno}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className='color-inherit' style={{ width: '23.28625%' }}>
                                                        <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                            {item?.invoiceId}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className='color-inherit' style={{ width: 'calc(100% - 87.5%)' }}>
                                                        <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                            {Moment(item.invoiceDate).format('DD MMM, yyyy')}
                                                        </Typography>
                                                    </TableCell>
                                                    {/* <TableCell className='color-inherit' style={{ width: 'calc(100% - 79.6875%)' }}>
                                                        <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                            {item?.invoiceDescription || '-'}
                                                        </Typography>
                                                    </TableCell> */}
                                                    <TableCell className='color-inherit' style={{ width: 'calc(100% - 90.3125%)' }}>
                                                        <Typography style={{ textAlign: 'end', marginRight: '40px', whiteSpace: 'nowrap' }} className='fs-12 ff-Roboto fw-bold'>
                                                            ₹{item.invoiceAmount}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className='color-inherit' style={{ width: 'calc(100% - 78.75%)' }}>
                                                        <Stack direction='row'>
                                                            <Typography className='fs-12 ff-Roboto fw-regular'>
                                                                {Moment(item.invoiceDueDate).format('DD MMM, yyyy')}
                                                            </Typography>
                                                            {
                                                                item?.invoicePaymentStatus === '1' ? null :
                                                                    item?.dayLeft > 0 ?
                                                                        <Stack alignItems='center' justifyContent='center' style={{ background: colors.due30Daysbg, color: colors.due30Dayscolor }} className='dueDataContainer'>
                                                                            <Typography className='fs-10 ff-Roboto fw-medium'>
                                                                                Due in {item?.dayLeft} days
                                                                            </Typography>
                                                                        </Stack>
                                                                        :
                                                                        item?.dayLeft === 0 ? '' :
                                                                            <Stack alignItems='center' justifyContent='center' style={{ background: item?.dayLeft >= -5 ? colors.overdue5bg : colors.overdue30bg, color: item?.dayLeft >= -5 ? colors.overdue5color : colors.overdue30color }} className='dueDataContainer'>
                                                                                <Typography className='fs-10 ff-Roboto fw-medium'>
                                                                                    {item?.dayLeft * -1} days overDue
                                                                                </Typography>
                                                                            </Stack>
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell className='color-inherit' style={{ width: '22.66125%' }}>
                                                        <Stack direction='row' alignItems='center'>

                                                            {
                                                                ((item?.invoicePaymentStatus === '0' || item?.invoicePaymentStatus === '2') &&
                                                                    <Stack direction='row' onClick={() => handleDownload(item, 'proforma')} style={{ cursor: 'pointer' }} >
                                                                        <img style={{ marginRight: '8px' }} alt='' src={reportDownload} />
                                                                        <Typography style={{ cursor: 'pointer', width: 'auto' }} className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                                            Proforma Invoice
                                                                        </Typography>
                                                                    </Stack>
                                                                ) ||
                                                                (item?.invoicePaymentStatus === '1' &&
                                                                    <>
                                                                        <Stack
                                                                            direction='row'
                                                                            id='resource-button'
                                                                            onClick={handleMenuOpen}
                                                                            aria-controls={open ? 'resource-menu' : undefined}
                                                                            aria-haspopup='true'
                                                                            aria-expanded={open ? 'true' : undefined}
                                                                            style={{ cursor: 'pointer' }}
                                                                        >
                                                                            <img style={{ marginRight: '8px' }} alt='' src={reportDownload} />
                                                                            <Typography

                                                                                style={{ cursor: 'pointer', width: 'auto' }} className='fs-12 ff-Roboto fw-regular ellipseStyle'>
                                                                                Download
                                                                            </Typography>
                                                                        </Stack>

                                                                        <Menu PaperProps={{
                                                                            sx: {
                                                                                width: '128px',
                                                                                "& .MuiList-root": {
                                                                                    padding: '0'
                                                                                }
                                                                            },
                                                                        }}
                                                                            className='appbar' id='resource-menu' anchorEl={anchorEl} open={open}
                                                                            MenuListProps={{
                                                                                "aria-labelledby": 'resource-button',
                                                                            }}
                                                                            onClose={handleMenuClose}
                                                                        >
                                                                            <MenuItem
                                                                                onClick={() => handleDownload(item, 'proforma')}
                                                                                style={{ minHeight: "30px" }}
                                                                                className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                                                                <Stack direction='row' alignItems='center'>
                                                                                    <Typography className='ff-roboto fs-12 fw-semibold'>
                                                                                        Proforma Invoice
                                                                                    </Typography>
                                                                                </Stack>
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                disabled={item?.taxInvoice == null}
                                                                                onClick={() => handleDownload(item, 'tax')}
                                                                                style={{ minHeight: "30px" }}
                                                                                className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                                                                <Stack direction='row' alignItems='center'>
                                                                                    <Typography className='ff-roboto fs-12 fw-semibold'>
                                                                                        Tax Invoice
                                                                                    </Typography>
                                                                                </Stack>
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                disabled={item?.receipt == null}
                                                                                onClick={() => handleDownload(item, 'receipt')}
                                                                                style={{ minHeight: "30px" }}
                                                                                className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                                                                <Stack direction='row' alignItems='center'>
                                                                                    <Typography className='ff-roboto fs-12 fw-semibold'>
                                                                                        Receipt
                                                                                    </Typography>
                                                                                </Stack>
                                                                            </MenuItem>
                                                                        </Menu>
                                                                    </>
                                                                )
                                                            }

                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell className='color-inherit' style={{ width: 'calc(100% - 92.5%)' }}>
                                                        {
                                                            (
                                                                item?.invoicePaymentStatus === '0' &&
                                                                <Button disabled={permission?.editAccess === '0'} onClick={() => handlePayment(item)} className='mobileviewStyle' sx={{ ...buttonStyle4, width: 'calc(100% - 92.5% - 8px)', height: '32px', minWidth: '-webkit-fill-available', marginRight: '8px', fontSize: '10px', textTransform: 'uppercase', fontWeight: '500' }} variant="contained">
                                                                    Pay
                                                                </Button>
                                                            )
                                                            ||
                                                            (
                                                                item?.invoicePaymentStatus === '1' && <Typography className='fs-10 ff-Roboto fw-medium ellipseStyle'>
                                                                    PAID
                                                                </Typography>
                                                            ) ||
                                                            (
                                                                item?.invoicePaymentStatus === '2' &&
                                                                <Typography className='fs-10 ff-Roboto fw-medium ellipseStyle'>
                                                                    PROCESSING
                                                                </Typography>
                                                            )
                                                        }


                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {paymentList?.length <= 0 ?
                                /**
                                 * No Data Found
                                 */
                                <div className='noData'>
                                    No Data Found
                                </div> : ''
                            }
                        </div>
                }
            </div>
        </div>
    )
}
