import './CampaignSection.scss'
import React, { useState, useEffect } from 'react';
import CampaignTable from './CampaignTable';
import CampaignCard from './CampaignCard';
import { TablePagination, useMediaQuery, Typography, Stack, Box, Menu, MenuItem, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { colors } from '../../../../../utils/colors';
import TablePaginationActions from '../../../../../components/TablePaginationActions';
import { reportSelect } from '../../../../../utils/commonUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import appapi from '../../../../../api/apiClient';
import { setLoading } from '../../../../../redux/features/appSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function CampaignSection() {

    const isMobile = useMediaQuery('(max-width:1000px)');

    const [length, setLength] = useState(null);
    const [page, setPage] = useState(0);
    const [backendpage, setBackendPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [allSelected, setAllSelected] = useState(false);

    const [selectedOption, setSelectedOption] = useState('monthly');
    const [displayfilter, setDisplayfilter] = useState('This month')
    const [campaignList, setCampaignList] = useState(null)

    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setBackendPage(newPage + 1)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setBackendPage(1)
        setPage(0);
    };

    // const handleChangeAll = (event, value) => {
    //     // console.log(value);
    //     setAllSelected(!allSelected)
    // }

    // const handleCheckedChange = (item) => {
    //     // console.log(item);
    // }

    //Options menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    //initial Call
    useEffect(() => {
        handleGetCampaignList()
    }, [selectedOption, page, rowsPerPage,]);

    useEffect(() => {
        reportSelect.map((item) => {
            if (item.value === selectedOption) {
                setDisplayfilter(item.name)
            }
        })
    }, [anchorEl]);

    const handleGetCampaignList = async () => {
        const response = await appapi.get(`/analytics/${user.enterpriseId}/ecoinitiativeList`, {
            enterpriseId: user.enterpriseId,
            filter: selectedOption,
            page: backendpage,
            limit: rowsPerPage
        })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data.data;
                setLength(data?.data.length)
                setCampaignList(userData)
            }
        }
    }

    return (
        <div className='inputFormStyle campaignSect'>
            {/**
               * Top Toolbar
               */}
            <header className={isMobile ? 'headerMobile' : ''} style={{ marginBottom: '8px', padding: isMobile ? '0' : '8px  0', position: 'initial' }}>
                <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                    <Stack direction='row' alignItems={'center'}>
                        <Typography className='ff-Roboto fw-medium text-upper' style={{ color: colors.blackMain }}>
                            Eco-initiatives
                        </Typography>
                        <Box
                            // className='optionsSelectionStyle diz-dropdown'
                            className={`optionsSelectionStyle diz-dropdown cursor-pointer ${open ? "dd-opened" : ""}`}
                            id='resource-button'
                            onClick={handleMenuOpen}
                            aria-controls={open ? 'resource-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Typography className='ff-Roboto fw-regular fs-12 dropdown-label' style={{ color: colors.black, width: '83%' }}>
                                {displayfilter}
                            </Typography>
                            <KeyboardArrowDownIcon style={{ color: 'rgb(0, 0, 0, 0.3)' }} className="dropdown-icon" />
                        </Box>
                        <Menu className='reportBar popup-menu filter' id='resource-menu' anchorEl={anchorEl} open={open}
                            MenuListProps={{
                                "aria-labelledby": 'resource-button',
                            }}
                            onClose={handleMenuClose}
                            PaperProps={{
                                style: {
                                    width: '176px',
                                },
                            }}
                        >
                            {
                                <FormControl>
                                    {/* <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        checked={selectedOption}
                                        name="radio-buttons-group"
                                    > */}
                                    {
                                        reportSelect.map((item, index) => (
                                            <MenuItem
                                                disableTouchRipple
                                                key={item.value}
                                                disabled={selectedOption === item.value}
                                                selected={selectedOption === item.value}
                                                onClick={() => { setSelectedOption(item.value); handleMenuClose() }}
                                                onClose={handleMenuClose}
                                                sx={{ margin: '0px 8px', marginTop: index === 0 ? '8px' : '0' }}
                                            >
                                                {/* <FormControlLabel value={item.value}
                                                        control={<Radio
                                                            size="small"
                                                            checked={item.value === selectedOption}
                                                            sx={{
                                                                color: colors.black,
                                                                padding: '0 0 0 8px',
                                                                '&.Mui-checked': {
                                                                    color: colors.black
                                                                },
                                                            }}
                                                            onChange={() => { setSelectedOption(item.value); handleMenuClose() }}
                                                        />} label={item.name} /> */}
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    }
                                    {/* </RadioGroup> */}
                                </FormControl>
                            }
                        </Menu>
                    </Stack>

                    <div className='flex-aligncenter'>
                        {
                            isMobile ? null :
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={length ? length : 1}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                        }
                    </div>
                </Stack>
            </header>
            {
                isMobile ?
                    <>
                        {
                            campaignList?.map((item, index) => (
                                <CampaignCard item={item} index={index} />
                            ))
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={length ? length : 1}
                                rowsPerPage={rowsPerPage ? rowsPerPage : 5}
                                page={page ? page : 0}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </div>
                    </>
                    :
                    <CampaignTable
                        length={length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        campaignList={campaignList}
                    // allSelected={allSelected}
                    // handleChangeAll={handleChangeAll}
                    // handleCheckedChange={handleCheckedChange}
                    />
            }

        </div>

    )
}
