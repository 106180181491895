import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import "./EcoCampaignStatusButton.scss";
import { colors } from './../../../../../utils/colors';

export default function EcoCampaignStatusButton({ status, onChangeStatus, permission }) {

  const isMobile = useMediaQuery("(max-width:600px)");

  let activeClass = "";
  if (status === "draft") {
    activeClass = "draft";
  }
  if (status === "scheduled") {
    activeClass = "otherStatus scheduled";
  }
  if (status === "running") {
    activeClass = "otherStatus running";
  }
  if (status === "paused") {
    activeClass = "otherStatus paused";
  }
  return (
    <Stack
      direction="row"
      style={{ height: "100%", marginTop: isMobile ? '16px' : '0' }}
      alignItems="center"
      className="row-hover"
    >
      {status !== "finished" && status !== "cancelled" ?
        (
          <Button
            className={`campaignStatusButton fs-12 ff-Roboto fw-regular ${activeClass} ${isMobile ? 'mobile' : ''}`}
            variant="outlined"
            onClick={onChangeStatus}
            style={{
              opacity: status === 'draft' && permission?.editAccess === '0' ? '0.5' : '1',
              pointerEvents: status === 'draft' && permission?.editAccess === '0' ? 'none' : 'initial',
              cursor: status === 'draft' && permission?.editAccess === '0' ? 'default' : 'pointer'
            }}
          ></Button>
        ) : (
          <Typography
            style={{ color: colors.primaryHover }}
            className="fs-12 ff-Roboto fw-regular "
          >
            {status === "finished" ? "Finished" : "Cancelled"}
          </Typography>
        )}
    </Stack>
  );
}
