import '../Payment.scss';
import React, { useState } from 'react';
import { Stack, Typography, Box, IconButton, Menu, MenuItem, Divider, Button, useMediaQuery } from '@mui/material';
import { colors } from '../../../../utils/colors';
import Moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { buttonStyle4 } from '../../../../utils/commonStyle';

export default function PaymentCard({ data, handleDownload, handlePayment, permission }) {

    const isMobile = useMediaQuery('(max-width:1000px)');

    //menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl)

    const handleMenuOpen = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }


    return (
        <Box className='paymentCard'>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Stack style={{ width: '60%' }}>
                    <Typography color={colors.blackMain} className='fs-12 ff-Roboto fw-regular'>
                        {data?.invoiceId}
                    </Typography>
                </Stack>
                {
                    <>
                        <Typography style={{ width: '27%', textAlign: 'end' }} className='fs-12 ff-Roboto fw-regular black'>
                            {Moment(data?.invoiceDate).format('DD MMM, yyyy')}
                        </Typography>
                        <MoreVertIcon
                            id='resource-button'
                            onClick={handleMenuOpen}
                            aria-controls={open ? 'resource-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            style={{ color: '#A4AFB9', fontSize: '15px', transform: 'scale(1.6)' }} />
                        <Menu PaperProps={{
                            sx: {
                                width: '128px',
                                "& .MuiList-root": {
                                    padding: '0'
                                }
                            },
                        }}
                            className='appbar' id='resource-menu' anchorEl={anchorEl} open={open}
                            MenuListProps={{
                                "aria-labelledby": 'resource-button',
                            }}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={() => handleDownload(data, 'proforma')}
                                style={{ minHeight: "30px" }}
                                className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                <Stack direction='row' alignItems='center'>
                                    <Typography className='ff-roboto fs-12 fw-semibold'>
                                        Proforma Invoice
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            {
                                data?.invoicePaymentStatus === '1' &&
                                <>
                                    <MenuItem
                                        onClick={() => handleDownload(data, 'tax')}
                                        style={{ minHeight: "30px" }}
                                        className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                        <Stack direction='row' alignItems='center'>
                                            <Typography className='ff-roboto fs-12 fw-semibold'>
                                                Tax Invoice
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                    <MenuItem
                                        disabled={data?.receipt == null}
                                        onClick={() => handleDownload(data, 'receipt')}
                                        style={{ minHeight: "30px" }}
                                        className='ff-Roboto fs-12' onClose={handleMenuClose}>
                                        <Stack direction='row' alignItems='center'>
                                            <Typography className='ff-roboto fs-12 fw-semibold'>
                                                Receipt
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                </>
                            }
                        </Menu>
                    </>
                }
            </Stack>
            {/* <Stack>
                <Typography color={colors.blackMain} style={{ lineHeight: 'initial' }} className='ff-Roboto fw-medium ellipseStyle'>
                    {data?.invoiceDescription || '-'}
                </Typography>
            </Stack> */}
            <Divider style={{ border: '1px solid #707070', marginTop: '10px', marginBottom: '8px' }} flexItem />
            <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                <Stack style={{ width: '60%' }}>
                    <Typography color={colors.blackMain} style={{ lineHeight: 'initial' }} className='fs-12 ff-Roboto fw-regular'>
                        Due Date
                    </Typography>
                    <Typography color={colors.blackMain} style={{ lineHeight: 'initial' }} className='fs-14 ff-Roboto fw-medium'>
                        {Moment(data?.invoiceDueDate).format('DD MMM, yyyy')}
                    </Typography>
                </Stack>
                {
                    data?.invoicePaymentStatus === '1' ? null :
                        data?.dayLeft > 0 ?
                            <Stack alignItems='center' justifyContent='center' style={{ background: colors.due30Daysbg, color: colors.due30Dayscolor }} className='dueDataContainer'>
                                <Typography className='fs-10 ff-Roboto fw-medium'>
                                    Due in {data?.dayLeft} days
                                </Typography>
                            </Stack>
                            :
                            data?.dayLeft === 0 ? '' :
                                <Stack alignItems='center' justifyContent='center' style={{ background: data?.dayLeft >= -5 ? colors.overdue5bg : colors.overdue30bg, color: data?.dayLeft >= -5 ? colors.overdue5color : colors.overdue30color }} className='dueDataContainer'>
                                    <Typography className='fs-10 ff-Roboto fw-medium'>
                                        {data?.dayLeft * -1} days overDue
                                    </Typography>
                                </Stack>
                }
            </Stack>
            <Stack direction='row' alignItems={isMobile ? 'center' : 'flex-start'} justifyContent='space-between' style={{ marginTop: '10px' }}>
                <Stack style={{ width: '60%' }}>
                    <Typography color={colors.primaryHover} className='fs-12 ff-Roboto fw-regular'>
                        Amount
                    </Typography>
                    <Typography color={colors.primaryHover} className='fs-20 ff-Roboto fw-bold'>
                        ₹ {data.invoiceAmount}
                    </Typography>
                </Stack>
                {
                    (
                        data?.invoicePaymentStatus === '0' &&
                        <Button disabled={permission?.editAccess === '0'} onClick={() => handlePayment(data)} sx={{ ...buttonStyle4, width: isMobile ? '88px' : 'calc(100% - 92.5% - 8px)', height: '32px', minWidth: isMobile ? 'initial' : '-webkit-fill-available', marginRight: '0px', fontSize: '10px', textTransform: 'uppercase', fontWeight: '500' }} variant="contained">
                            Pay
                        </Button>
                    ) ||
                    (
                        data?.invoicePaymentStatus === '1' &&
                        <Typography className='fs-10 ff-Roboto fw-medium' style={{ marginRight: '20px' }}>
                            PAID
                        </Typography>
                    ) ||
                    (
                        data?.invoicePaymentStatus === '2' &&
                        <Typography className='fs-10 ff-Roboto fw-medium' style={{ marginRight: '20px' }}>
                            PROCESSING
                        </Typography>
                    )
                }
            </Stack>
        </Box>
    )
}
