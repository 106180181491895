import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const AuthRoute = ({ children }) => {
    const { isLoggedIn, fromLogout } = useSelector((store) => store.auth);
    if (!isLoggedIn) {
        // user is not authenticated
        if (!fromLogout) {
            return <Navigate to="/signup" />;
        } else {
            return <Navigate to="/login" />;
        }
    }
    return children;
};

export const AppRoute = ({ children }) => {
    const { isLoggedIn } = useSelector((store) => store.auth);
    if (isLoggedIn) {
        // user is authenticated
        return <Navigate to="/" />;
    }
    return children;
};

//dashboard sidemenu permission based Protected Routes
export const PermissionRoute = ({ children, name }) => {
    const { sideMenuList } = useSelector((store) => store.auth);
    let find = sideMenuList.find((item) => item.name === name)
    if (find.flag === false) {
        return <Navigate to="/login" />;
    }
    return children;
}