import React, { createRef, useRef } from "react"
import "./WorkShopForm.scss"
import { useNavigate } from "react-router-dom"
import { Button, Stack, Typography } from "@mui/material"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import FormTextField from "../../../../components/FormTextField"
import { buttonStyleForWebForm } from "../../../../utils/commonStyle"
import FieldTitle from "../../../../components/FieldTitle"
import { useDispatch } from "react-redux"

const validationSchema = Yup.object().shape({
  name: Yup.string().required().trim().label("Name"),
  email: Yup.string().required().email().trim().label("Email"),
  eventCode: Yup.string().required().trim().label("Event Code"),
})

export default function WorkShopForm({ scheduleid, locationid, ecoAction }) {
  const formRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const submit = async values => {
    if (ecoAction?.eventCode === values.eventCode) {
      const payload = {
        ecoInitiativeScheduleId: scheduleid,
        questions: [
          {
            ecoActionsQuestionBankId: 26,
            answer: values.name,
          },
          {
            ecoActionsQuestionBankId: 27,
            answer: values.email,
          },
          {
            ecoActionsQuestionBankId: 28,
            answer: values.eventCode,
          },
        ],
      }
      navigate(`/webform/verifyMobile`, { state: { data: payload, actionType: ecoAction?.actionType, image: null } })
    } else {
      formRef.current?.setFieldError("eventCode", "Event Code is invalid")
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        name: "",
        email: "",
        eventCode: locationid ? locationid : '', // location id can also be a event code
      }}
      validationSchema={validationSchema}
      onSubmit={values => submit(values)}
    >
      {({ isValid, dirty }) => (
        <Form noValidate autoComplete="off">
          {ecoAction?.formHeaderText && (
            <Typography className="ff-Roboto fw-regular fs-12" sx={{ color: "#49454F", mb: "20px" }}>
              {ecoAction?.formHeaderText}
            </Typography>
          )}
          <Stack flex={1} className="mt-9">
            <FieldTitle number={1} title={"Your Name"} />
            <FormTextField variant="filled" inputSx={{ backgroundColor: "#FAFAFA" }} inputProps={{ maxLength: 50 }} whiteSpace={false} name="name" sx={{ mb: "2px" }} />
          </Stack>

          <Stack flex={1} className="mt-14">
            <FieldTitle number={2} title={"Your Email"} />
            <FormTextField variant="filled" inputSx={{ backgroundColor: "#FAFAFA" }} whiteSpace={false} name="email" sx={{ mb: "2px" }} />
          </Stack>

          <Stack flex={1} className="mt-14">
            <FieldTitle number={3} title={"Event Code"} />
            <FormTextField variant="filled" disabled={locationid ? true : false} inputSx={{ backgroundColor: "#FAFAFA" }} inputProps={{ maxLength: 20 }} whiteSpace={false} name="eventCode" sx={{ mb: "2px" }} />
          </Stack>

          <Button disabled={!(dirty && isValid)} sx={{ ...buttonStyleForWebForm, width: "100%", mt: "24px" }} type="submit">
            REGISTER
          </Button>
          <Stack direction="row" justifyContent="center">
            {ecoAction?.formFooterText && (
              <Typography className="ff-Roboto fw-regular fs-11" sx={{ color: "#49454F", mt: "10px", textAlign: "center" }}>
                {ecoAction?.formFooterText}
              </Typography>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
