import { useState, useEffect } from 'react';
import { dialogButtonStyle, dialogButtonCancel } from '../../../../utils/commonStyle';
import { Dialog, Stack, Typography, DialogActions, Button, TextField } from '@mui/material';
import { colors } from '../../../../utils/colors';

const sx = {
    "& .MuiDialog-container": {
        alignItems: "center",
    },
    "& .MuiPaper-root": {
        width: '376px',
        minHeight: '160px',
        padding: '24px 16px 16px 16px',
        borderRadius: '4px',
    }
};

export default function ChangeStatusDialog({ open, onHandleStatusClose, user, handleChangeStatus, deleteChecker, onHandleOverAllStatus, selectedUser }) {

    const [confirmVal, setConfirmVal] = useState('');
    const [checkVal, setCheckVal] = useState('')

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setConfirmVal('')
        onHandleStatusClose(true);
    }

    useEffect(() => {
        setConfirmVal('')
        if (deleteChecker) {
            let selecterUserList = selectedUser.filter((item) => item.activateStatus !== 'invite')
            let checker = selecterUserList?.every((item) => item.activateStatus === 'deactivate');
            setCheckVal(checker ? 'activate' : 'deactivate')
        } else {
            setCheckVal(user?.activateStatus === 'activate' ? 'deactivate' : 'activate')
        }
    }, [open])

    return (
        <Dialog
            sx={sx}
            open={open}
            onClose={handleClose}
            // TransitionComponent={Transition}
            className='AddUserContainer'
        >
            <Stack direction='row'>
                <Stack style={{ width: '100%' }}>
                    <Typography style={{ color: colors.dangerDark, marginBottom: '12px' }} className='ff-Roboto fw-semibold wordBreak'>
                        Do you want to {checkVal} {(user?.name) ? (user?.name) : (user?.emailId)}?
                    </Typography>
                    <Typography style={{ color: colors.blackMain }} className='ff-Roboto fw-regular fs-12 wordBreak'>
                        Are you sure you want to {checkVal} ? This is a reversible action.
                    </Typography>
                </Stack>
            </Stack>
            <Stack style={{ marginTop: '10px' }}>
                <TextField
                    fullWidth
                    placeholder={`Type " ${checkVal} " to confirm`}
                    id='delete'
                    name='delete'
                    value={confirmVal || ''}
                    onChange={(e) => {
                        setConfirmVal(e.target.value.toLowerCase());
                    }}
                />
            </Stack>
            <DialogActions style={{
                height: '66px',
                padding: '0',
                marginTop: '20px',
                width: '100%'
            }}>
                <Button sx={dialogButtonCancel} style={{ width: '128px' }} variant='outlined' onClick={() => { onHandleStatusClose(true); setConfirmVal('') }}>Cancel</Button>
                <Button sx={dialogButtonStyle} disabled={confirmVal === checkVal ? false : true} style={{ width: '136px', marginLeft: '24px', background: '#C71212' }} onClick={deleteChecker ? onHandleOverAllStatus : handleChangeStatus} variant="contained">
                    {checkVal}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
