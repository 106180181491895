import '../Integration.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/features/appSlice';
import appapi from '../../../../api/apiClient';
import { Dialog, Stack, Typography, Button, IconButton, TextField } from '@mui/material';
import { colors } from '../../../../utils/colors';
import { showmessage } from '../../../../utils/toastr';
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { buttonStyle2 } from '../../../../utils/commonStyle';

/**
 * Validation Schema for register
 */
const validationSchema = yup.object({
    label: yup
        .string('Enter your Label')
        .required('Label is Required')
        .max(50, 'Name must maximum of 50 characters')
        .trim(),
})

export default function AddApiDialog({ open, onHandleAddClose, enterpriseUser }) {

    const dispatch = useDispatch()

    const sx = {
        "& .MuiDialog-container": {
            alignItems: "center",
        },
        "& .MuiPaper-root": {
            width: '400px',
            minHeight: '160px',
            padding: '16px',
            borderRadius: '4px',
        }
    };

    const [data, setData] = useState({
        label: ''
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            createApiKey(values)
        },
    })

    const { handleSubmit, values, handleChange, handleBlur, isValid, dirty, resetForm } = formik;

    const createApiKey = async (obj) => {
        dispatch(setLoading(true))
        let params = {
            enterpriseId: enterpriseUser.enterpriseId
        }
        let payload = {
            label: obj.label,
            createdBy: enterpriseUser?.name
        }
        const response = await appapi.post(`/integration/${enterpriseUser.enterpriseId}`, payload, params)
        if (response.ok) {
            dispatch(setLoading(false))
            const data = response.data;
            if (data.status === 'success') {
                handleCloseDialog(false)
                resetForm()
                // showmessage(data.message)
            } else {
                showmessage(data.message, 'error')
            }
        } else {
            dispatch(setLoading(false))
            showmessage(`Something Went Wrong!`, 'error', 2000)
        }
    }

    const handleCloseDialog = (event) => {
        setData({
            label: ''
        })
        values.label = ''
        resetForm()
        onHandleAddClose(event)
    }

    const handleClose = (event, reason) => {
        // resetForm()
        if (reason && reason === "backdropClick")
            return;
        handleCloseDialog(true);
    }

    return (
        <Dialog
            sx={sx}
            open={open}
            onClose={handleClose}
            className='AddUserContainer'
        >
            <Stack style={{ paddingBottom: '10px', marginBottom: '16px', position: 'sticky', top: '0', zIndex: '99', background: '#FFF' }} direction='row' justifyContent='space-between' alignItems='center' >
                <div className='flex'>
                    <Typography style={{ color: colors.blackMain }} className='fw-bold fs-14 ff-Roboto' >CREATE API KEY</Typography>
                </div>
                <div>
                    <IconButton style={{ padding: '0' }} onClick={() => handleCloseDialog(true)}>
                        <ClearIcon style={{ color: colors.blackMain, transform: 'scale(1.143)' }} />
                    </IconButton>
                </div>
            </Stack>
            <form onSubmit={handleSubmit} className='editContainer'>
                <Stack>
                    <Stack>
                        <Typography className='fs-14 ff-Roboto fw-medium mb-7' color={colors.blackMain}>Label</Typography>
                        <TextField
                            fullWidth
                            id='label'
                            name='label'
                            value={values.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                            // error={touched.label && Boolean(errors.label)}
                            autoComplete='off'
                        />
                    </Stack>
                    <Button sx={{ ...buttonStyle2, width: '100%', marginTop: '36px' }} disabled={!(dirty && isValid)} type='submit' variant="contained">Done</Button>
                </Stack>
            </form>
        </Dialog>
    )
}
