import './EcoCampaignCard.scss';
import { Stack, Typography } from '@mui/material';
import moment from "moment";

import { colors } from './../../../../../utils/colors';
import EcoInitiativeCampaignBrand from '../EcoInitiativeCampaignBrand/EcoInitiativeCampaignBrand';
import refuse from "../../../../../assets/images/refuse.svg";
import recycle from "../../../../../assets/images/recycle.svg";
import reuse from "../../../../../assets/images/reuse.svg";
import EcoCampaignStatusButton from '../EcoCampaignStatusButton/EcoCampaignStatusButton';

export default function EcoCampaignCard({ campaign, onStatus, permission }) {

    const categoryBorderColor =
        campaign?.categoryType === "reuse"
            ? "#6EA4CE"
            : campaign?.categoryType === "recycle"
                ? "#57AF8C"
                : "#F48257";

    const categoryImg =
        campaign?.categoryType === "reuse"
            ? reuse
            : campaign?.categoryType === "recycle"
                ? recycle
                : refuse;

    return (
        <Stack className="campaignCardContainer" style={{ borderLeftColor: categoryBorderColor }}>
            <Stack direction="row">
                <Stack direction="row" sx={{ mb: '36px' }} alignItems="center">
                    <img className="categoryImg" src={categoryImg} alt={"categoryImg"} />
                    <Typography className="ff-Roboto fw-regular" style={{ color: colors.blackMain }}>{campaign?.title}</Typography>
                </Stack>
            </Stack>
            <Stack direction="row">
                <Stack direction="row" flex={1}>
                    <EcoInitiativeCampaignBrand campaign={campaign} />
                </Stack>
                <Stack flexGrow={1} />
                <Stack flex={1} >
                    <Typography className="ff-Roboto fw-medium fs-10" style={{ color: colors.primaryHover }}>Recent Acitivty</Typography>
                    <Typography className="ff-Roboto fw-medium fs-12" style={{ color: colors.blackMain, mb: '15px' }}>{moment(campaign?.updatedAt).format('DD-MM-YYYY')}</Typography>
                    <EcoCampaignStatusButton
                        status={campaign?.campaignStatus}
                        onChangeStatus={() => onStatus(campaign)}
                        permission={permission}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}