import { colors } from "./colors"

/**
 * Normal button style
 */
export const buttonStyle1 = {
  backgroundColor: colors.primaryDark,
  color: "#FFF",
  fontSize: "14px",
  height: "56px",
  fontWeight: "500",
  marginBottom: "0",
  marginTop: "15px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: colors.primaryHover,
  },
}

export const buttonStyleForWebForm = {
  backgroundColor: colors.primaryDark,
  color: "#FFF",
  fontSize: "14px",
  height: "56px",
  fontWeight: "500",
  marginBottom: "0",
  marginTop: "15px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: colors.primaryHover,
  },
  "&:disabled": { color: "rgba(255,255,255,0.25)" },
}

export const buttonOutlineStyle = {
  backgroundColor: "transparent",
  width: "100%",
  color: colors.primaryDark,
  borderWidth: 2,
  borderColor: colors.primaryDark,
  fontSize: "14px",
  height: "56px",
  fontWeight: "500",
  marginBottom: "0",
  marginTop: "15px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "transparent",
    borderWidth: 2,
    borderColor: colors.primaryDark,
  },
}

export const buttonStyle2 = {
  backgroundColor: "#8AC865",
  "&:hover": {
    backgroundColor: "#B2D787",
  },
  height: "56px",
  width: "169px",
  borderRadius: "4px",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  color: "#FFF",
}

/**
 * Add Button
 */
export const buttonStyle3 = {
  height: "32px",
  width: "96px",
  borderRadius: "20px",
  // backgroundColor: '#445C37',
  backgroundColor: "#8AC865",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: "500",
  marginRight: "10px",
  color: "#E9FCD3",
  "&:hover": {
    backgroundColor: "#8AC865",
  },
}

/**
 * editUser ButtonStyle
 */

export const dialogButtonStyle = {
  height: "56px",
  width: "169px",
  borderRadius: "4px",
  backgroundColor: colors.primaryDark,
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  color: "#FFF",
  "&:hover": {
    backgroundColor: colors.primaryHover,
  },
}

export const dialogButtonCancel = {
  height: "56px",
  width: "169px",
  borderRadius: "4px",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  border: "1px solid #474747",
  color: "#474747",
}

export const buttonStyle4 = {
  backgroundColor: "#8AC865",
  color: "#FFF",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  minWidth: "152px",
  height: "40px",
  textTransform: "none",
  marginRight: "24px",
  "&:hover": {
    backgroundColor: "#8AC865",
  },
}
