import "./Legal.scss";
import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import wavesbg from "../../assets/images/wavesbg.svg";
import { Stack } from "@mui/system";

export default function Terms() {
  const isMobile = useMediaQuery("(max-width:1000px)");

  return (
    <div
      className="legal-container"
      style={{
        padding: isMobile ? "16px" : "32px",
        minHeight: isMobile ? "auto" : "100vh",
        backgroundImage: `url(${wavesbg})`,
        backgroundPosition: "bottom 95% ",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={11} lg={7}>
          <Stack>
            <Typography
              className="fw-bold c-sp-16"
              style={{ textAlign: "center", fontSize: "24px" }}
            >
              Terms & Conditions
            </Typography>
            <Stack direction="row" sx={{ textAlign: "justify" }}>
              <Stack>
                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Acknowledgement and acceptance of these terms of services
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This page states the terms and conditions (the{" "}
                  <span className="fw-bold">T&C</span>) under which you may
                  access and/or use our App (see definitions section below).
                  Please read this page carefully. If you do not accept the
                  Terms and Conditions stated herein, you are advised not to use
                  the App
                  <br />
                  <br />
                  Do It Zero (India) Private Limited ("
                  <span className="fw-bold">Company</span>" or "
                  <span className="fw-bold">We</span>" or "
                  <span className="fw-bold">Us</span>" or “
                  <span className="fw-bold">Do It Zero</span>™”) is the owner of
                  this App, and all the content herein, and reserves the right
                  to change these T&C at any time without notice to you, and
                  upon such change, the same shall be effective immediately. You
                  agree to review these T&C on a regular basis. By using the
                  App, you are agreeing to be bound by the T&C as updated from
                  time to time.
                  <br />
                  <br />
                  These Terms and Conditions, together with the privacy policy
                  (the “<span className="fw-bold">Privacy Policy</span>”), and
                  other rules and policies, if any, posted on the App,
                  constitute the entire agreement between you and the Company
                  with respect to your use of the App, and they supersede all
                  prior communications, proposals, and terms and conditions
                  between you and the Company with respect to this App. Any
                  rights not expressly granted in these Terms and Conditions are
                  reserved by the Company.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Definitions
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  In this document, the following words and phrases shall have
                  the meanings set forth herein unless the context demands
                  otherwise:
                </Typography>

                <ol>
                  <li>
                    <Typography className="fs-14">
                      "Agreement" means the terms and conditions as detailed
                      herein, including the privacy policy. It shall include all
                      references to these terms and conditions as amended,
                      negated, supplemented, varied or replaced from time to
                      time.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "Authority" shall mean the relevant authority governing a
                      particular kind of business in India or other relevant
                      jurisdictions.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "Call Centre" means the call centre facility set up by Do
                      It Zero™ to assist its Users with respect to various
                      transactions and to furnish information etc., regarding
                      its products and such other related information. The Users
                      can presently reach Do It Zero™ on the numbers notified on
                      the App.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "User" means any person, whether legal or natural,
                      browsing and/or using this App to obtain information
                      pertaining to, or procure such goods/services as sold
                      herein.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "Record/s" means any electronic records that are submitted
                      through the App and includes physical records collected by
                      an agent or an employee of Do It Zero™ from the user or
                      User effecting the transaction.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "Personally identifiable information" shall mean such
                      information submitted by the User or User that enables
                      individual identification of the User either by itself or
                      in association with other material.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "Product" or "Service" shall mean, but not be limited to,
                      various products and services that Do It Zero™ may display
                      on the Website for sale from time to time.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      "App" shall mean the website{" "}
                      <a href="https://www.doitzero.com" target="_blank">
                        https://www.doitzero.com
                      </a>
                      <span className="fw-bold">
                        {" "}
                        as well as the mobile app on the Google playstore as
                        well as Apple App store and all other mobile
                        applications of the Company however distributed.
                      </span>
                    </Typography>
                  </li>
                </ol>

                <Typography
                  className="fw-bold"
                  style={{ marginBottom: "8px", marginTop: "1rem" }}
                >
                  Applicable Law
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This site is created and controlled by Do It Zero™. The laws
                  of India shall apply and govern all content herein and courts
                  in Hyderabad, Telangana, India shall have jurisdiction in
                  respect of all the terms, conditions and disclaimers herein.
                  <br />
                  <br />
                  The Company accepts no liability whatsoever, direct or
                  indirect for non-compliance with the laws of any country other
                  than that of India. The mere fact that the App can be accessed
                  in a country other than India would not result in such laws of
                  the said country being applicable to the App. <br />
                  <br />
                  It shall be the sole responsibility of foreign residents
                  including Non-Resident Indians in foreign jurisdictions to
                  verify whether the services available on the App can be
                  accessed and utilised in their respective jurisdictions. The
                  services which are part of the App do not constitute an offer
                  to sell or a solicitation of an offer to buy any
                  policies/products/securities to any person in any jurisdiction
                  where it is unlawful to make such an offer or solicitation.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Eligibility
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  You represent and warrant that you are competent and eligible
                  to enter into legally binding agreement and have the requisite
                  authority to enter into this Agreement and perform your
                  obligations herein. You shall not use this App if you are not
                  competent to contract under the applicable laws, rules and
                  regulations.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Registration Requirement
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  In the event that Do It Zero™ requires any user of this App to
                  submit to registration of their details in order to
                  authenticate their use of the same then the User shall, at all
                  times, be responsible for maintaining the confidentiality of
                  his/her User credentials, and shall be fully responsible for
                  all activities that occur by use of such user credentials.
                  Further, the User agrees not to use any other person's
                  credentials for any purpose whatsoever.
                  <br />
                  <br />
                  User is responsible for the security of his/her credentials
                  and for all transactions undertaken through the App using such
                  credentials. User confirms that he/she is the authorized
                  holder of the credit card or the original account used for the
                  transactions under this App. <br />
                  <br />
                  The Company shall not be responsible for any inconvenience
                  resulting from misuse of User credentials.
                  <br />
                  <br />
                  The User also agrees and undertakes to immediately notify Do
                  It Zero™ of any unauthorized use of the user credentials. The
                  User shall ensure that the user logs off from the App at the
                  end of each session at the App. Do It Zero™ shall not be
                  responsible for any direct or indirect loss or damage arising
                  out of the User’s failure to comply with this requirement.
                  <br />
                  <br />
                  As part of the registration process, the user does hereby
                  agree to provide Do It Zero™ his/her current, complete, and
                  accurate information as prompted and to maintain and update
                  this information as and when required keeping it current,
                  complete and accurate at all times.
                  <br />
                  <br />
                  If you provide any information that is untrue, inaccurate not
                  current or incomplete or if the Company has reasonable grounds
                  to suspect that such information is untrue, inaccurate, not
                  current, incomplete or mischievous, Do It Zero™ has the right
                  to indefinitely suspend or terminate your membership and can
                  block the User from using the App without any liability to
                  User.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Terms of Service
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This Agreement shall continue to be valid and in effect as
                  long as you are using the App or the services provided through
                  the App. Additional terms and conditions with respect to
                  products and services offered by the Company may apply.
                  <br />
                  <br />
                  You agree to use such products and services of the Company in
                  a manner consistent with the terms and conditions of this
                  Agreement and with all applicable laws and regulations.
                  <br />
                  <br />
                  Further, the User of this App does hereby agree and understand
                  that the information furnished on this App is for information
                  and guidance purpose only and must not be construed as a
                  recommendation by Do It Zero™ to purchase any product or
                  service. The decision to purchase the product or service
                  should be based on your own evaluation of the product or
                  service. Further, you must exercise due care in ensuring the
                  completeness and accuracy of the information provided. Do It
                  Zero™ and its affiliates, including without limitation
                  employees, shareholders and directors, shall not be liable for
                  any loss or damage caused by or arising out of reliance placed
                  on such incomplete or inaccurate information.
                  <br />
                  <br />
                  Further, by effecting a transaction that may be allowed by Do
                  It Zero™ on the App, you agree to provide complete and
                  irrevocable authority to Do It Zero™ to take steps and carry
                  out tasks that are essential and relevant to provision of the
                  services for which the transaction is effected. You further
                  acknowledge and accept that the records maintained by Do It
                  Zero™ with respect to the transactions effected by you at the
                  App shall be the conclusive evidence of such transaction and
                  shall be final and binding on you and your assigns and
                  successors.
                  <br />
                  <br />
                  Do It Zero™ reserves the right to suspend, withdraw, modify,
                  add or cancel any or all of the services offered on the App
                  without any prior notice. Any such suspension, withdrawal,
                  modification, addition or cancellation shall become binding on
                  you forthwith.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  User Account Data
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  The Company respects the privacy of every User. We protect the
                  personal information in accordance with our Privacy Policy set
                  out below and respect the confidentiality of the User.
                  <br />
                  <br />
                  The information is kept confidential and is shared only in the
                  manner detailed in the Privacy Policy below. However, you
                  shall also be responsible for maintaining the confidentiality
                  and security of your account, user credentials and you agree
                  to accept the responsibility for all the activities that occur
                  using your account and user credentials. The Company shall not
                  be responsible for any loss or damage due to your failure to
                  safeguard your account.
                  <br />
                  <br />
                  You shall notify the Company by writing to
                  support@doitzero.com of any suspected unauthorized use(s) of
                  your account or any breach of security, including loss, theft,
                  or unauthorized disclosure of your account as soon as the same
                  comes to your notice.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Privacy Policy
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  For our policy on Privacy, kindly refer to our Privacy Policy
                  section in the App.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Information on the App
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  Whilst every effort is made to update the information
                  contained on this App, neither Do It Zero™ nor any third party
                  or data or content provider makes any representations or
                  warranties, whether express, implied in law or residual, as to
                  the sequence, accuracy, completeness or reliability of
                  information, opinions, research information, data and/or
                  content contained on the App (including but not limited to any
                  information which may be provided by any third party or data
                  or content providers) and shall not be bound in any manner by
                  any information contained on the App.
                  <br />
                  <br />
                  The Company reserves the right at any time to change or
                  discontinue without notice, any aspect or feature of this App.
                  No information provided on this App shall be construed as an
                  advice or recommendation.
                  <br />
                  <br />
                  You agree that the information on the App with respect to any
                  product or service that you may purchase is merely indicative
                  of the terms of such product or service. In the event, with
                  respect to a product or service, there is any conflict between
                  the terms set out in the documentation for the product or
                  service and the terms herein, you shall be governed by the
                  terms of the documentation for the product or service.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Intellectual Property
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  The trade marks, names, logos and service marks displayed on
                  this App are registered and/or unregistered trademarks of the
                  Company. Nothing contained on this App should be construed as
                  granting any license or right to use any of the trademarks
                  without the prior written permission of the Company.
                  <br />
                  <br />
                  Copyright, trademark and other intellectual property rights
                  with respect to the contents on this App and look and feel of
                  the App and its User Interface (UI) are solely and exclusively
                  owned by the Company. All rights in the pages, site contents
                  are owned by the Company and the User is prohibited from
                  copying, modifying, displaying, distributing, transmitting,
                  re-delivering, publishing, selling, licensing, creating
                  derivative works or using any content of the App or the look
                  and feel of the App and its UI for any purpose without the
                  prior written consent of the Company.
                  <br />
                  <br />
                  Everything you see or read on the App (including but not
                  limited to directories, guides, news articles, opinions,
                  reviews, text, photographs, images, illustrations, profiles,
                  audio clips, video clips, trademarks, service marks, the look
                  and feel of the App and its UI and the like) is copyrighted/
                  protected by intellectual property laws.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Electronic Communication
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  The users of this App do hereby agree to receive communication
                  from the Company and the App electronically either by e-mails
                  or through announcements on the App.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Card or Bank Account Details
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  You agree, understand and confirm that the bank or credit /
                  debit card details provided by you for buying the products or
                  services offered by Do It Zero™ on the Website shall be
                  correct and accurate and you shall not use the bank account
                  and / or credit / debit card which is not lawfully owned by
                  you. You further agree and undertake to provide the correct
                  and valid bank account and / or credit / debit card details to
                  Do It Zero™.
                  <br />
                  <br />
                  Further, the said information shall not be stored, utilized
                  and shared with any of the third parties by the Company unless
                  required by law, regulation or court order. You shall fully
                  indemnify the Company from any loss or damage arising out of a
                  non-compliance of this condition by you.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Features and Benefits
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  The User does hereby agree that before purchasing a product or
                  service available on this App, the User shall make
                  himself/herself fully aware of its features and benefits
                  offered there under.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Links to Third Party Websites
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This App may contain external links to websites operated by
                  third parties other than Do It Zero™. Such links are provided
                  for your convenience only and Do It Zero™ has no control over
                  such websites and no representation is being made by the
                  Company as to its content and conduct. Use or reliance on any
                  such links and the content thereon provided shall be at your
                  sole risk.
                  <br />
                  <br />
                  When visiting such external links, you shall refer to the
                  terms and conditions of use of such websites. No hypertext
                  links shall be created from any website controlled by You or
                  any other person, to this App, without the express prior
                  written permission of the Company.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Downloads and Software Available on the App
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  Any and all information, or any software (if any) that is made
                  available for download from this App is the copyrighted work
                  of the Company and/or its suppliers. Your usage of such
                  software is governed by the terms of the end User license
                  agreement, if any, which accompanies or is included in such
                  software. You shall not install or use any software that is
                  accompanied by or includes a License Agreement, unless you
                  first agree to the License Agreement terms.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Restrictions on Use
                </Typography>
                <Typography className="fs-14">You shall not:</Typography>
                <ol>
                  <li>
                    <Typography className="fs-14">
                      Reverse engineer, decompile, disassemble, copy, reproduce,
                      distribute, modify, transmit, perform, reproduce, publish
                      or create derivative works from or in any way exploit any
                      of the services or content on this App or look or feel of
                      this App or its UI in violation of the Terms and
                      Conditions or the laws of any country.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Create a database in electronic or structured manual form
                      by systematically downloading and storing all or any part
                      of the App save and except such information as relates to
                      the policy purchased by you.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Cache or permit caching by any person;
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Perform any act that violates our intellectual property in
                      the App as a whole, its UI or other component parts.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Engage in any fraudulent, abusive or illegal activity,
                      including but not limited to any communication or
                      solicitation designed or intended to fraudulently obtain
                      the password or any private information of any use.
                    </Typography>
                  </li>
                  <li>
                    <Typography className="fs-14">
                      Use the App to violate the security of any computer
                      network, crack pass words or security encryption codes,
                      transfer or store illegal material including threatening
                      or obscene material or engage in any kind of illegal
                      activity.
                    </Typography>
                  </li>
                </ol>

                <Typography
                  className="fw-bold"
                  style={{ marginBottom: "8px", marginTop: "1rem" }}
                >
                  Downloads and Software Available on the App
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This App is provided without any warranties or guarantees and
                  on an "As Is" condition. You must bear the risks associated
                  with the use of the App. While Do It Zero™ tries to ensure
                  that material included on the App is correct, it cannot be
                  held liable for any inaccuracy of the same.
                  <br />
                  <br />
                  Do It Zero™ shall not be responsible for any errors or
                  omissions or for the results obtained from the use of such
                  information or for any technical problems you may experience
                  while using the App.
                  <br />
                  <br />
                  To the fullest extent permitted under applicable law, Do It
                  Zero™ shall not be liable for any indirect, incidental,
                  special, incidental, consequential or exemplary damages,
                  including but not limited to, damages for loss of profits,
                  goodwill, use, data or other intangible losses arising out of
                  or in connection with the App, its services or this Agreement.
                  <br />
                  <br />
                  Do It Zero™ makes no representations or warranties about the
                  accuracy, reliability, completeness, correctness and/or
                  timeliness of any content, information, software, text,
                  graphics, link, statics or communications provided on or
                  through the use of the App or that the operation of the App
                  shall be error free and/or uninterrupted. Consequently, Do It
                  Zero™ assumes no liability whatsoever for any monetary or
                  other damage suffered by you on account of the delay, failure,
                  interruption, or corruption of any data or other information
                  transmitted in connection with use of the App and/or any
                  interruption or errors in the operation of the App.
                  <br />
                  <br />
                  This App and its contents, or any transactions enabled by the
                  App, do not constitute the solicitation, offer or sale of any
                  securities.
                  <br />
                  <br />
                  Further in no event shall Do It Zero™ be liable
                  for any direct, indirect, punitive, incidental, special or
                  consequential damages or for any damages whatsoever including,
                  without limitation, damages for loss of use, data or profits,
                  arising out of or in any way connected with the use or
                  performance of Do It Zero™ sites/services for interrupted
                  communications, delay, lost data or lost profits arising out
                  of or in connection with this agreement.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Indemnity
                </Typography>
                <Typography className="fs-14">
                  User agrees to defend, indemnify and hold harmless Do It
                  Zero™, its employees, directors, officers, partners and their
                  successors and assigns from and against any and all claims,
                  liabilities, damages, losses, costs and expenses, including
                  attorney's fees, caused by or arising out of claims based
                  upon:
                </Typography>
                <ol>
                  <li>
                    <Typography className="fs-14">
                      User's actions or inactions, including but not limited to
                      any warranties, representations or undertakings or in
                      relation to the non-fulfilment of any of its obligations
                      under this Agreement or arising out of the User's
                      infringement of any applicable laws.
                    </Typography>
                    <Typography className="fs-14">
                      Payment of statutory dues and taxes;
                    </Typography>
                    <Typography className="fs-14">
                      Claim of libel, defamation, violation of rights of privacy
                      or publicity, loss of service by other subscribers;
                    </Typography>
                    <Typography className="fs-14">
                      Your use or someone else's use of your access to the App;
                    </Typography>
                    <Typography className="fs-14">
                      A claim that any use of the App by you or someone using
                      your computer infringes any intellectual property right of
                      any third party, or any right of personality or publicity,
                      is libelous or defamatory, or otherwise results in injury
                      or damage to any third party; and/or
                    </Typography>
                    <Typography className="fs-14">
                      Infringement of intellectual property rights or other
                      rights of the Company.
                    </Typography>
                  </li>
                </ol>
                <Typography
                  className="fw-bold"
                  style={{ marginBottom: "8px", marginTop: "1rem" }}
                >
                  Liability
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  Any acts by the User in violation of the Terms and Conditions
                  stated herein and/or such other policies issued by Do It Zero™
                  from time to time which adversely affect any rights accruing
                  to Do It Zero™ shall give rise to a cause of action to Do It
                  Zero™, its directors, officers, shareholders and/or its key
                  managerial personnel to institute such proceedings under law
                  as may be appropriate in order to seek redressal, by way of,
                  <i>inter alia</i>, criminal sanctions, compensation and/or damages.
                  <br />
                  <br />
                  Where the User is a company or such other legal person, it is
                  expressly agreed that Do It Zero™ may, in its discretion,
                  institute appropriate proceedings against such person along
                  with its Directors/shareholders, officers, and/or managerial
                  personnel.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Updates
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  We reserve the right to change or update the content herein at
                  any time. Such changes shall be effective immediately upon
                  posting on this App and you shall keep yourself updated of
                  such changes.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Severability
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  If any part of this Agreement is determined to be invalid or
                  unenforceable pursuant to applicable law including, but not
                  limited to, the warranty disclaimers and liability limitations
                  set forth above, then the invalid or unenforceable provision
                  shall be deemed to be superseded by a valid, enforceable
                  provision that most closely matches the intent of the original
                  provision and the remainder of the Agreement shall continue in
                  effect.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Entire Agreement
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  Unless otherwise specified herein, this Agreement constitutes
                  the entire agreement between you and the Company with respect
                  to the Company's sites/services and it supersedes all prior or
                  contemporaneous communications and proposals, whether
                  electronic, oral or written, between you and the Company with
                  respect to the Company's sites/services. The Company's failure
                  to act with respect to a breach by you or others does not
                  waive its right to act with respect to subsequent or similar
                  breaches.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Governing Law and Dispute Resolution
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  This Agreement shall be governed by the laws of India and any
                  disputes arising here from shall be subject to the sole and
                  exclusive jurisdiction of the courts at Hyderabad, Telangana,
                  India.
                </Typography>

                <Typography className="fw-bold" style={{ marginBottom: "8px" }}>
                  Assistance
                </Typography>
                <Typography className="c-sp-16 fs-14">
                  For any concerns or questions about any aspect of these Terms
                  & Conditions of use of the App, please feel free to contact us
                  at support@doitzero.com
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
