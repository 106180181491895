import React from 'react'
import { detailArr } from '../utils/commonUtils'
import { Stack, Typography } from '@mui/material'
import { colors } from '../utils/colors'

export default function DetailCard() {
    return (
        detailArr.map((item, index) => (
            <Stack key={index} direction='row' alignItems='center'>
                <div style={{ width: '9%' }}>
                    <img alt={item.title} src={item.logo} />
                </div>
                <Stack className='details'>
                    <Typography className='ff-Roboto fw-medium fs-14' style={{ color: colors.black }}>
                        {item.title}
                    </Typography>
                    <Typography className='ff-Roboto fw-regular fs-12' style={{ color: colors.blackSecondary, width: '216px' }}>
                        {item.subTitle}
                    </Typography>
                </Stack>
            </Stack>
        ))
    )
}
