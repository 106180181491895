import './CampaignSection.scss'
import React from 'react';
import EnchanedTableHeader from '../../../../../components/EnchanedTableHeader';
import { Typography, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import millify from 'millify';

const headerCell = [
    {
        name: 'Campaign Name',
        width: '15%'
    },
    {
        name: 'Total Participants',
        width: '11.875%'
    },
    {
        name: 'Total Actions',
        width: '10.875%'
    },
    {
        name: 'Eco-points Awarded',
        width: '13.125%'
    },
    {
        name: 'Average Actions',
        width: '10.625%'
    },
    {
        name: 'Average Eco-Points',
        width: '10.625%',
    },
    {
        name: 'Result',
        width: '10.875%'
    },
    {
        name: 'Carbon impact',
        width: '13.25%'
    }
]

export default function CampaignTable({ length, page, rowsPerPage, allSelected, handleChangeAll, handleCheckedChange, campaignList }) {

    return (
        <div className='CampaignTable'>
            <div className='tableContainer'>
                <TableContainer>
                    <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                        <EnchanedTableHeader
                            headerCell={headerCell}
                            handleChangeAll={((event, value) => handleChangeAll(event, value))}
                            allSelected={allSelected}
                            checkbox={false}
                            preference={false}
                            infoIconLast={true}
                        />
                        <TableBody className='mainUserBodyTable'>
                            {
                                campaignList?.map((item, index) => (
                                    <TableRow className='tableRowHover' key={index}>
                                        <TableCell align='center' style={{ width: 'calc(100% - 96.25%)' }} className={item?.isSelected ? 'selectedStyle tablecellonHover' : 'tablecellonHover'}>
                                            {/* <Checkbox checked={item.isSelected}
                                                onChange={() => handleCheckedChange(item)} className={item?.isSelected ? 'checkedItems' : 'checkboxonHover'} /> */}
                                            <Typography className={(item?.isSelected ? 'indexChecked ' : 'indexStyle ') + 'ff-Roboto fs-12 fw-regular black'}>
                                                {(rowsPerPage * (page)) + index + 1}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.title}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.totalParticipants}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.totalActions}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {millify(item?.ecoPointsAwarded)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.averageAction}/user
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.averageEcoPoints}/user
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.result || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {item?.carbonImpact || '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
