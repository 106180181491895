import './MobileModeSetting.scss';
import { Stack, Typography } from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';

import UserProfile from '../UserProfile/UserProfile';
import ChangePassword from '../ChangePassword/ChangePassword';
import Organization from '../Organization/Organization';
import Contact from '../Contact/Contact';
import mobilewavybg from '../../../../../assets/images/mobilewavybg.svg';

const menus = [
    {lable: 'User Profile', icon: <PersonIcon sx={{transform: 'scale(0.8)'}} />},
    {lable: 'Change Password', icon: <LockIcon sx={{transform: 'scale(0.8)'}} />},
    {lable: 'Organization', icon: <BusinessIcon sx={{transform: 'scale(0.8)'}} />},
    {lable: 'Contact Us', icon: <PhoneIcon sx={{transform: 'scale(0.8)'}} />},
];


export default function MobileModeSetting({activeMenuIndex = 0, onMenuChange}) {

    const handleMenuChange = (index) => {
        if(index !== activeMenuIndex) {
            onMenuChange(index);
        }
    }

    return (
        <>
            <Stack className='mobileModeSettingConatiner' style={{ backgroundImage: `url(${mobilewavybg})`, backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat'}}>
               {activeMenuIndex === 0 && <UserProfile />}
               {activeMenuIndex === 1 && <ChangePassword />}
               {activeMenuIndex === 2 && <Organization />}
               {activeMenuIndex === 3 && <Contact />}
            </Stack>
            <Stack className="mobileModeSettingFooter" direction="row" alignItems="center" justifyContent="space-between">
                {menus.map((item, index) => <Stack onClick={() => handleMenuChange(index)} direction="row" alignItems="center" className={index === activeMenuIndex ? "activeFooterMenu" : ""}>
                    {item.icon}
                    {index === activeMenuIndex && <Typography sx={{ml: '8px'}} className="fs-12 ff-Roboto">{item.lable}</Typography>}
                </Stack>)}
            </Stack>
        </>
    );
}