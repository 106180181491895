import './UserManagement.scss'
import React, { useState, useEffect } from 'react'
import {
    Typography, Stack, IconButton, Button, TextField,
    InputAdornment, Table, TableBody, TableRow, TableCell, Checkbox,
    TableContainer, Tooltip, useMediaQuery, Box, TablePagination
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { buttonStyle3 } from '../../../utils/commonStyle';
import appapi from '../../../api/apiClient';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';
import ViewUserDialog from './components/ViewUserDialog';
import EditUserDialog from './components/EditUserDialog';
import DeleteUserDialog from './components/DeleteUserDialog';
import AddUserDialog from './components/AddUserDialog';
import { activateStatusType } from '../../../utils/commonUtils';
import { showmessage } from '../../../utils/toastr';
import UserCard from './components/UserCard';
import longPressEvents from '../../../components/LongPress/useLongPress';
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { setLoading } from '../../../redux/features/appSlice';
import { colors } from '../../../utils/colors';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { CancelToken } from 'apisauce'
import EnchanedTableHeader from '../../../components/EnchanedTableHeader';
import ChangeStatusDialog from './components/ChangeStatusDialog';
import { RenderIf } from '../../../utils/customHooks';

const headerCell = [
    {
        name: 'Email',
        width: 'calc(100% - 85%)'
    },
    {
        name: 'Role',
        width: 'calc(100% - 88.125%)'
    },
    {
        name: 'Name',
        width: 'calc(100% - 84.375%)'
    },
    {
        name: 'Designation',
        width: 'calc(100% - 86.25%)'
    },
    {
        name: 'Department',
        width: 'calc(100% - 88.671875%)'
    },
    {
        name: 'Location',
        width: 'calc(100% - 91.328125%)'
    },
    {
        name: 'Added',
        width: 'calc(100% - 90.703125%)'
    },
    {
        name: 'Status',
        width: 'calc(100% - 89.296875%)'
    },
]

export default function UserManagement() {
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(0);
    const [backendpage, setBackendPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allSelected, setAllSelected] = useState(false);
    const [enterpriseUser, setEnterpriseUser] = useState(null);
    const [openViewUser, setOpenViewUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [openDeleteUser, setDeleteUser] = useState(false);
    const [openAddUser, setAddUser] = useState(false);
    const [openDeactivate, setDeactivate] = useState(false);
    const [userDetail, setUserDetail] = useState(null);
    const [enableStatusOverAll, SetEnableStatusOverAll] = useState(false);
    const [enableDeleteOverAll, SetEnableDeleteOverAll] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deleteChecker, setDeleteChecker] = useState(false);
    const [fromEditChecker, setFromEditChecker] = useState(false);
    const [checkLongPress, setCheckLongPress] = useState(false);
    const [length, setLength] = useState(null);
    const [userPermission, setUserPermission] = useState(null)

    const isMobile = useMediaQuery('(max-width:1000px)');
    const source = CancelToken.source()

    /**
     * retrieving user From Redux Store
     */
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch()

    /**
     * handle user is selected or not
     * @param {*} item
     * user Item 
     */
    // const handleCheckedChange = (item) => {
    //     let arr = [...enterpriseUser];
    //     let findIndex = arr.findIndex((row) => row.emailId === item.emailId);
    //     arr[findIndex].isSelected = !item.isSelected;
    //     setEnterpriseUser([...arr])
    //     let isCheckedArr = arr.filter((row) => row.isSelected);
    //     if (isCheckedArr.length > 0) {
    //         let checkerInvite = isCheckedArr.every((row) => row.activateStatus === activateStatusType.invite);
    //         if (checkerInvite) {
    //             SetEnableStatusOverAll(false)
    //         } else {
    //             SetEnableStatusOverAll(true)
    //         }
    //         SetEnableDeleteOverAll(true)
    //     } else {
    //         SetEnableStatusOverAll(false)
    //         SetEnableDeleteOverAll(false)
    //     }

    //     // isCheckedArr.length > 0 ? setEnableOverAllBtn(false) : setEnableOverAllBtn(true)
    //     setSelectedUser([...isCheckedArr])
    // }

    /**
     * Handle All user Select
     */
    // const handleChangeAll = (event, value) => {
    //     let arr = [...enterpriseUser];
    //     arr.map((row) => {
    //         if (allSelected !== true) {
    //             row.isSelected = true;
    //         } else {
    //             row.isSelected = false;
    //         }
    //     })
    //     setEnterpriseUser([...arr])
    //     let isCheckedArr = arr.filter((row) => row.isSelected);
    //     if (isCheckedArr.length > 0) {
    //         let checkerInvite = isCheckedArr.every((row) => row.activateStatus === activateStatusType.invite);
    //         if (checkerInvite) {
    //             SetEnableStatusOverAll(false)
    //         } else {
    //             SetEnableStatusOverAll(true)
    //         }
    //         SetEnableDeleteOverAll(true)
    //     } else {
    //         SetEnableStatusOverAll(false)
    //         SetEnableDeleteOverAll(false)
    //     }
    //     setSelectedUser([...isCheckedArr])
    //     setAllSelected(!allSelected)
    // }

    /**
     * handle Search
     * @param {value} event 
     */
    const handleSearch = (event) => {
        let filterValue = event.target.value;
        setSearch(filterValue ? filterValue : null)
        source.cancel()
    }

    /**
     * Dialog Open
     * @param {user Details} row 
     */
    const handleDialog = (row, type, fromOverAll = null, value = null) => {
        setUserDetail(row)
        if (type === 'view') {
            setOpenViewUser(true)
        } else if (type === 'edit') {
            setOpenEditUser(true)
        } else if (type === 'delete') {
            if (value === 'fromEdit') {
                setFromEditChecker(true)
            }
            setDeleteChecker(fromOverAll)
            setDeleteUser(true)
        } else if (type === 'add') {
            setAddUser(true)
        } else if (type === 'changeStatue') {
            setDeleteChecker(fromOverAll)
            setDeactivate(true)
        }
    }

    /**
     * Dialog Close
     * @param {*} type 
     */
    const handleDialogClose = (event, type, optionalEdit = null) => {
        if (type === 'delete') {
            if (event !== true) {
                if (enterpriseUser.length === 1) {
                    setPage(page === 0 ? 0 : page - 1)
                    setBackendPage(backendpage === 1 ? 1 : backendpage - 1)
                    if (page === 0 && backendpage === 1) {
                        handleGetEnterPriceUser()
                    }
                } else if (selectedUser?.length === enterpriseUser?.length) {
                    if (page === 0 && backendpage === 1) {
                        handleGetEnterPriceUser()
                    } else {
                        setPage(page === 0 ? 0 : page - 1)
                        setBackendPage(backendpage === 1 ? 1 : backendpage - 1)
                    }
                } else {
                    handleGetEnterPriceUser()
                }
            }

        }
        if (!event && fromEditChecker) {
            setOpenEditUser(false)
            setOpenViewUser(false)
            setFromEditChecker(false)
            setCheckLongPress(false)
            SetEnableDeleteOverAll(false)
            SetEnableStatusOverAll(false)
        }
        if (type === 'view') {
            setOpenViewUser(false)
        } else if (type === 'edit') {
            if (event !== true) {
                if (optionalEdit === 'editAnother') {
                    handleGetEnterPriceUser()
                }
                setSelectedUser([])
                SetEnableDeleteOverAll(false)
                SetEnableStatusOverAll(false)
                setCheckLongPress(false)
            }
            setOpenEditUser(false)
        } else if (type === 'delete') {
            if (event !== true) {
                setSelectedUser([])
                SetEnableDeleteOverAll(false)
                SetEnableStatusOverAll(false)
                setCheckLongPress(false)
            }
            setDeleteUser(false)
            setAllSelected(false)
        } else if (type === 'add') {
            if (event !== true) {
                handleGetEnterPriceUser()
                setSelectedUser([])
                SetEnableDeleteOverAll(false)
                SetEnableStatusOverAll(false)
                setCheckLongPress(false)
            }
            setAddUser(false)
        } else if (type === 'statusChange') {
            if (event !== true) {
                setSelectedUser([])
                SetEnableDeleteOverAll(false)
                SetEnableStatusOverAll(false)
                setCheckLongPress(false)
            }
            setDeactivate(false)
        }
    }

    /**
     * retrieving EnterpriseUser List
     */
    const handleGetEnterPriceUser = async (fromSearch = true) => {
        dispatch(setLoading(fromSearch));
        const response = await appapi.get(`/user/${user.enterpriseId}`, {
            page: fromSearch === false ? 1 : backendpage,
            filter: search ? search : null,
            limit: rowsPerPage,
        }, { cancelToken: source.token })
        if (response.ok) {
            const data = response.data;
            if (data.status === 'success') {
                let userData = data?.data?.users
                setLength(data?.data?.length)
                userData.map((item, index) => {
                    item.Sno = (rowsPerPage * (page)) + index + 1
                    item.isSelected = false
                    item.originalStatus = item.activateStatus === activateStatusType.active ? 'Active' : item.activateStatus === activateStatusType.deActivated ? 'Deactivated' : 'Invited'
                    item.activateAccess = null
                    item.deleteAccess = null
                    item.editAccess = null
                    item.viewAccess = null
                });
                // setEnterpriseUser(userData)
                if (userDetail) {
                    let find = userData?.filter((item) => item.enterpriseUserId === userDetail.enterpriseUserId)
                    setUserDetail(find[0]);
                }

                // permission Task
                let find = user?.permission?.find((item) => item?.moduleName === 'user_management');
                setUserPermission(find)
                if (find) {
                    let menuarr = [...userData]
                    menuarr.map((item) => {
                        if (find.activateAccess === '1') {
                            item.activateAccess = true
                        } else if (find.activateAccess === '0') {
                            item.activateAccess = false
                        } else {
                            item.activateAccess = true
                        }
                        if (find.editAccess === '1') {
                            item.editAccess = true
                        } else if (find.editAccess === '0') {
                            item.editAccess = false
                        } else {
                            item.editAccess = true
                        }
                        if (find.viewAccess === '1') {
                            item.viewAccess = true
                        } else if (find.viewAccess === '0') {
                            item.viewAccess = false
                        } else {
                            item.viewAccess = true
                        }
                        if (find.deleteAccess === '1') {
                            item.deleteAccess = true
                        } else if (find.deleteAccess === '0') {
                            item.deleteAccess = false
                        } else {
                            item.deleteAccess = true
                        }
                        if (find.activateAccess === '2' && item.roleId === 1) {
                            item.activateAccess = false
                        }
                        if (find.editAccess === '2' && item.roleId === 1) {
                            item.editAccess = false
                        }
                        if (find.viewAccess === '2' && item.roleId === 1) {
                            item.viewAccess = false
                        }
                        if (find.deleteAccess === '2' && item.roleId === 1) {
                            item.deleteAccess = false
                        }
                    })
                    setEnterpriseUser(menuarr)
                }
            } else {
                setEnterpriseUser([])
                setLength(1);
                setPage(0);
                setBackendPage(1)
            }
            dispatch(setLoading(false))
        } else {
            // setEnterpriseUser([])
            setLength(1);
            setPage(0);
            setBackendPage(1)
            dispatch(setLoading(false))
            if (fromSearch === true) {
                // showmessage(`Something Went Wrong!`, 2000, 'error')
            }
        }
    }

    /**
     * Fetch User Data
     */
    useEffect(() => {
        let timer;
        if (search) {
            timer = setTimeout(() => {
                setPage(0);
                setBackendPage(1)
                handleGetEnterPriceUser(search ? false : true)
            }, 150)
        } else {
            handleGetEnterPriceUser()
        }
        setAllSelected(false)
        return () => clearTimeout(timer)
    }, [page, rowsPerPage, search, user]);

    /**
     * Changes the status of the user
     * @param {activateStatus} row 
     */
    const handleChangeStatus = async (row) => {
        dispatch(setLoading(true));
        let params = {
            params: {
                enterpriseId: user.enterpriseId
            }
        }
        let payload = [row.enterpriseUserId]
        let status = row.activateStatus === 'activate' ? 'deactivate' : 'activate'
        const response = await appapi.post(`/user/${user.enterpriseId}/${status}`, payload, params)
        if (response.ok) {
            const data = response.data
            if (data.status === 'success') {
                // showmessage(`Status Changed Successfully!!`, 2000)
                setDeactivate(false);
                dispatch(setLoading(false));
                handleGetEnterPriceUser().then(() => {
                    row.activateStatus = row.activateStatus === 'activate' ? 'deactivate' : 'activate'
                    setUserDetail(row)
                })
            } else {
                dispatch(setLoading(false));
                showmessage(data.message, 'error')
            }
        } else {
            dispatch(setLoading(false));
            // showmessage(`Something Went Wrong!`, 2000, 'error')
        }
    }

    /**
     * Change Status Overall Users
     * This is commented since we are not supporting multiselect
     */
    // const onHandleOverAllStatus = async () => {
    //     dispatch(setLoading(true));
    //     let params = {
    //         params: {
    //             enterpriseId: user.enterpriseId
    //         }
    //     }
    //     let payload = []
    //     let selecterUserList = selectedUser.filter((item) => item.activateStatus !== 'invite')
    //     let checker = selecterUserList?.every((item) => item.activateStatus === 'deactivate');
    //     if (checker) {
    //         selecterUserList?.forEach((item) => {
    //             if (item.activateStatus === 'deactivate') {
    //                 payload.push(item.enterpriseUserId)
    //             }
    //         })
    //     } else {
    //         selecterUserList?.forEach((item) => {
    //             if (item.activateStatus === 'activate') {
    //                 payload.push(item.enterpriseUserId)
    //             }
    //         })
    //     }
    //     let status = checker ? 'activate' : 'deactivate'
    //     const response = await appapi.post(`/user/${user.enterpriseId}/${status}`, payload, params)
    //     if (response.ok) {
    //         const data = response.data;
    //         if (data.status === 'success') {
    //             setDeactivate(false);
    //             dispatch(setLoading(false));
    //             handleGetEnterPriceUser()
    //             setCheckLongPress(false)
    //             // showmessage(`Status Changed Successfully!!`, 2000)
    //             setAllSelected(false)
    //             setSelectedUser([])
    //             // setEnableOverAllBtn(true)
    //             SetEnableDeleteOverAll(false)
    //             SetEnableStatusOverAll(false)
    //         } else {
    //             dispatch(setLoading(false));
    //             showmessage(data.message, 2000, 'error')
    //         }
    //     } else {
    //         dispatch(setLoading(false));
    //         // showmessage(`Something Went Wrong!`, 2000, 'error')
    //     }
    // }

    /**
     * check longPress for mobile view UserCard
     */
    // const onLongPress = (item) => {
    //     setCheckLongPress(true)
    //     setSelectedUser([])
    //     item.isSelected = true
    //     let userList = [...enterpriseUser]
    //     userList.forEach((row) => {
    //         if (item.enterpriseUserId === row.enterpriseUserId) {
    //             row.isSelected = item.isSelected
    //         }
    //     })
    //     setEnterpriseUser(userList)
    //     let selectedUserList = userList.filter((user) => user.isSelected === true)
    //     if (selectedUserList.length > 0) {
    //         let checkerInvite = selectedUserList.every((row) => row.activateStatus === activateStatusType.invite);
    //         if (checkerInvite) {
    //             SetEnableStatusOverAll(false)
    //         } else {
    //             SetEnableStatusOverAll(true)
    //         }
    //         SetEnableDeleteOverAll(true)
    //     } else {
    //         SetEnableStatusOverAll(false)
    //         SetEnableDeleteOverAll(false)
    //     }
    //     setSelectedUser(selectedUserList)
    // };

    // const onSingleClick = (item) => {
    //     item.isSelected = item.isSelected === true ? false : true
    //     let userList = [...enterpriseUser]
    //     userList.forEach((row) => {
    //         if (item.enterpriseUserId === row.enterpriseUserId) {
    //             row.isSelected = item.isSelected
    //         }
    //     })
    //     setEnterpriseUser(userList)
    //     let selectedUserList = userList.filter((user) => user.isSelected === true)
    //     if (selectedUserList.length > 0) {
    //         let checkerInvite = selectedUserList.every((row) => row.activateStatus === activateStatusType.invite);
    //         if (checkerInvite) {
    //             SetEnableStatusOverAll(false)
    //         } else {
    //             SetEnableStatusOverAll(true)
    //         }
    //         SetEnableDeleteOverAll(true)
    //     } else {
    //         SetEnableStatusOverAll(false)
    //         SetEnableDeleteOverAll(false)
    //     }
    //     setSelectedUser(selectedUserList)
    //     if (selectedUserList.length <= 0) {
    //         setCheckLongPress(false)
    //     }
    // }

    // const handleClearAll = () => {
    //     let userList = [...enterpriseUser]
    //     userList.forEach((row) => {
    //         row.isSelected = false
    //     })
    //     setSelectedUser([])
    //     setCheckLongPress(false)
    // }

    /**
     * 
     * @function handleChangePage
     * @function handleChangeRowsPerPage
     * handle Pagination 
     */
    const handleChangePage = (event, newPage) => {
        setBackendPage(newPage + 1)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setBackendPage(1)
        setPage(0);
    };

    return (
        <div className='userManagementContainer inputFormStyle innerPadding'>
            {/**
             * Top Toolbar
             */}
            <header className={isMobile ? 'headerMobile' : undefined} style={{ marginBottom: '8px' }}>
                <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                    <Typography className='ff-Roboto fw-medium text-upper' style={{ color: colors.blackMain }}>
                        User Management
                    </Typography>
                    <div className='flex-aligncenter'>
                        <TextField
                            id='search'
                            name='search'
                            value={search || ''}
                            onChange={handleSearch}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment disablePointerEvents position='end'>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <RenderIf isTrue={userPermission?.editAccess === '1' || userPermission?.editAccess === '2'}>
                            <Button className='mobileviewStyle' sx={buttonStyle3} onClick={() => handleDialog({}, 'add')} variant="contained" endIcon={<AddIcon />}>
                                ADD
                            </Button>
                        </RenderIf>

                        {/* <IconButton className='mobileviewStyle' onClick={() => handleDialog(null, 'changeStatue', true)} disabled={!enableStatusOverAll}>
                            <PersonIcon style={{ color: !enableStatusOverAll ? '#9AA694' : colors.primaryTxt }} />
                        </IconButton>
                        <IconButton className='mobileviewStyle' onClick={() => handleDialog(null, 'delete', true)} disabled={!enableDeleteOverAll}>
                            <DeleteIcon style={{ color: !enableDeleteOverAll ? '#9AA694' : colors.primaryTxt }} />
                        </IconButton> */}
                        {
                            isMobile ? null :
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={length ? length : 0}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 10}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                        }

                    </div>
                </Stack>
            </header>
            {/**
             * Table
             */}
            {
                isMobile ?
                    <div style={{ marginBottom: '70px' }}>
                        {
                            enterpriseUser && enterpriseUser.map((item, index) => (

                                <div style={{ background: item.isSelected ? '#F8FBF5' : '#FFF' }} key={index} >
                                    {/**
                                     * This is remove from top line since we are not supporting multiselect
                                     * onClick={() => checkLongPress ? onSingleClick(user) : ''} {...longPressEvents(() => checkLongPress ? null : onLongPress(user))}
                                     */}
                                    <UserCard
                                        user={item}
                                        handleViewDetial={(event) => handleDialog(event, 'view')}
                                        handleStatuChangeUser={(event) => handleDialog(event, 'changeStatue')}
                                        handleDelete={(event) => handleDialog(event, 'delete', false)}
                                        selectedUser={selectedUser}
                                        enterpriseUser={user}
                                    />
                                </div>
                            ))
                        }
                        {enterpriseUser?.length <= 0 ?
                            /**
                             * No Data Found
                             */
                            <div className='noData'>
                                No Data Found
                            </div> : ''
                        }
                        {
                            //This is commented since we are not supporting multiselect
                            // checkLongPress
                            //     ?
                            //     <Box className='stackBoxBottom' >
                            //         <Stack className='innerBoxBottom' direction='row'>
                            //             <IconButton className='innerButton' onClick={() => handleClearAll()}>
                            //                 <ClearAllRoundedIcon style={{ color: '#E9FCD3' }} />
                            //             </IconButton>
                            //             <IconButton disabled={!enableStatusOverAll} className='innerButton' onClick={() => handleDialog(null, 'changeStatue', true)}>
                            //                 <PersonIcon style={{ color: !enableStatusOverAll ? '#9AA694' : '#E9FCD3' }} />
                            //             </IconButton>
                            //             <IconButton disabled={!enableDeleteOverAll} className='innerButton' onClick={() => handleDialog(null, 'delete', true)}>
                            //                 <DeleteIcon style={{ color: !enableDeleteOverAll ? '#9AA694' : '#E9FCD3' }} />
                            //             </IconButton>
                            //         </Stack>
                            //     </Box>
                            //     :
                            <RenderIf isTrue={userPermission?.editAccess === '1' || userPermission?.editAccess === '2'}>
                                <IconButton onClick={() => handleDialog({}, 'add')} className='mobileAddStyle'>
                                    <AddIcon style={{ color: '#FFF' }} />
                                </IconButton>
                            </RenderIf>
                        }
                        {
                            enterpriseUser?.length > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={length ? length : 0}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 5}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </div>
                        }

                    </div>
                    :
                    <div className='tableContainer'>
                        <TableContainer sx={{ maxHeight: 'calc(100vh - 128px)' }}>
                            <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                                <EnchanedTableHeader
                                    headerCell={headerCell}
                                    // handleChangeAll={((event, value) => handleChangeAll(event, value))}
                                    checkbox={false}
                                />
                                <TableBody className='mainUserBodyTable'>
                                    {
                                        enterpriseUser && enterpriseUser.map((item, index) => (
                                            <TableRow className='tableRowHover' key={index}>
                                                <TableCell align='center' style={{ width: 'calc(100% - 96.25%)' }} className={item?.isSelected ? 'selectedStyle tablecellonHover' : 'tablecellonHover'}>
                                                    {/* <Checkbox checked={item.isSelected}
                                                        onChange={() => handleCheckedChange(item)} className={item?.isSelected ? 'checkedItems' : 'checkboxonHover'} /> */}
                                                    <Typography className={(item?.isSelected ? 'indexChecked ' : 'indexStyle ') + 'ff-Roboto fs-12 fw-regular black'}>
                                                        {item.Sno}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 85%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                        {item.emailId}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 88.125%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                        {item.roleName || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 84.375%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                        {item.name || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 86.25%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                        {item.designation || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 88.671875%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                        {item.department || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 91.328125%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                        {item.cityName || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 90.703125%)' }}>
                                                    <Typography className='fs-12 ff-Roboto fw-regular black'>
                                                        {Moment(item.createdAt).format('DD MMM, yyyy')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ width: 'calc(100% - 89.296875%)', position: 'relative' }}>
                                                    <Stack direction='row' alignItems='center'>
                                                        <div className='statusCircle'
                                                            style={{
                                                                background: (item.activateStatus === activateStatusType.invite && colors.invited) ||
                                                                    (item.activateStatus === activateStatusType.active && colors.active) ||
                                                                    (item.activateStatus === activateStatusType.deActivated && colors.deactive)
                                                            }}
                                                        >
                                                        </div>
                                                        <Typography className='fs-12 ff-Roboto fw-regular'
                                                            style={{
                                                                marginLeft: '10px',
                                                                textTransform: 'capitalize',
                                                                color: (item.activateStatus === activateStatusType.invite && colors.invited) ||
                                                                    (item.activateStatus === activateStatusType.active && colors.active) ||
                                                                    (item.activateStatus === activateStatusType.deActivated && colors.deactive)
                                                            }}
                                                        >
                                                            {item?.originalStatus}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack className='showOnHoverAction' direction='row'>
                                                        <RenderIf isTrue={item.viewAccess}>
                                                            <Tooltip disableInteractive title='View User'>
                                                                <IconButton onClick={() => handleDialog(item, 'view')} className='hoverIconButton'>
                                                                    <VisibilityOutlinedIcon style={{ color: colors.primaryTxt, transform: 'scale(0.7275)' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </RenderIf>
                                                        <RenderIf isTrue={item.editAccess}>
                                                            <Tooltip disableInteractive title='Edit User'>
                                                                <IconButton onClick={() => handleDialog(item, 'edit')} className='hoverIconButton'>
                                                                    <EditIcon style={{ color: colors.primaryTxt, transform: 'scale(0.889)' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </RenderIf>
                                                        <RenderIf isTrue={item.activateAccess}>
                                                            {
                                                                (user?.enterpriseUserId === item?.enterpriseUserId && user?.roleId === 1) || item.activateStatus === activateStatusType.invite ? '' :
                                                                    <Tooltip disableInteractive title={item.activateStatus === activateStatusType.active ?
                                                                        'Deactivate' :
                                                                        'Activate'}>
                                                                        <IconButton onClick={() => handleDialog(item, 'changeStatue')} className='hoverIconButton'>
                                                                            {

                                                                                item.activateStatus === activateStatusType.active ?
                                                                                    <PersonOffIcon style={{ color: colors.primaryTxt }} /> :
                                                                                    <PersonIcon style={{ color: colors.primaryTxt }} />
                                                                            }
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            }
                                                        </RenderIf>
                                                        <RenderIf isTrue={item.deleteAccess}>
                                                            <RenderIf isTrue={!(user?.enterpriseUserId === item?.enterpriseUserId && user?.roleId === 1)}>
                                                                <Tooltip disableInteractive title='Delete User'>
                                                                    <IconButton onClick={() => handleDialog(item, 'delete', false)} className='hoverIconButton'>
                                                                        <DeleteIcon style={{ color: colors.primaryTxt, transform: 'scale(0.8888)' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </RenderIf>
                                                        </RenderIf>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {enterpriseUser?.length <= 0 ?
                            /**
                             * No Data Found
                             */
                            <div className='noData'>
                                No Data Found
                            </div> : ''
                        }
                    </div>
            }
            <AddUserDialog userPermission={userPermission} open={openAddUser} enterpriseUser={user} onHandleAddClose={(event) => handleDialogClose(event, 'add')} />
            <ViewUserDialog open={openViewUser} user={userDetail} onHandleEditDialog={(row) => handleDialog(row, 'edit')} onHandleUserClose={() => handleDialogClose(true, 'view')} />
            <EditUserDialog userPermission={userPermission} handleChangeStatus={(row) => handleChangeStatus(row)} open={openEditUser} enterpriseUser={user} user={userDetail} onHandleEditClose={(event, editClose) => handleDialogClose(event, 'edit', editClose)} onHandleDeleteUser={(row, value) => handleDialog(row, 'delete', false, value)} />
            <DeleteUserDialog deleteChecker={deleteChecker} selectedUser={selectedUser} enterpriseUser={user} open={openDeleteUser} user={userDetail} onHandleDeleteClose={(event) => handleDialogClose(event, 'delete')} />
            {/* onHandleOverAllStatus={onHandleOverAllStatus}   this comes in changestatus dialog since no multiselect we removed this*/}
            <ChangeStatusDialog deleteChecker={deleteChecker} selectedUser={selectedUser} open={openDeactivate} user={userDetail} onHandleStatusClose={(event) => handleDialogClose(event, 'statusChange')} handleChangeStatus={() => handleChangeStatus(userDetail)} />
        </div>
    )
}