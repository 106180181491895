import React from "react";
import {
  Stack,
  Typography,
  TextField,
  useMediaQuery,
  Button,
  InputAdornment,
  IconButton,
  Switch,
  styled,
  MenuItem,
  Checkbox,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { FieldArray, useFormikContext } from "formik";
import { colors } from "../../../../../../utils/colors";
import { buttonStyle3 } from "../../../../../../utils/commonStyle";
import FormTextField from "../../../../../../components/FormTextField";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

export default function EcoCampaignSetting() {

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#8AC865",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#8C8C8C",
      boxSizing: "border-box",
    },
  }));

  const { citiesList } = useSelector((state) => state.app);
  const cities = citiesList?.slice(1, citiesList.length);


  const isMobile = useMediaQuery("(max-width:1000px)");

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormikContext();

  const handleChangeEcoRedemptionType = (evt) => {
    setFieldValue(
      "ecoRedemptionType",
      values?.ecoRedemptionType === "onetime" ? "peritem" : "onetime"
    );
  };

  const handleChangeSelect = (event) => {
    const {value, options} = event.target;
    setFieldValue('cityId',  value);
  };


  return (
    <Stack>
      <Typography
        className="fs-14 ff-Roboto fw-medium mb-7"
        color={colors.blackMain}
      >
        Schedule
      </Typography>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="flex-start"
        className="datepickerSetting"

      >
        <LocalizationProvider dateAdapter={AdapterMoment} >
          <DatePicker
            label="Start Date*"
            id="startDate"
            name="startDate"
            value={values.startDate}
            onChange={(newValue) => {
              setFieldValue("startDate", newValue.toDate());
            }}
            PopperProps={{
              placement: "bottom-start",
            }}
            disablePast={true}
            components={{
              OpenPickerIcon: ArrowDropDownIcon,
            }}

            renderInput={(params) => (
              <TextField
                style={{ width: isMobile ? '100%' : "240px" }}
                // error={touched.startDate && Boolean(errors.startDate)}
                // helperText={
                //   (touched.startDate || dirty.startDate) && errors.startDate
                // }
                {...params}
              />
            )}
          />
        </LocalizationProvider >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            className={isMobile ? "mt-14" : ""}
            label="End Date*"
            id="endDate"
            name="endDate"
            value={values.endDate}
            onChange={(newValue) => {
              setFieldValue("endDate", newValue.toDate());
            }}
            PopperProps={{
              placement: "bottom-end",
            }}
            minDate={moment(values.startDate).add(1, "d")}
            disabled={values.startDate == null ? true : false}
            components={{
              OpenPickerIcon: ArrowDropDownIcon,
            }}
            renderInput={(params) => (
              <TextField

                fullWidth
                style={{ width: isMobile ? '100%' : "240px" }}
                // error={touched.endDate && Boolean(errors.endDate)}
                // helperText={
                //   (touched.endDate || dirty.endDate) && errors.endDate
                // }
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      { values.citySelection === 'specific' ? <Stack className="mt-16">
        <Typography
          className="fs-14 ff-Roboto fw-medium mb-7"
          color={colors.blackMain}
        >
          Select City*
        </Typography>
        {/* <TextField
          fullWidth
          id="cityId"
          name="cityId"
          value={values.cityId || ""}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          // error={touched.cityId && Boolean(errors.cityId)}
          // helperText={(touched.cityId || dirty.cityId) && errors.cityId}
          select
        >
          {citiesList.map((item) => (
            <MenuItem key={item.cityId} value={item.cityId}>
              {item.cityName}
            </MenuItem>
          ))}
        </TextField> */}
        <FormControl sx={{  minWidth: 120 }}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={values['cityId'] || []}
            onChange={handleChangeSelect}
            input={<OutlinedInput />}
            renderValue={(selected) => cities.filter(res => values.cityId.includes(res.cityId)).map(res => res.cityName).join(', ')}
            MenuProps={MenuProps}
          >
            {cities.map((city) => (
              <MenuItem key={city.cityId} value={city.cityId}>
                <Checkbox checked={values.cityId.includes(city.cityId)} />
                <ListItemText primary={city.cityName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </Stack> : <Stack className="mt-14">
              <Typography style={{color: '#496073'}} className="fs-14 ff-Roboto ff-regular">Applicable in all cities</Typography>
      </Stack>}
      <Stack className="mt-14">

        <FormTextField inputProps={{ maxLength: 5 }} disabled={values?.ecoPointsType === 'fixed'} label="Eco Points*" name="ecoPoints" sx={{ mb: 0 }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="mt-6"
        >
          <Stack direction="row" alignItems="center">
            {values?.ecoPointsRedemption === "flexible" && (
              <AntSwitch
                onChange={handleChangeEcoRedemptionType}
                checked={values?.ecoRedemptionType === "onetime"}
                inputProps={{ "aria-label": "Switch ecoRedemptionType" }}
                sx={{ mr: "12px" }}
              />
            )}

            <Typography
              className="fs-12 ff-Roboto fw-medium"
              color={colors.blackMain}
            >
              {values?.ecoRedemptionType === "peritem"
                ? "One Time Per Item"
                : "One Time Per Cart"}
            </Typography>
          </Stack>
          <Typography
            className="fs-12 ff-Roboto fw-medium capitalize"
            color={colors.blackMain}
          >
            {values?.ecoPointsType}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        className="fs-14 ff-Roboto fw-medium mt-14 mb-7"
        color={colors.blackMain}
      >
        Maximum Points limit
      </Typography>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing="8px"

      >
        <Stack flex={1} style={isMobile ? { width: '100%' } : {}}><FormTextField inputProps={{ maxLength: 5 }} label="Per Day" name="limitPerDayPerUser" sx={{ mb: '14px' }} /></Stack>
        <Stack flex={1} style={isMobile ? { width: '100%' } : {}}> <FormTextField inputProps={{ maxLength: 5 }} label="Per Week" name="limitPerWeekPerUser" sx={{ mb: '14px' }} /></Stack>


      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing="8px"

      >
        <Stack flex={1} style={isMobile ? { width: '100%' } : {}}><FormTextField inputProps={{ maxLength: 5 }} label="Per Month" name="limitPerMonthPerUser" sx={{ mb: 0 }} /></Stack>
        <Stack flex={1} style={isMobile ? { width: '100%' } : {}}> <FormTextField inputProps={{ maxLength: 5 }} label="Per User" name="limitPerUser" sx={{ mb: 0 }} /></Stack>


      </Stack>
      <Typography
        className="fs-14 ff-Roboto fw-medium mt-14 mb-7"
        color={colors.blackMain}
      >
        Item SKUs
      </Typography>
      <FieldArray name="SKUid">
        {({ push, remove }) => (
          <>
            <div style={{ height: '185px', overflowY: 'scroll' }}>
              {values.SKUid.map((p, index) => {
                const sku = `SKUid[${index}].skuid`;
                return (
                  <Stack key={index} style={{ marginBottom: "8px" }}>
                    <TextField

                      fullWidth
                      id={sku}
                      name={sku}
                      value={p.skuid}
                      onChange={handleChange}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {values.SKUid.length > 1 && (
                              <IconButton
                                onClick={() => remove(index)}
                                style={{
                                  transform: "scale(1.143)",
                                  background: "rgb(237, 237, 237)",
                                  borderRadius: "4px",
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                );
              })}
            </div>
            <div style={{ textAlign: "center", marginBottom: '60px' }}>
              <Button
                type="button"
                sx={buttonStyle3}
                onClick={() => {
                  push({
                    sku: "",
                  })
                }

                }
                variant="contained"
                endIcon={<AddIcon />}
              >
                ADD
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </Stack>
  );
}
