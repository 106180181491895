import { useState } from "react"
import { Stack, TextField, InputAdornment, IconButton } from "@mui/material"
import { useFormikContext } from "formik"
import FormTextFieldLabel from "./FormTextFieldLabel"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"

export default function FormTextField({ name, adornment, onChange, label, sx, inputSx, children, lowerCase = false, whiteSpace = false, emptyCheck = false, visibility, setVisibility, forgotPassword = false, onClickForgotPassword, inputStyle, ...otherProps }) {
  const [blur, setBlur] = useState(true)

  const { values, touched, errors, handleChange, handleBlur, dirty } = useFormikContext()

  const onChangeVisibility = () => {
    let val = visibility
    setVisibility(!val)
  }

  let tempValue = values[name]
  if (tempValue && lowerCase) {
    tempValue = tempValue?.toLowerCase()?.trim()
  }
  if (tempValue && whiteSpace) {
    tempValue = tempValue?.replace(/^\s/g, "")
  }
  if (tempValue && typeof tempValue === "string") {
    tempValue = tempValue?.trimStart()
  }
  if (!tempValue) {
    tempValue = ""
  }

  return (
    <Stack sx={{ mb: "22px", ...sx }}>
      {(forgotPassword || label) && <FormTextFieldLabel label={label} forgotPassword={forgotPassword} onClickForgotPassword={onClickForgotPassword} />}
      <TextField
        sx={{
          "& .MuiInputBase-input": {
            ...inputSx,
          },
        }}
        fullWidth
        autoComplete="off"
        id={name}
        name={name}
        value={tempValue}
        onChange={e => {
          handleChange(e)
          setBlur(false)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={e => {
          handleBlur(e)
          var regex = /^\s*$/
          if (!regex.test(values[name])) {
            setBlur(true)
          } else {
            setBlur(false)
          }
        }}
        error={blur && Boolean(values[name] || emptyCheck) && touched[name] && Boolean(errors[name])}
        helperText={blur && Boolean(values[name] || emptyCheck) && (touched[name] || dirty[name]) && errors[name]}
        {...otherProps}
        type={!visibility ? otherProps.type : "text"}
        InputProps={{
          endAdornment:
            otherProps.type === "password" ? (
              <InputAdornment position="end">
                {
                  <IconButton
                    onMouseDown={e => {
                      e.preventDefault()
                      onChangeVisibility()
                    }}
                  >
                    {visibility ? <VisibilityOutlinedIcon style={{ color: "#8C8C8C", transform: "scale(0.7274)" }} /> : <VisibilityOffOutlinedIcon style={{ color: "#8C8C8C", transform: "scale(0.7274)" }} />}
                  </IconButton>
                }
              </InputAdornment>
            ) : (
              ""
            ),
            ...adornment,
          style: inputStyle,
          ...otherProps.inputProps
        }}
      >
        {" "}
        {children}
      </TextField>
    </Stack>
  )
}
