import './App.scss';
import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { Provider } from "react-redux";
import store from './redux/store/store';
import { ToastContainer } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import RootNavigation from './navigation/RootNavigation';

function App() {

  const CloseButton = ({ closeToast }) => (
    <IconButton disableRipple style={{ padding: '0' }} onClick={closeToast}>
      <ClearIcon style={{ transform: 'scale(1.143)', color: '#000' }} />
    </IconButton>
  );

  //load fonts initially
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Roboto', 'Roboto:Thin', 'Roboto:Light', 'Roboto:Regular', 'Roboto:Medium', 'Roboto:Bold',
          // 'Open Sans', 'Open Sans:Thin', 'Open Sans:Light', 'Open Sans:Regular', 'Open Sans:Medium', 'Open Sans:Bold',
          // 'Noto Sans', 'Noto Sans:Bold', 'Noto Sans:Thin', 'Noto Sans:Light', 'Noto Sans:Regular', 'Noto Sans:Medium'
        ]
      }
    })
  }, [])

  return (
    <Provider store={store} >
      <RootNavigation />
      <ToastContainer hideProgressBar={true} pauseOnFocusLoss={false} closeButton={CloseButton} />
    </Provider>
  );
}

export default App;
