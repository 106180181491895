import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import DesktopModeSetting from './components/DesktopModeSetting/DesktopModeSetting';
import MobileModeSetting from './components/MobileModeSetting/MobileModeSetting';
import './Setting.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setIndex } from '../../../redux/features/appSlice';

export default function Setting() {

    const isMobile = useMediaQuery("(max-width:600px)");

    const { initialIndexSetting } = useSelector(state => state.app)
    const dispatch = useDispatch()

    const [activeMenu, setActiveMenu] = useState(initialIndexSetting ? initialIndexSetting : 0);

    useEffect(() => {
        handleMenuChange(initialIndexSetting)
    }, [initialIndexSetting]);

    const handleMenuChange = (index) => {
        dispatch(setIndex(index))
        setActiveMenu(index);
    };

    return (
        <div className='setting-page'>
            {isMobile ? <MobileModeSetting activeMenuIndex={activeMenu} onMenuChange={handleMenuChange} /> : <DesktopModeSetting activeMenuIndex={activeMenu} onMenuChange={handleMenuChange} />}
        </div>
    );
}