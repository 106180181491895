import './ReportSection.scss'
import React from 'react';
import { Typography, TableContainer, Table, TableBody, TableRow, TableCell, Stack, CircularProgress, Box } from '@mui/material';
import EnchanedTableHeader from '../../../../../components/EnchanedTableHeader';
import { colors } from '../../../../../utils/colors';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import { AddBoxRounded } from '@mui/icons-material';
import downloadcircle from '../../../../../assets/images/downloadcircle.svg';
import Moment from 'moment';

const headerCell = [
    {
        name: 'Report',
        width: '25.625%'
    },
    {
        name: 'Date Created',
        width: '61.25%'
    },
    {
        name: 'Action',
        width: '10%'
    }
]

export default function ReportTable({ length, page, rowsPerPage, reportList, handleGetDownload, handleDownloadReport }) {
    return (
        <div className='ReportTable'>
            <div className='tableContainer'>
                <TableContainer sx={{ maxHeight: 'calc(100vh - 128px)' }}>
                    <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                        <EnchanedTableHeader
                            headerCell={headerCell}
                            checkbox={false}
                            preference={false}
                            infoIconLast={false}
                            initialWidth={96.875}
                        />
                        <TableBody className='mainUserBodyTable'>
                            {
                                reportList?.map((item, index) => (
                                    <TableRow className='tableRowHover' key={index}>
                                        <TableCell align='center' className='color-inherit' style={{ width: 'calc(100% - 96.875%)' }}>
                                            <Typography className={'ff-Roboto fs-12 fw-regular'}>
                                                {(rowsPerPage * (page)) + index + 1}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '25.625%' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black capitalize'>
                                                {item?.reportType || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '61.25%' }}>
                                            <Typography className='fs-12 ff-Roboto fw-regular ellipseStyle black'>
                                                {Moment(item?.downloadedDate).format('DD MMM, yyyy') || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '10%' }}>
                                            {
                                                item?.getdownloads &&
                                                <Stack style={{ cursor: 'pointer', width: 'min-content' }} onClick={() => handleGetDownload(item)} direction='row' alignItems='center'>
                                                    <DownloadIcon style={{ transform: 'scale(0.706)' }} />
                                                    <Typography className='fs-12 ff-Roboto fw-bold ellipseStyle' color={colors.blackMain}>
                                                        Get Report
                                                    </Typography>
                                                </Stack>
                                            }
                                            {
                                                item?.pending &&
                                                <Stack direction='row' alignItems='center'>
                                                    <Box className='flex-center' style={{ position: 'relative', marginRight: '8px', cursor: 'pointer' }}>
                                                        <ClearIcon style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%) scale(0.5)' }} />
                                                        <CircularProgress
                                                            size={16}
                                                            color="success" />
                                                    </Box>
                                                    <Typography className='fs-12 ff-Roboto fw-bold ellipseStyle' color={colors.blackMain}>
                                                        Preparing
                                                    </Typography>
                                                </Stack>
                                            }
                                            {
                                                item?.download &&
                                                <Stack onClick={() => handleDownloadReport(item)} style={{ cursor: 'pointer', width: 'min-content' }} direction='row' alignItems='center'>
                                                    <img style={{ marginRight: '8px' }} src={downloadcircle} alt="downloadcircle" />
                                                    <Typography className='fs-12 ff-Roboto fw-bold ellipseStyle' color={colors.blackMain}>
                                                        Download
                                                    </Typography>
                                                </Stack>
                                            }


                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
