import { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Form, Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import "./EditEcoCampaign.scss";
import { colors } from "./../../../../../utils/colors";
import { buttonStyle2 } from "../../../../../utils/commonStyle";
import EcoCampaignDetails from "./componets/EcoCampaignDetails";
import EcoCampaignSetting from "./componets/EcoCampaignSetting";
import EcoCampaignAdditionalInfo from "./componets/EcoCampaignAdditionalInfo";
import { setLoading } from "../../../../../redux/features/appSlice";
import appapi from "../../../../../api/apiClient";
import { showmessage } from "../../../../../utils/toastr";
import EcoStatusConfirmationDialog from "../EcoStatusConfirmationDialog/EcoStatusConfirmationDialog";
import { useEffect } from "react";

export default function EditEcoCampaign({ open, handleCloseDialog, template }) {
  const isMobile = useMediaQuery("(max-width:1000px)");

  /**
   * dialog style
   */
  const sx = {
    "& .MuiDialog-container": {
      alignItems: isMobile ? "end" : "center",
      WebkitAlignItems: isMobile ? "end" : "center",
      WebkitBoxAlign: isMobile ? "end" : "center",
    },
    "& .MuiPaper-root": {
      width: isMobile ? "696px" : "728px",
      maxWidth: isMobile ? "696px" : "728px",
      // minHeight: '424px',
      height: isMobile ? "620px" : "656px",
      margin: isMobile ? "0" : "8px",
      padding: "0 16px 23px 16px",
      borderRadius: isMobile ? "16px 16px 0 0" : "4px",
    },
  };

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const formRef = useRef();

  const [activeMenu, setActiveMenu] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  useEffect(() => {
    formRef?.current?.validateForm();
  }, [activeMenu]);

  const values = {
    ecoInitiativeScheduleId: null,
    ecoInitiativeTemplateId: "",
    title: "",
    shortDescription: "",
    startDate: null,
    endDate: null,
    ecoPoints: "",
    ecoPointsType: "",
    ecoPointsRedemption: "",
    ecoRedemptionType: "",
    SKUid: [
      {
        skuid: "",
      },
    ],
    brandLogo: "",
    brandName: "",
    subBrandName: null,
    product: null,
    countryId: 103,
    cityId: [],
    detailedDescription: "",
  };

  const initialValues = {
    ...values,
    ...template,
    ecoPoints: template?.ecoPoints
      ? template?.ecoPoints.toString()
      : template?.defaultPoints.toString(),
    ecoPointsType: template?.ecoPointsType
      ? template?.ecoPointsType
      : template?.pointsType,
    ecoRedemptionType: template?.ecoRedemptionType
      ? template?.ecoRedemptionType
      : template?.defaultRedemptionType,
    ecoPointsRedemption: template?.ecoPointsRedemption
      ? template?.ecoPointsRedemption
      : template?.pointsRedemption,
    formFooterText: template?.formFooterText || null,
    limitPerDayPerUser: template?.limitPerDayPerUser?.toString() || null,
    limitPerWeekPerUser: template?.limitPerWeekPerUser?.toString() || null,
    limitPerMonthPerUser: template?.limitPerMonthPerUser?.toString() || null,
    limitPerUser: template?.limitPerUser?.toString() || null,
    SKUid:
      template?.SKUid && template?.SKUid.length > 0
        ? template?.SKUid
        : [
          {
            skuid: "",
          },
        ],
    countryId: template?.countryId || 103,
    cityId: template?.cityId ? template?.cityId.split(',').map(res => parseInt(res)): [],
    citySelection: template?.citySelection || 'all'

  };

  const validation = () => {
    const details = {
      title: yup
        .string("Enter your campaign Name")
        .min(3, "Name must minimum of 3 characters")
        .max(50, "Name must maximum of 50 characters")
        .required("Name is Required")
        .trim(),
      shortDescription: yup.string("Enter your Description").max(50, ""),
      detailedDescription: yup.string("Enter your Description").max(500, ""),
    };

    const setting = {
      startDate: yup
        .date("Enter your StartDate")
        .nullable()
        .min(
          moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .subtract(1, "day")
            .toDate()
        )
        .required("Start Date is Required"),
      endDate: yup
        .date("Enter your EndDate")
        .nullable()
        .required("End Date is Required")
        .when("startDate", (val, schema) => {
          if (val) {
            const startDate = new Date(val);
            startDate.setDate(startDate.getDate() + 1);
            return (
              val && schema.min(startDate, "Should be Greater than start date")
            );
          }
        }),
      ecoPoints: yup
        .number("Enter your Eco Points")
        .typeError("You must specify a number")
        .required("Eco Points is Required"),
      limitPerDayPerUser: yup
        .number("Enter your per day user limit")
        .typeError("You must specify a number"),
      limitPerWeekPerUser: yup
        .number("Enter your per week user limit")
        .typeError("You must specify a number"),
      limitPerMonthPerUser: yup
        .number("Enter your per month user limit")
        .typeError("You must specify a number"),
      limitPerUser: yup
        .number("Enter your per user limit")
        .typeError("You must specify a number"),
      citySelection: yup
        .string("Enter your per user limit").notRequired(),
      cityId: yup
        .array().of(yup.number()).when("citySelection", (val, schema) => {
          if (val && val === 'specific') {
            return (
              schema.required("City is is Required")
            );
          }
        }),
    };

    const additionalDetails = {
      brandLogo: yup.string("Upload your brand logo"),
      brandName: yup
        .string("Enter your brand name")
        .trim()
        .required("Brand name is Required"),
      subBrandName: yup.string("Enter your Subbrand").nullable(),
      product: yup.string("Enter your Product").nullable(),
      countryId: yup
        .string("Enter your Country")
        .trim()
        .required("Country is Required"),
    };

    if (activeMenu === 0 && !isMobile) {
      return details;
    }

    if (activeMenu === 1 && !isMobile) {
      return { ...details, ...setting };
    }

    if (activeMenu === 2 || isMobile) {
      return { ...details, ...setting, ...additionalDetails };
    }
  };

  const SideToggleButton = ({ label, isActive = false, onMenuChange }) => {
    const handleMenuChange = () => {
      if (!isActive) {
        onMenuChange();
      }
    };

    return (
      <Stack
        onClick={handleMenuChange}
        className={`sideToggleBtn ${isActive ? "btn-active" : ""}`}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          style={{ color: colors.blackMain }}
          className="fs-14 ff-Roboto fw-medium"
        >
          {label}
        </Typography>
        <KeyboardArrowRightIcon
          className="arrow"
          style={{ color: colors.blackMain }}
        />
      </Stack>
    );
  };

  const save = (values) => {
    const format1 = "YYYY-MM-DD";
    const payload = {
      ecoInitiativeTemplateId: values.ecoInitiativeTemplateId,
      title: values.title,
      shortDescription: values.shortDescription,
      detailedDescription: values.detailedDescription,
      startDate: moment(values.startDate).format(format1) || null,
      endDate: moment(values.endDate).format(format1) || null,
      ecoPoints: parseInt(values.ecoPoints),
      ecoPointsType: values.ecoPointsType,
      ecoRedemptionType: values.ecoRedemptionType,
      ecoPointsRedemption: values.ecoPointsRedemption,
      formFooterText: values.formFooterText || null,
      limitPerDayPerUser: values.limitPerDayPerUser || null,
      limitPerWeekPerUser: values.limitPerWeekPerUser || null,
      limitPerMonthPerUser: values.limitPerMonthPerUser || null,
      limitPerUser: values.limitPerUser || null,
      brandLogo:
        activeMenu === 2 || isMobile
          ? values.brandLogo || user.enterpriseLogo || null
          : values.brandLogo || null,
      brandName: values.brandName || null,
      subBrandName: values.subBrandName || null,
      product: values.product || null,
      countryId: values.countryId || null,
      cityId: values.cityId.join(',') || null,
      enterpriseId: user.enterpriseId,
      createdBy: user.name,
      updatedBy: user.name,
      SKUid: values.SKUid.filter((res) => res.skuid),
      isScheduleStatus:
        activeMenu === 2 || isMobile || template?.isEdit ? "1" : "0",
    };
    if (values.ecoInitiativeScheduleId) {
      updateCampaign(values.ecoInitiativeScheduleId, payload);
    } else {
      createCampaign(payload);
    }
  };

  const createCampaign = async (payload) => {
    dispatch(setLoading(true));
    const response = await appapi.post(
      `/ecoinitiative/${user.enterpriseId}`,
      payload
    );
    if (response.ok) {
      dispatch(setLoading(false));
      const data = response.data;
      if (data.status === "success") {
        if (payload.isScheduleStatus === "1") {
          handleCloseDialog(true);
        } else {
          formRef?.current?.setFieldValue("ecoInitiativeScheduleId", data.data);
          setRefresh(true);
        }
        // showmessage(data.message);
      } else {
        showmessage(data.message, "error");
      }
    } else {
      dispatch(setLoading(false));
      // showmessage(`Something Went Wrong!`, 5000, "error");
    }
  };

  const updateCampaign = async (ecoInitiativeScheduleId, payload) => {
    dispatch(setLoading(true));
    const response = await appapi.put(
      `/ecoinitiative/${user.enterpriseId}/${ecoInitiativeScheduleId}`,
      payload
    );
    if (response.ok) {
      dispatch(setLoading(false));
      const data = response.data;
      if (data.status === "success") {
        // showmessage(data.message);
        if (payload.isScheduleStatus === "1") {
          handleCloseDialog(true);
        } else {
          setRefresh(true);
        }
      } else {
        showmessage(data.message, "error");
      }
    } else {
      dispatch(setLoading(false));
      // showmessage(`Something Went Wrong!`, 2000, "error");
    }
  };

  const deleteCampaign = async () => {
    dispatch(setLoading(true));
    const response = await appapi.delete(
      `/ecoinitiative/${user.enterpriseId}/${formRef.current?.values?.ecoInitiativeScheduleId}`,
      { updatedBy: user.name }
    );
    if (response.ok) {
      const data = response.data;
      if (data.status === "success") {
        // showmessage(data.message);
        handleCloseDialog(true);
      } else {
      }
      dispatch(setLoading(false));
    } else {
      // showmessage(`Something Went Wrong!`, 2000, "error");
      dispatch(setLoading(false));
    }
  };

  const handleDeleteConfirmClose = (confirm) => {
    setDeleteConfirm(false);
    if (confirm) {
      deleteCampaign();
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleCloseDialog(true);
  };

  return (
    <Dialog
      sx={sx}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="edit-eco-campaign"
      aria-describedby="edit-eco-campaign-description"
    >
      <DialogTitle
        className="fs-16 ff-Roboto fw-bold"
        sx={{
          borderBottom: 1,
          borderBottomColor: "#474747",
          position: "sticky",
          top: "0",
          zIndex: 2,
          background: "#FFf",
        }}
      >
        Edit Campaign
        {(formRef?.current?.values?.ecoInitiativeScheduleId ||
          template?.ecoInitiativeScheduleId) && (
            <IconButton
              aria-label="delete"
              onClick={() => setDeleteConfirm(true)}
              sx={{
                position: "absolute",
                right: 40,
                top: 12,
                color: colors.black,
              }}
            >
              <DeleteOutlinedIcon fontSize="16px" />
            </IconButton>
          )}
        <IconButton
          aria-label="close"
          onClick={() => handleCloseDialog(refresh)}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
            color: colors.black,
          }}
        >
          <CloseIcon fontSize="16px" />
        </IconButton>
      </DialogTitle>
      <Stack>
        <Stack direction="row" sx={{ mt: "16px" }}>
          {!isMobile && (
            <Stack flex={"30%"}>
              <div style={{ position: "sticky", top: "81px" }}>
                {["Details", "Settings", "Additional Info"].map(
                  (label, index) => (
                    <SideToggleButton
                      key={index}
                      label={label}
                      isActive={index === activeMenu}
                      onMenuChange={() => {
                        setActiveMenu(index);
                      }}
                    />
                  )
                )}
              </div>
            </Stack>
          )}
          <Stack
            flex={isMobile ? "100%" : "70%"}
            className={
              isMobile ? "sideBodyContainerMobile" : "sideBodyContainer"
            }
          >
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={yup.object().shape(validation())}
              onSubmit={(values) => save(values)}
            >
              {({ isValid, errors, values }) => (
                <Form
                  noValidate
                  autoComplete="off"
                  className="flex-column "
                  style={{ flex: 1 }}
                > 
                  <>
                    {(activeMenu === 0 || isMobile) && <EcoCampaignDetails />}
                    {(activeMenu === 1 || isMobile) && <EcoCampaignSetting />}
                    {(activeMenu === 2 || isMobile) && (
                      <EcoCampaignAdditionalInfo />
                    )}
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      flexGrow={1}
                      alignItems="flex-end"
                      sx={{ mt: "16px" }}
                    >
                      <Button
                        sx={{
                          ...buttonStyle2,
                          width:
                            activeMenu === 2 || isMobile ? "100%" : "160px",
                        }}
                        onClick={() => formRef?.current?.handleSubmit()}
                        variant="contained"
                        disabled={!isValid}
                      >
                        {activeMenu === 2 || isMobile ? "CREATE" : "SAVE"}
                      </Button>
                    </Stack>
                  </>
                </Form>
              )}
            </Formik>
          </Stack>
        </Stack>
      </Stack>
      <EcoStatusConfirmationDialog
        open={deleteConfirm}
        handleClose={handleDeleteConfirmClose}
        type="delete"
      />
    </Dialog>
  );
}
