import "./VerifyOTP.scss"
import EcoActionsContainer from "../Components/EcoActionsContainer/EcoActionsContainer"
import { colors } from "../../../utils/colors"
import { Button, Stack, Typography, useMediaQuery } from "@mui/material"
import * as yup from "yup"
import { Form, Formik } from "formik"
import FormTextField from "../../../components/FormTextField"
import { buttonStyleForWebForm } from "../../../utils/commonStyle"
import { useRef, useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { uploadFileWithoutToken } from "../../../utils/commonUtils"
import appapi from "../../../api/apiClient"
import { showmessage } from "../../../utils/toastr"
import { useDispatch } from "react-redux"
import { setLoading } from "../../../redux/features/appSlice"

const validationSchema = yup.object({
  otp: yup
    .string()
    .required()
    .min(6, "OTP must be 6 digits")
    .matches(/^[0-9]*$/, "OTP should be only numbers")
    .label("OTP"),
})

export default function VerifyOTP() {
  const formRef = useRef()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width:800px)")
  const { state } = useLocation()
  const [sec, setSec] = useState(50)
  const dispatch = useDispatch()
  const [isResendClicked, setResendClicked] = useState(false)
  const { mobile, otpId, data, actionType, image } = state

  const myInterval = useRef(null)

  useEffect(() => {
    startInterval()
    return () => {
      if (myInterval.current) {
        clearInterval(myInterval.current)
        myInterval.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (sec <= 0 && myInterval.current) {
      clearInterval(myInterval.current)
      myInterval.current = null
    }
    if (sec === 50 && !myInterval.current) {
      startInterval()
    }
  }, [sec])

  const startInterval = () => {
    const intervalId = setInterval(() => {
      if (sec > 0) {
        setSec(sec => sec - 1)
      }
    }, 1000)
    myInterval.current = intervalId
  }

  const submit = async values => {
    dispatch(setLoading(true))
    const payload = {
      otpId: otpId,
      otp: values.otp,
      deviceId: "123",
    }
    const response = await appapi.post("webform/verifyMobileNumber", payload)
    if (response.ok) {
      const res = response.data
      if (res.status === "success") {
        if (actionType === "ewastecollection") {
          submitEWaste(res.data.consumerId)
        }
        if (actionType === "plasticwastecollection") {
          submitPlasticWaste(res.data.consumerId)
        }
        if (actionType === "formtemplate3") {
          submitInteractiveWorkshop(res.data.consumerId)
        }
      } else {
        showmessage(res.message, "error")
        dispatch(setLoading(false))
      }
    }
  }

  const submitEWaste = async consumerId => {
    const response = await appapi.post(`webform/eWaste/${consumerId}`, data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image,
            actionType,
            isSkippedVerification: false
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  const submitPlasticWaste = async (consumerId) => {
    const response = await appapi.post(`webform/plasticWaste/${consumerId}`, data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image,
            actionType,
            isSkippedVerification: true
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  const submitInteractiveWorkshop = async consumerId => {
    const response = await appapi.post(`webform/joinEvent/${consumerId}`, data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image: null,
            actionType,
            isSkippedVerification: false
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  const resendOTP = async () => {
    dispatch(setLoading(true))
    setSec(sec => (sec = 50))
    const response = await appapi.post(`webform/resendOTP/${otpId}`)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        showmessage(data.message, "success")
      } else {
        showmessage(data.message, "error")
      }
    }
  }

  return (
    <EcoActionsContainer>
      <div className="verifyOTPFormContainer" style={{ width: isMobile ? "100%" : "384px", marginLeft: "24px", marginRight: "24px" }}>
        <Typography className="ff-Roboto fw-medium fs-32" color={"#3787C9"}>
          Enter OTP
        </Typography>
        <Typography className="ff-Roboto fw-regular fs-16 mt-16" color={"#737179"}>
          A six digit code has been sent to your mobile number <span style={{ color: "#3787C9" }}>+91 {mobile}</span>
        </Typography>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            otp: "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => submit(values)}
        >
          {({ handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Stack flex={1} style={{ marginTop: 80, width: "100%" }} direction={"row"}>
                <FormTextField inputProps={{ maxLength: 6 }} inputStyle={{ backgroundColor: "white", width: "100%" }} variant="filled" placeholder="Enter OTP Here" name="otp" sx={{ mb: "2px", width: "100%" }} style={{ fontSize: 14, backgroundColor: "#FFFFFF" }} />
              </Stack>

              <Button disabled={!(dirty && isValid)} sx={{ ...buttonStyleForWebForm, width: "100%", mt: "36px" }} type="submit">
                VERIFY
              </Button>
              <Stack flex={1} direction={"row"} alignItems={"center"} sx={{ mt: "24px" }}>
                <Stack flex={1} direction={"row"} alignItems={"center"}>
                  <Typography className="ff-Roboto fw-regular fs-16" color={"#737179"}>
                    Resent OTP in &nbsp;
                  </Typography>
                  <Typography className="ff-Roboto fw-medium fs-16" color={colors.black}>
                    {sec} Sec
                  </Typography>
                </Stack>
                <Button disabled={sec > 0} type="button" onClick={() => resendOTP()}>
                  {" "}
                  RESEND{" "}
                </Button>
              </Stack>
              <Stack direction="row" className="mt-16" justifyContent="center"></Stack>
            </Form>
          )}
        </Formik>
      </div>
    </EcoActionsContainer>
  )
}
