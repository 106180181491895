import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function LineChart({ isMobile }) {

    const options = {
        type: "chart",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: "top"
            },
            title: {
                display: false,
                text: "Chart.js Line Chart"
            }
        },
        scales: {
            y: {
                display: false
            },
            x: {
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: isMobile ? 8 : 12,
                    }
                }
            }
        }
    };

    const labels = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
    ];

    const data = {
        labels,
        datasets: [
            {
                // label: 'Dataset 1',
                data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                borderColor: "#48B549",
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderWidth: 1
            }
        ]
    };

    return (
        <Line options={options} data={data} />
    )
}
