import React, { useState, useRef, useEffect, createRef } from "react"
import "./WorkShopShare.scss"
import { useLocation } from "react-router-dom"
import { Box, Button, CircularProgress, Stack, Typography, useMediaQuery } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"
import EcoActionsContainer from "../Components/EcoActionsContainer/EcoActionsContainer"
import { buttonStyle1 } from "../../../utils/commonStyle"
import diz from "../../../assets/images/diz.svg"
import { useScreenshot } from "../../../components/Screenshot"
import { showmessage } from "../../../utils/toastr"
import { LazyLoadImage } from "react-lazy-load-image-component"
import workshopShare from "../../../assets/images/workshopShare.svg"

export default function WorkShopShare() {
  const isMobile = useMediaQuery("(max-width:800px)")
  const ref = createRef(null)
  const [imageurl, takeScreenshot] = useScreenshot({ quality: 1.0 })

  useEffect(() => {
    if (imageurl) {
      share(imageurl)
    }
  }, [imageurl])

  const dataURLtoFile = dataurl => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], "share.png", { type: mime })
  }

  const share = async imageurl => {
    if (navigator.share) {
      const response = await navigator.share({ files: [dataURLtoFile(imageurl)] })
      takeScreenshot(null)
    } else {
      takeScreenshot(null)
      showmessage("Share option is not available", "error")
    }
  }

  return (
    <EcoActionsContainer>
      <div className="ecoActionShareContainer" style={{ width: isMobile ? "100%" : "384px", marginLeft: "24px", marginRight: "24px" }}>
        <Stack ref={ref}>
          <div className="logoShareImg" style={{ marginLeft: "8px", marginBottom: "8px" }}>
            <img className="shareLogo" alt="dizLogo" src={diz} />
          </div>
          <div className="ecoActionShareCard">
            <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <LazyLoadImage
                alt={"preview"}
                height={"183px"}
                effect="blur"
                crossOrigin="anonymous"
                src={"https://diz-dev-app.s3.us-east-2.amazonaws.com/iws.svg"} // use normal <img> attributes as props
                width={"100%"}
                placeholderSrc={workshopShare}
                style={{ width: "100%", height: "183px", objectFit: "contain" }}
              />
              {/* <MuiImage.Image showLoading={<CircularProgress style={{color: 'white'}} size={"1rem"} />} crossOrigin="anonymous" alt="" src={"https://diz-dev-app.s3.us-east-2.amazonaws.com/iws.png"} style={{ width: "100%", height: "auto", objectFit: "contain" }} /> */}
              {/* <div className="divBgImage" style={{ backgroundImage: `url(${previewImageUrl})` }} /> */}
            </div>
            {/* <Box component={"img"} crossOrigin="anonymous" loading="eager" alt="" src={"https://diz-dev-app.s3.us-east-2.amazonaws.com/iws.png"} style={{ width: "100%", height: "auto", objectFit: "contain" }} /> */}
            <Stack alignItems={"center"} className="mt-12 mb-8">
              <Typography className="ff-Roboto fw-medium fs-14" sx={{ color: "#3787C9", textAlign: "center" }}>
                Just attended Climate Interactive Workshop
              </Typography>
              <Typography className="ff-Roboto fw-regular fs-12 mt-4" sx={{ color: "#737179", textAlign: "center" }}>
                Aware and commit to making eco-friendly choices.
              </Typography>
            </Stack>
          </div>
        </Stack>
        <Button onClick={() => takeScreenshot(ref.current)} sx={{ ...buttonStyle1, mt: "24px", textTransform: "none", width: "100%" }} type="button" endIcon={<ShareIcon />}>
          Share on Social Media
        </Button>
      </div>
    </EcoActionsContainer>
  )
}
