import "./EcoCampaignTableView.scss";
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import moment from "moment";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditIcon from '@mui/icons-material/Edit';

import refuse from "../../../../../assets/images/refuse.svg";
import recycle from "../../../../../assets/images/recycle.svg";
import reuse from "../../../../../assets/images/reuse.svg";

import { colors } from "../../../../../utils/colors";
import EcoInitiativeCampaignBrand from "../EcoInitiativeCampaignBrand/EcoInitiativeCampaignBrand";
import TableColumnFilter from './../TableColumnFilter/TableColumnFilter';
import { useState } from 'react';
import EcoCampaignStatusButton from './../EcoCampaignStatusButton/EcoCampaignStatusButton';
import { ContentDuplicate } from "../../../../../utils/icons";


const headerCellData = [
  {
    name: "Initiative",
    width: "25",
    selected: true,
    default: true,
  },
  {
    name: "Start Date",
    width: "10%",
    key: "startDate",
    type: "date",
    selected: true,
  },
  {
    name: "End Date",
    width: "10%",
    key: "endDate",
    type: "date",
    selected: true,
  },
  {
    name: "Status",
    width: "12%",
    selected: true,
  },
  {
    name: "City",
    width: "10%",
    key: "cityName",
    selected: true,
  },
  {
    name: "Brand",
    width: "14%",
    selected: true,
  },
  {
    name: "Created Date",
    width: "10%",
    key: "createdAt",
    type: "date",
    selected: true,
  },
  {
    name: "Created By",
    width: "10%",
    key: "createdBy",
    selected: false,
  },
  {
    name: "Recent Activity",
    width: "10%",
    key: "updatedAt",
    type: "date",
    selected: false,
  },
  {
    name: "Product",
    width: "14%",
    key: "product",
    selected: false,
  },
  {
    name: "Country",
    width: "10%",
    key: "country",
    selected: false,
  },
];

export default function EcoCampaignTableView({
  ecoInitiatives,
  onStatus,
  onDuplicate,
  onEdit,
  permission
}) {


  const [headerCell, setHeaderCell] = useState(headerCellData);

  const headerCells = headerCell?.filter(item => item.selected);

  /**
   *
   * @returns Header of the Table
   */
  const EnchanedTableHeader = () => {
    return (
      <TableHead className="mainUserTableHead">
        <TableRow
          sx={{
            "& th": {
              backgroundColor: colors.secondary,
            },
          }}
        >
          {headerCells.map((header, index) => (
            <TableCell
              key={"header-cell" + index}
              style={{ width: header.width, paddingLeft: index === 0 ? 16 : 0 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={
                  index === headerCells.length - 1
                    ? "space-between"
                    : "flex-start"
                }
              >
                <Typography
                  className="ff-Roboto fs-12 fw-semibold"
                  style={{ color: colors.primaryDark }}
                >
                  {header.name}
                </Typography>
                {index === headerCells.length - 1 && (
                  <TableColumnFilter menuList={headerCell} onApply={(menus) => setHeaderCell(menus)} />
                )}
              </Stack>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  /**
   *
   * @returns title of the EcoInitiatives
   */
  const EcoTitle = ({ campaign }) => {
    const categoryImg =
      campaign?.categoryType === "reuse"
        ? reuse
        : campaign?.categoryType === "recycle"
          ? recycle
          : refuse;
    const categoryBorderColor =
      campaign?.categoryType === "reuse"
        ? "#6EA4CE"
        : campaign?.categoryType === "recycle"
          ? "#57AF8C"
          : "#F48257";
    return (
      <Stack direction="row" style={{ height: "100%", borderLeftColor: categoryBorderColor }} className="border-type">
        <Stack className="ecoInitoatives-category-container" justifyContent="center">
          <img
            className="ecoInitoatives-category-img"
            src={categoryImg}
            alt="Category"
          />
        </Stack>
        <Stack
          style={{ paddingLeft: "8px" }}
          justifyContent="center"
          className="row-hover"
          flexGrow={1}
        >
          <Typography
            style={{ color: colors.deactive }}
            className="ff-Roboto fw-bold ellipseStyle capitalize"
          >
            {campaign?.title}
          </Typography>
          <Stack direction="row" alignItems="center">
            <AccessTimeIcon fontSize="14px" style={{ marginRight: "8px" }} />
            <Typography className="fs-12 ff-Roboto fw-regular ellipseStyle">
              1 month
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <TableContainer>
      <Table
        style={{ tableLayout: "fixed" }}
        stickyHeader
        aria-label="sticky table"
        sx={{ minWidth: 650 }}
      >
        <EnchanedTableHeader />
        <TableBody
          className="mainUserBodyTable"
          sx={{ backgroundColor: "transparent" }}
        >
          {ecoInitiatives.map((item, index) => (
            <TableRow className="tableRowHover" key={index}>
              {headerCells.map((header, headerIndex) => (
                <TableCell
                  key={headerIndex}
                  style={{
                    width: header.width,
                    height: 80,
                    borderBottomWidth: 0,
                  }}
                >
                  <Stack
                    style={{
                      marginTop: "8px",
                      backgroundColor: "#fff",
                      height: "72px",
                      position: "relative",
                    }}
                  >
                    {header.name === "Initiative" && (
                      <EcoTitle campaign={item} />
                    )}
                    {header.name === "Brand" && (
                      <EcoInitiativeCampaignBrand campaign={item} />
                    )}
                    {header.name === "Status" && (
                      <EcoCampaignStatusButton
                        status={item?.campaignStatus}
                        onChangeStatus={() => onStatus(item)}
                        permission={permission}
                      />
                    )}
                    {!["Initiative", "Brand", "Status"].includes(
                      header.name
                    ) && (
                        <Stack
                          sx={{ height: "100%" }}
                          justifyContent="center"
                          className="row-hover"
                        >
                          <Typography
                            className="ff-Roboto fs-14 "
                            style={{ color: colors.black }}
                          >
                            {header?.type === "date"
                              ? item[header.key] ? moment(item[header.key]).format("DD-MM-YYYY") : '-'
                              : item[header.key] || "-"}
                          </Typography>
                        </Stack>
                      )}
                    {headerIndex === headerCells.length - 1 && (
                      <Stack
                        className="actionContainer"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                      >
                        {item?.campaignStatus === 'scheduled' && <Tooltip title="Edit campaign" 
                          disableInteractive
                          arrow
                          componentsProps={{
                              tooltip: {
                                  sx: {
                                      fontSize: '12px',
                                      fontFamily: 'Roboto',
                                      fontWeight: '400',
                                      bgcolor: colors.primaryDark,
                                      '& .MuiTooltip-arrow': {
                                          color: colors.primaryDark,
                                      },
                                  },
                              },
                          }}
                        >
                          <IconButton
                            style={{
                              color: colors.primaryDark,
                              fontSize: "16px",
                            }}
                            aria-label="edit"
                            component="label"
                            onClick={() => onEdit(item)}
                          >
                            <EditIcon
                              fontSize="16px"
                              style={{ color: colors.primaryHover }}
                            />
                          </IconButton>
                        </Tooltip>}

                        <Tooltip title="Duplicate campaign" 
                          disableInteractive
                          arrow
                          componentsProps={{
                              tooltip: {
                                  sx: {
                                      fontSize: '12px',
                                      fontFamily: 'Roboto',
                                      fontWeight: '400',
                                      bgcolor: colors.primaryDark,
                                      '& .MuiTooltip-arrow': {
                                          color: colors.primaryDark,
                                      },
                                  },
                              },
                          }}
                        >
                          <IconButton
                            style={{
                              color: colors.primaryDark,
                              fontSize: "16px",
                            }}
                            aria-label="contentDuplicate"
                            component="label"
                            onClick={() => onDuplicate(item)}
                          >
                            <ContentDuplicate
                              fontSize="16px"
                              style={{ color: colors.primaryHover }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
