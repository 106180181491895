import "./EcoInitiativeMobileFooter.scss";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";

const tabs = ["TEMPLATES", "CAMPAIGNS"];

export default function EcoInitiativeMobileFooter({ onTabChange }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if(onTabChange) {
      onTabChange(activeTab);
    }
  },[activeTab])

  return (
    <div className="eco-mobile-footer-container">
      <Stack direction="row" sx={{ height: "100%", position: 'relative' }}>
        {tabs.map((tab, index) => (
          <Stack
            key={index}
            flex={1}
            alignItems="center"
            justifyContent="center"
            className={`diZ-tab ${index === activeTab && "activeTab"}`}
            onClick={() => handleTabChange(index)}
          >
            <Typography className="fs-14 ff-Roboto fw-medium">{tab}</Typography>
          </Stack>
        ))}
        <div className="glider"  style={{ transform: `translateX(${activeTab * 100}%)`}}></div>
      </Stack>
    </div>
  );
}
