import "./ImageUpload.scss"
import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import UploadIcon from "@mui/icons-material/Upload"
import { Stack, IconButton, Typography } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import { colors } from "../../utils/colors"
import { showmessage } from "../../utils/toastr"

export default function ImageUpload({ handleFile, inputId, name, url, helpText = "File dimensions should be 300x300 upto 1 MB", backgroundColor = "#F2F2F2", showTitle = true }) {
  const [error, setError] = useState("")

  const [file, setFile] = useState(null)
  const [filePreview, setFilePreview] = useState(null)

  const onDrop = async files => {
    if (files.length == 1) {
      const image = new Image()
      image.src = URL.createObjectURL(files[0])
      image.addEventListener("load", () => {
        handleFile(files[0])
        setFile(files[0])
        let objUrl = URL.createObjectURL(files[0])
        setFilePreview(objUrl)
        setError(false)
      })
    } else {
      showmessage(`Multiple files are not allowed.`)
    }
  }

  const onCancelFile = () => {
    handleFile(null)
    setFilePreview(null)
    setError(false)
    setFile(null)
  }

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    onDrop,
    maxFiles: 1,
  })

  const onSelectFile = event => {
    console.log(event)
  }

  return (
    <div
      className="drop-zone-container flex-center"
      style={{ border: !Boolean(error) && (file || url) ? null : "1px dashed #C6C6C6", borderRadius: 8, flexDirection: "column", backgroundColor: !Boolean(error) && (file || url) ? "transparent" : backgroundColor, height: !Boolean(error) && (file || url) ? "180px" : "100px" }}
    >
      {!Boolean(error) && (file || url) && (
        <IconButton className="cancelButton" onClick={onCancelFile}>
          <ClearIcon style={{ transform: "scale(0.7)", color: "rgb(6, 29, 48)" }} />
        </IconButton>
      )}
      {!Boolean(error) && !(file || url) && (
        <div style={{ width: "100%", height: "100%", outline: "none", border: "none" }} className="dropZone" {...getRootProps({ className: "dropzone" })}>
          <Stack flex={1} justifyContent="center" alignItems="center" height={"100%"}>
            <input id={inputId} name={name} {...getInputProps()} />
            <UploadIcon style={{ color: "#3787C9", fontSize: "35px" }} />
            {isDragReject ? (
              <>
                <Typography className="fs-14 ff-Roboto fw-medium" color={colors.blackMain}>
                  Images only supported
                </Typography>
              </>
            ) : (
              <>
                {showTitle && (
                  <Typography className="fs-14 ff-Roboto fw-medium" color={colors.blackMain}>
                    Drop File Here
                  </Typography>
                )}
                {helpText && (
                  <Typography className="fs-12 ff-Roboto fw-regular mt-6" color={colors.blackMain} style={{ textAlign: "center", margin: "0px 32px" }}>
                    {helpText}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        </div>
      )}
      {Boolean(error) && !(file || url) && (
        <div className="dropZone" style={{ width: "100%", height: "100%", outline: "none", border: "none" }} {...getRootProps({ className: "dropzone" })}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <input id={inputId} name={name} {...getInputProps()} />
            <div style={{ height: "30px", width: "25px" }} className="flex-center">
              <UploadIcon style={{ color: "#3787C9", width: "35px" }} />
            </div>
            <Typography className="fs-10 ff-Roboto fw-regular" color={colors.dangerDark}>
              {error}
            </Typography>
          </Stack>
        </div>
      )}
      {!Boolean(error) && (file || url) && (
        <Stack flex={1} style={{ width: "300px", height: "180px" }}>
          <input id={inputId} name={name} {...getInputProps()} onSelect={onSelectFile} multiple={false} />
          {/* <div style={{backgroundImage: `url(${filePreview || url})`, width: "300px", height: "auto", objectFit: "contain"}}></div> */}
          <img alt="" src={filePreview || url} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </Stack>
      )}
    </div>
  )
}
