import "./Login.scss";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import ReCAPTCHA from "../../../components/react-google-recaptcha-enterprise";

import { useNavigate, useLocation } from "react-router-dom";
import appapi from "../../../api/apiClient";
import { showmessage } from "../../../utils/toastr";
import { login } from "../../../redux/features/authSlice";
import { buttonStyle1 } from "../../../utils/commonStyle";
import { setLoading } from "../../../redux/features/appSlice";
import { encrypt } from "../../../utils/Crpyto";
import { colors } from "../../../utils/colors";
import mobilewavybg from "../../../assets/images/mobilewavybg.svg";
import diztextlogo from "../../../assets/images/diztextlogo.svg";
import dizlogoMobile from "../../../assets/images/dizlogoMobile.svg";
import wavesbg from "../../../assets/images/wavesbg.svg";
import SocialCard from "../../../components/SocialCard";
import FormTextField from "../../../components/FormTextField";

/**
 * Validation Schema for register
 */
const validationSchema = yup.object({
  email: yup
    .string("Enter your Email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is Invalid")
    .required("")
    .trim(),
  password: yup
    .string("Enter your Password")
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/, 'Enter a password of minimum 8 characters with a combination of capital letters, small letters, symbols, and numbers.')
    .required("")
    .trim(),
});

export default function Login() {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:1000px)");

  const formRef = useRef();

  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const { state } = useLocation();

  const [data, setData] = useState({
    email: state?.email ? state?.email : "",
    password: "",
  });

  /**
   * @param {email, password} obj
   * Login user
   */
  const submit = (obj) => {
    // loadRecaptcha(() => {
    dispatch(setLoading(true));
    // if (window && window.grecaptcha) {
    //   console.log(window.grecaptcha);
    //   window.grecaptcha.enterprise.ready(() => {
    //     window.grecaptcha.enterprise
    //       .execute("6LftiwckAAAAAP-0tM-urRCDergCU1o1EjNHShi7", {
    //         action: "login",
    //       })
    //       .then((token) => {
    //         loginApi({ ...obj, token });
    //       });
    //   });
    // }
    loginApi(obj)
  };

  const loginApi = async (obj) => {
    let payload = {
      emailId: obj.email,
      password: obj.password,
      deviceId: "123",
      token: recaptchaToken,
    };
    let dataObj = {};
    const response = await appapi.post("/auth/login", payload);
    if (response.ok) {
      dispatch(setLoading(false));
      const data = response.data;
      if (data.status === "success") {
        // showmessage(data.message)
        dataObj = { ...data.data };
        let encryptedData = encrypt(JSON.stringify(data.data));
        localStorage.setItem("diz", encryptedData);
        appapi.setHeader("Authorization", `Bearer ${data.data.accessToken}`);
        dispatch(login(dataObj));
      } else {
        showmessage(data.message, "error");
      }
    } else {
      dispatch(setLoading(false));
      if (response.status === 400) {
        let error = response.data.errors[0].message.replace(
          "body.emailId",
          "Email Id"
        );
        showmessage(error, "error");
      }
    }
  };

  const handleSignUpNavigate = () => {
    navigate("/signup");
  };

  /**
   *
   * @returns redirect to forgotPassword Page
   */
  const onClickForgotPassword = (values, errors) => {
    if (!Boolean(errors.email)) {
      navigate(`/forgotPassword`, { state: { email: values.email } });
    } else {
      navigate(`/forgotPassword`, { state: { email: "" } });
    }
  };

  const onChange = (value) => {
   setRecaptchaToken(value ? value : null);
  }

  const loginForm = () => {
    return (
      <>
        <Typography
          className="fw-bold ff-Roboto createHeading"
          color={colors.primaryTxt}
        >
          {" "}
          Login{" "}
        </Typography>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            email: data?.email || "",
            password: data?.password,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => submit(values)}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <Form noValidate autoComplete="off">
              <Stack flex={1} className="mt-9">
                <FormTextField
                  label="Email"
                  name="email"
                  sx={{ mb: 0 }}
                  lowerCase={true}
                />
              </Stack>
              <Stack flex={1} className="mt-14">
                <FormTextField
                  label="Password"
                  name="password"
                  type="password"
                  sx={{ mb: '14px' }}
                  forgotPassword={true}
                  onClickForgotPassword={() =>
                    onClickForgotPassword(values, errors)
                  }
                  visibility={visibility}
                  setVisibility={(val) => setVisibility(val)}
                />
              </Stack>
              <div className="g-recaptcha">
              <ReCAPTCHA action="login" sitekey="6LcQc-8jAAAAAN3MpjpfKCMhVYFZuBarUVwQq-IZ" onChange={onChange} />
              </div>
              
              <Button
                sx={{ ...buttonStyle1, width: "100%" , mt: '2.3px'}}
                disabled={!(dirty && isValid) || !Boolean(recaptchaToken)}
                type="submit"
              >
                Login
              </Button>
              <Stack direction="row" className="mt-16" justifyContent="center">
                <Typography
                  className="ff-Roboto fw-regular fs-12"
                  color={colors.gray}
                >
                  Don't have an account{" "}
                </Typography>
                <Typography
                  onClick={handleSignUpNavigate}
                  className="ff-Roboto fw-bold fs-12"
                  color={colors.primaryDark}
                  style={{ marginLeft: "11px", cursor: "pointer" }}
                >
                  Sign up
                </Typography>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <div
      className="loginContainer"
      style={{
        padding: isMobile ? "16px" : "0",
        minHeight: isMobile ? "auto" : "100vh",
      }}
    >
      {isMobile ? (
        <div
          className="mobileViewContainer"
          style={{ minHeight: "calc(100vh - 32px)" }}
        >
          <div
            style={{
              backgroundImage: `url(${mobilewavybg})`,
              minHeight: "calc(100vh - 32px)",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="logoImg" style={{ marginLeft: "8px" }}>
              <img alt="dizLogo" src={dizlogoMobile} />
            </div>
            <div className="innerContainer" style={{ marginTop: "160px" }}>
              {loginForm()}
            </div>
          </div>
        </div>
      ) : (
        <Stack
          style={{ maxWidth: "1368px", margin: "auto", minHeight: "758.64px" }}
          direction="row"
          justifyContent="space-between"
        >
          <Stack className="leftStack" style={{ width: "100%" }}>
            <div className="logoImg">
              <img
                style={{ visibility: "hidden" }}
                alt="dizLogo"
                src={diztextlogo}
              />
            </div>
            <div
              className="detailContainer"
              style={{ backgroundImage: `url(${wavesbg})` }}
            ></div>
            <Stack className="formSubmittedStyle">
              <div className="logoImg">
                <img alt="dizLogo" src={diztextlogo} />
              </div>
              <div style={{ marginTop: "13.541666666667vh" }}>
                {loginForm()}
              </div>
            </Stack>
          </Stack>
        </Stack>
      )}
      {isMobile ? (
        <Stack
          className="footer"
          direction="row"
          justifyContent="space-between"
        >
          <Stack className="socialContainer" direction="row">
            {<SocialCard />}
          </Stack>
          <Typography className="fs-10 ff-Roboto fw-medium black dizcopyrights">
            © 2022 Do It Zero Inc.
          </Typography>
        </Stack>
      ) : (
        <Stack className="footerWeb">
          <Stack
            className="socialContainer flex-justifycenter"
            style={{
              margin: "3.125vh auto 3.125vh",
            }}
            direction="row"
          >
            {<SocialCard />}
          </Stack>
          <Typography className="fs-10 ff-Roboto fw-medium black dizcopyrights">
            © 2022 Do It Zero Inc.
          </Typography>
        </Stack>
      )}
    </div>
  );
}
