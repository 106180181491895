import { Button, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import appapi from "../../../../../api/apiClient";
import FormTextField from "../../../../../components/FormTextField";
import FormTextFieldLabel from "../../../../../components/FormTextFieldLabel";
import { setLoading } from "../../../../../redux/features/appSlice";

import { colors } from "../../../../../utils/colors";
import { buttonStyle2 } from "../../../../../utils/commonStyle";
import { showmessage } from "../../../../../utils/toastr";

/**
 * Validation Schema 
 */
const validationSchema = yup.object({
    oldPassword: yup
        .string('Enter your Old Password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/, 'Enter a password of minimum 8 characters with a combination of capital letters, small letters, symbols, and numbers.')
        .required('Old Password is requried')
        .trim(),
    newPassword: yup
        .string('Enter your New Password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/, 'Enter a password of minimum 8 characters with a combination of capital letters, small letters, symbols, and numbers.')
        .required('New Password is requried'),
})


export default function ChangePassword() {

    const isMobile = useMediaQuery("(max-width:600px)");

    const containerSX = isMobile ? { mt: '19px', width: '100%' } : { width: '328px', mt: '99px' };

    const [visibility1, setVisibility1] = useState(false);
    const [visibility2, setVisibility2] = useState(false);

    const formRef = useRef();

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const handleChangepassword = async (payload) => {
        dispatch(setLoading(true));
        const response = await appapi.put(`/user/${user.enterpriseId}/${user.enterpriseUserId}/changePassword`, payload)
        if (response.ok) {
            const data = response.data
            if (data.status === 'success') {
                showmessage(data.message, 'success');
                formRef?.current?.resetForm();
            } else {
                showmessage(data.message, 'error')
            }
            dispatch(setLoading(false))
        } else {
            dispatch(setLoading(false))
            // showmessage(`Something Went Wrong!`, 2000, 'error')
        }
    };

    return (
        <Stack>
            <Typography className="ff-Roboto fw-bold" sx={{ color: colors.primaryHover }}>CHANGE PASSWORD</Typography>
            <Stack alignSelf={isMobile ? "unset" : "center"} sx={containerSX}>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        newPassword: '',
                        oldPassword: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleChangepassword(values)}
                >
                    {({ isValid, dirty }) => (
                        <Form noValidate autoComplete="off">
                            <FormTextField label="Old Password" name="oldPassword" type="password"
                                visibility={visibility1}
                                setVisibility={(val) => setVisibility1(val)}
                            />
                            <FormTextField label="New Password" name="newPassword" type="password"
                                visibility={visibility2}
                                setVisibility={(val) => setVisibility2(val)}
                            />
                            <Button sx={{ ...buttonStyle2, width: '100%' }} disabled={!(dirty && isValid)} type='submit'>CHANGE PASSWORD</Button>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Stack>
    );
}