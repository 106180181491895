import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import ReactCrop from "react-image-crop"
import { createRef } from "react"
import "react-image-crop/src/ReactCrop.scss"
import { Buffer } from "buffer"
import { Button, Stack } from "@mui/material"
import { useState } from "react"
import "./CropImage.scss"

export default function CropImage(props) {
  const { onClose, imageUri, open } = props
  const [crop, setCrop] = useState("")
  const imgRef = createRef(null)

  const handleClose = () => {
    onClose()
  }

  const done = () => {
    const canvas = document.createElement("canvas")
    // get the image element
    const image = imgRef.current
    // draw the image on the canvas
    if (image) {
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      const ctx = canvas.getContext("2d")
      const pixelRatio = window.devicePixelRatio
      canvas.width = crop.width * pixelRatio * scaleX
      canvas.height = crop.height * pixelRatio * scaleY

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = "high"
        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY)
      }
      const base64Image = canvas.toDataURL("image/png") // can be changed to jpeg/jpg etc
      if (base64Image && base64Image != "data:,") {
        // @ts-ignore
        const fileType = base64Image.split(";")[0].split(":")[1]
        const buffer = Buffer.from(base64Image.replace(/^data:image\/\w+;base64,/, ""), "base64")
        const file = new File([buffer], "preview.jpg", { type: fileType })
        const imageObj = new Image()
        imageObj.src = URL.createObjectURL(file)
        imageObj.addEventListener("load", () => {
          let objUrl = URL.createObjectURL(file)
          onClose(objUrl)
        })
      } else {
        onClose()
      }
    } else {
      onClose()
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} sx={{ padding: "24px" }}>
      <Stack flex={1} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <DialogTitle>Crop Image</DialogTitle>
        <Button onClick={() => done()} disabled={crop ? false : true} style={{ marginRight: "24px" }}>
          Done
        </Button>
      </Stack>
      <div className="cropContainer">
        <ReactCrop
          aspect={1}
          crop={crop}
          onChange={c => {
            setCrop(c)
          }}
          onComplete={c => {}}
        >
          <img src={imageUri} ref={imgRef} style={{ width: "100%", height: "auto" }} />
        </ReactCrop>
      </div>
    </Dialog>
  )
}
