import './AnalyticsHeader.scss'
import React, { useState, useEffect } from 'react';
import { Stack, Typography, Menu, MenuItem, Box, FormControl, FormControlLabel, RadioGroup, Radio, Button } from '@mui/material'
import { buttonStyle4 } from '../../../../../utils/commonStyle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { reportSelect } from '../../../../../utils/commonUtils'
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function AnalyticsHeader({ isMobile, colors, selectedOption, SetSelectedOption, handleReport }) {

    //Options menu
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);

    const [displayfilter, setDisplayfilter] = useState('This month')

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        reportSelect.map((item) => {
            if (item.value === selectedOption) {
                setDisplayfilter(item.name)
            }
        })
    }, [anchorEl]);

    return (
        <header className='AnalyticsheaderContainer' style={{ marginBottom: '8px' }}>
            <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                <Stack style={{ paddingTop: isMobile ? '10px' : '0', width: isMobile ? '100%' : 'initial' }} direction='row' alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={isMobile ? 'space-between' : 'initial'}>
                    <Stack>
                        <Typography className='ff-Roboto fw-medium text-upper' style={{ color: colors.blackMain }}>
                            REPORTS & ANALYTICS
                        </Typography>
                        {
                            isMobile ?
                                <Typography className='ff-Roboto fs-12 fw-regular' style={{ color: colors.black }}>
                                    {displayfilter}
                                </Typography>
                                : ''
                        }

                    </Stack>

                    {
                        isMobile ?
                            <Box
                                className='optionsSelectionStyle'
                                id='resource-button'
                                onClick={handleMenuOpen}
                                aria-controls={open ? 'resource-menu' : undefined}
                                aria-haspopup='true'
                                aria-expanded={open ? 'true' : undefined}
                                style={{ justifyContent: 'space-around' }}
                            >
                                <DateRangeIcon style={{ transform: 'scale(0.8)' }} />
                                <KeyboardArrowDownIcon style={{ color: 'rgb(0, 0, 0, 0.3)' }} />
                            </Box>
                            :
                            <Box
                                // className='optionsSelectionStyle diz-dropdown'
                                className={`optionsSelectionStyle diz-dropdown cursor-pointer ${open ? "dd-opened" : ""}`}
                                id='resource-button'
                                onClick={handleMenuOpen}
                                aria-controls={open ? 'resource-menu' : undefined}
                                aria-haspopup='true'
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Typography className='ff-Roboto fw-regular fs-12 dropdown-label' style={{ color: colors.black, width: '83%' }}>
                                    {displayfilter}
                                </Typography>
                                <KeyboardArrowDownIcon style={{ color: 'rgb(0, 0, 0, 0.3)' }} className="dropdown-icon" />
                            </Box>
                    }

                    <Menu className='reportBar popup-menu filter' id='resource-menu' anchorEl={anchorEl} open={open}
                        MenuListProps={{
                            "aria-labelledby": 'resource-button',
                        }}
                        onClose={handleMenuClose}
                        PaperProps={{
                            style: {
                                width: '176px',
                            },
                        }}
                    >
                        {
                            <FormControl>
                                {/* <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    checked={selectedOption}
                                    name="radio-buttons-group"
                                > */}
                                {
                                    reportSelect.map((item, index) => (
                                        <MenuItem
                                            disableTouchRipple
                                            key={item.value}
                                            disabled={selectedOption === item.value}
                                            selected={selectedOption === item.value}
                                            onClick={() => { SetSelectedOption(item.value); handleMenuClose() }}
                                            onClose={handleMenuClose}
                                            sx={{ margin: '0px 8px', marginTop: index === 0 ? '8px' : '0' }}
                                        >
                                            {/* <FormControlLabel value={item.value}
                                                    control={<Radio
                                                        size="small"
                                                        checked={item.value === selectedOption}
                                                        sx={{
                                                            color: colors.black,
                                                            padding: '0 0 0 8px',
                                                            '&.Mui-checked': {
                                                                color: colors.black
                                                            },
                                                        }}
                                                        onChange={() => { SetSelectedOption(item.value); handleMenuClose() }}
                                                    />} label={item.name} /> */}
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                                {/* </RadioGroup> */}
                            </FormControl>
                        }
                    </Menu>
                </Stack>
                {
                    isMobile ? '' :
                        <div className='flex-aligncenter'>
                            <Button onClick={handleReport} sx={{ ...buttonStyle4, marginRight: '0', minWidth: '112px', width: '112px', height: '36px', textTransform: 'uppercase', borderRadius: '16px' }} className='ff-Roboto fw-regular fs-12' variant="contained">
                                Report
                            </Button>
                        </div>
                }

            </Stack>
        </header>
    )
}
