import './DragAndDrop.scss';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { colors } from '../../utils/colors';

export default function DragAndDrop({ handleFile, inputId, name, url, helpText = 'File dimensions should be 300x300 upto 1 MB', backgroundColor = '#F2F2F2', showTitle = true }) {

    const [error, setError] = useState('');

    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    const onDrop = async (files) => {

        if (files[0].size > 1000000) {
            handleFile(null)
            setError('File size should be less than or equal to 1MB')
            setFile(null)
            setFilePreview(null);
            return;
        }
        const image = new Image();
        image.src = URL.createObjectURL(files[0])
        image.addEventListener('load', () => {
            if (image.width <= 300 && image.height <= 300) {
                // console.log('yeah size and dimension');
                handleFile(files[0])
                setFile(files[0])
                let objUrl = URL.createObjectURL(files[0])
                setFilePreview(objUrl)
                setError(false)
            } else {
                handleFile(null)
                setError('Image dimensions should be 300 x 300 ')
                setFile(null)
                setFilePreview(null);
            }
        });

    }

    const onCancelFile = () => {
        handleFile(null)
        setFilePreview(null)
        setError(false)
        setFile(null)
    }


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject
    } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png']
        },
        onDrop,
        maxFiles: 1,
    });



    return (
        <div className="drop-zone-container flex-center" style={{ border: isDragActive ? '1px solid #106AA5' : '', flexDirection: 'column', backgroundColor: backgroundColor  }}>
            {
                (!Boolean(error) && (file || url)) &&
                <IconButton className='cancelButton' onClick={onCancelFile}>
                    <ClearIcon style={{ transform: 'scale(1.143)', color: 'rgb(6, 29, 48)' }} />
                </IconButton>
            }

            {/* {isDragAccept && (<p>All files will be accepted</p>)}
                {isDragReject && (<p>Some files will be rejected</p>)}
                {!isDragActive && (<p>Drop some files here ...</p>)} */}

            {
                (!Boolean(error) && !(file || url)) &&
                <div style={{ width: '100%', height: '100%', outline: 'none', border: 'none' }} className='dropZone' {...getRootProps({ className: "dropzone" })}>
                    <Stack justifyContent='center' alignItems='center' className='imgContainer'>
                        <input id={inputId} name={name} {...getInputProps()} />
                            <CloudUploadIcon style={{ color: 'rgb(178,215,135)', fontSize: '96px' }} />
                        {
                            isDragReject ?
                                <>
                                    <Typography className='fs-14 ff-Roboto fw-medium' color={colors.blackMain}>
                                        Images only supported
                                    </Typography>
                                </>
                                :
                                <>
                                    {showTitle && <Typography className='fs-14 ff-Roboto fw-medium' color={colors.blackMain}>
                                        Drop File Here
                                    </Typography>}
                                    {helpText && <Typography className='fs-12 ff-Roboto fw-light mt-6' color={colors.blackMain} style={{ textAlign: 'center', margin: '0px 32px'}}>
                                        {helpText}
                                    </Typography>}
                                </>
                        }

                    </Stack>
                </div>
            }
            {
                (Boolean(error) && !(file || url)) &&
                <div className='dropZone' style={{ width: '100%', height: '100%', outline: 'none', border: 'none' }} {...getRootProps({ className: "dropzone" })}>
                    <Stack justifyContent='center' alignItems='center' className='imgContainer'>
                        <input id={inputId} name={name} {...getInputProps()} />
                        <div style={{ height: '64px', width: '96px' }} className='flex-center'>
                            <CloudUploadIcon style={{ color: 'rgb(178,215,135)', width: '96px' }} />
                        </div>
                        <Typography className='fs-12 ff-Roboto fw-light mt-6' color={colors.blackMain}>
                            File dimensions should be 300x300 upto 1 MB
                        </Typography>
                        <Typography  className='fs-10 ff-Roboto fw-regular' color={colors.dangerDark}>
                            {error}
                        </Typography>
                    </Stack>
                </div>
            }
            {
                (!Boolean(error) && (file || url)) &&
                <div style={{ width: '300px' }}>
                    <img alt='' src={filePreview || url} style={{ width: '270px', height: '100px', objectFit: 'contain' }} />
                    <Typography style={{ textAlign: 'center' }} className='fs-14 ff-Roboto fw-medium ellipseStyle' color={colors.blackMain}>
                        {file?.name || ''}
                    </Typography>
                </div>
            }

        </div>
    );
}