import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChart({ isMobile, selectedFilterOption, chartData }) {

    const options = {
        type: "chart",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: isMobile ? 8 : 12,
                    }
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        }
    };


    // const labels = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sept",
    //     "Oct",
    //     "Nov",
    //     "Dec"
    // ];

    const [initialdata, setInitialData] = useState(null);
    const [labels, setLabels] = useState(null);
    const [barPercentage, setBarPercentage] = useState(1);

    useEffect(() => {
        if (selectedFilterOption === new Date().getFullYear()) {
            let arr = []
            let arrdata = []
            chartData?.map((item) => {
                arr.push(item.month)
                arrdata.push(item.count)
            })
            setLabels(arr)
            setInitialData(arrdata)
        } else if (selectedFilterOption === 1) {
            let arr = []
            let arrdata = []
            chartData?.map((item) => {
                arr.push(item.name)
                arrdata.push(item.count)
            })
            setLabels(arr)
            setInitialData(arrdata)
        } else {
            let arr = []
            let arrdata = []
            let arr1 = [
                {
                    year: 2021,
                    count: 2
                },
                {
                    year: 2020,
                    count: 15
                },
                {
                    year: 2019,
                    count: 10
                },
                {
                    year: 2018,
                    count: 11
                },
                {
                    year: 2017,
                    count: 12
                },
                {
                    year: 2016,
                    count: 9
                },
                {
                    year: 2015,
                    count: 8
                },
                {
                    year: 2014,
                    count: 7
                },
                {
                    year: 2013,
                    count: 7
                },
                {
                    year: 2012,
                    count: 3
                },
                {
                    year: 2011,
                    count: 5
                },
                {
                    year: 2010,
                    count: 1
                },
            ]
            // chartData = [...arr1, ...chartData]

            chartData?.map((item) => {
                arr.push(item.year)
                arrdata.push(item.count)
            })
            //to Slice last 12 data
            let lastcount = 12
            if (arr.length >= lastcount) {
                arr = arr.slice(1).slice(-lastcount)
                arrdata = arrdata.slice(1).slice(-lastcount)
            }

            console.log(arr, arrdata)

            setLabels(arr)
            setInitialData(arrdata)
        }
    }, [chartData]);

    const typeQuaterly = (type) => {
        if (type === 1) {
            setBarPercentage(0.5)
        }

        if (type === new Date().getFullYear()) {
            setBarPercentage(0.7)
        }
        console.log(initialdata?.length)
        if (type === 0) {
            console.log(initialdata.length)
            if (initialdata?.length > 10) {
                setBarPercentage(0.7)
            }
            if (initialdata?.length <= 9) {
                setBarPercentage(0.5)
            }
            if (initialdata?.length < 5) {
                setBarPercentage(0.3)
            }
            if (initialdata?.length == 1) {
                setBarPercentage(0.1)
            }

        }
    }

    useEffect(() => {
        typeQuaterly(selectedFilterOption)
    }, [initialdata]);

    const data = {
        labels,
        datasets: [
            {
                label: '',
                data: initialdata,
                backgroundColor: 'rgb(55, 135, 201, 0.5)',
                // barThickness: 48,
                barPercentage: barPercentage,
                borderRadius: 4,
                borderSkipped: false,
            },
        ],
    };

    return (
        <Bar options={options} data={data} />
    )
}
