import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { create } from "apisauce";
import APPCONFIG from '../../config/apiConfig';
import { CircularProgress, Stack } from '@mui/material';

export default function PGSuccess() {

  const paramsFromUrl = useParams();

  const mobapi = create({
    baseURL: APPCONFIG.mobileEndPoint,
  });

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  const checkPaymentStatus = async () => {
    const response = await mobapi.get(`payment/phonepe/${paramsFromUrl.mtid}`);
    if (response.ok) {
      const data = response.data;
      if(data.data.code === 'PAYMENT_SUCCESS' || data.data.code === 'PAYMENT_PENDING') {
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(JSON.stringify({ payment_status: 'completed' }));
      } else {
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(JSON.stringify({ payment_status: 'failed' }));
      }
    } else {
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify({ payment_status: 'failed' }));
    }
  }

  return (
    <Stack sx={{height: '100vh'}} flex={1} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  )
}
