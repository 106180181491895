import './CampaignSection.scss'
import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { colors } from '../../../../../utils/colors';
import millify from 'millify';

export default function CampaignCard({ item, index }) {
    return (
        <div className='campaignMobileCard' key={index}>
            <Stack>
                <Typography className='ff-Roboto fw-medium' color={colors.black} style={{ marginLeft: '8px' }}>
                    {item?.title}
                </Typography>
                <Box className='mt-13 boxContainer'>
                    <Stack direction='row'>
                        <Stack style={{ width: '50%', rowGap: '16px' }}>
                            <div>
                                <Typography className='ff-Roboto fw-regular black'>
                                    {millify(item?.totalParticipants)}
                                </Typography>
                                <Typography className='fs-12 ff-Roboto fw-regular' color={colors.primaryHover}>
                                    Participants
                                </Typography>
                            </div>
                            <div>
                                <Typography className='ff-Roboto fw-regular black'>
                                    {millify(item?.ecoPointsAwarded)}
                                </Typography>
                                <Typography className='fs-12 ff-Roboto fw-regular' color={colors.primaryHover}>
                                    Eco-points Awarded
                                </Typography>
                            </div>
                        </Stack>
                        <Stack style={{ width: '50%', rowGap: '16px' }}>
                            <div>
                                <Typography className='ff-Roboto fw-regular black'>
                                    {millify(item?.totalActions)}
                                </Typography>
                                <Typography className='fs-12 ff-Roboto fw-regular' color={colors.primaryHover}>
                                    Total Actions
                                </Typography>
                            </div>
                            <div>
                                <Typography className='ff-Roboto fw-regular black'>
                                    {millify(item?.averageEcoPoints)}
                                </Typography>
                                <Typography className='fs-12 ff-Roboto fw-regular' color={colors.primaryHover}>
                                    Average Eco-Points
                                </Typography>
                            </div>
                        </Stack>
                    </Stack>
                </Box>
                <div className='mt-14'>
                    <Typography className='fs-12 ff-Roboto fw-regular' style={{ lineHeight: 'initial' }}>Result</Typography>
                    <Typography className='ff-Roboto fw-regular black' style={{ lineHeight: 'initial' }}>{item?.result || '-'}</Typography>
                </div>
            </Stack>
        </div>
    )
}
