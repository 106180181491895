import { configureStore } from '@reduxjs/toolkit'
import appSlice from '../features/appSlice';
import authSlice from '../features/authSlice'

const store = configureStore({
    reducer: {
        auth: authSlice,
        app: appSlice
    }
})
export default store;