import "./TableColumnFilter.scss";
import React, { useEffect,useRef, useState  } from "react";
import {
  Menu,
  MenuItem,
  Checkbox,
  Button,
  Stack,
  IconButton,
  FormControlLabel,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { colors } from "../../../../../utils/colors";

export default function TableColumnFilter({ menuList, onApply }) {
  const anchorRef = useRef(null);
  const [openTableColumnFilter, setOpenTableColumnFilter] = useState(false);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const tempMenus = [...menuList];
    setMenus([...tempMenus]);
  },[menuList])

  const handleTableColumnMenuOpen = () => {
    setOpenTableColumnFilter(true);
  };

  const handleTableColumnMenuClose = () => {
    setOpenTableColumnFilter(false);
  };

  const handleSelectMenu = (index) => {
    const tempMenus = [...menus];
    tempMenus[index].selected = !tempMenus[index].selected;
    setMenus(tempMenus);
  };

  const handleClearMenu = () => {
    setOpenTableColumnFilter(false);
  };

  const handleApplyMenu = () => {

    setOpenTableColumnFilter(false);
    onApply(menus);
  }

  const checkedMenusLength = menus.filter((item) => item.selected).length;

  return (
    <>
      <IconButton
        ref={anchorRef}
        style={{ color: colors.primaryDark }}
        aria-controls={openTableColumnFilter ? "table-column-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openTableColumnFilter ? "true" : undefined}
        id="table-column-button"
        onClick={handleTableColumnMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="pereferenceMenu popup-menu"
        id="table-column-menu"
        anchorEl={anchorRef.current}
        open={openTableColumnFilter}
        MenuListProps={{
          "aria-labelledby": "table-column-button",
        }}
        onClose={handleTableColumnMenuClose}
        PopperProps={{
          placement: "bottom-end",
        }}
        PaperProps={{
          style: {
            width: "200px",
            marginTop: "5px",
            borderRadius: "4px",
            background: "#FFFFFF",
            position: "relative",
          },
        }}
      >
        <Stack className="menu-header" alignItems="center" justifyContent="center">
          <Typography className="ff-Roboto fs-12 fs-medium">Column Preference</Typography>
        </Stack>
        {menus.map((item, index) => (
          <MenuItem
            key={index}
            disableTouchRipple
            style={{ color: colors.blackMain }}
            onClose={handleTableColumnMenuClose}
          >
            <FormControlLabel
              disabled={
                item.default || (!item.selected && checkedMenusLength === 7)
              }
              className="ff-Roboto fs-12 fs-medium"
              control={
                <Checkbox
                  onChange={() => handleSelectMenu(index)}
                  checked={item.selected}
                  style={{
                    padding: "0",
                    paddingRight: "16px",
                    color: item.selected ?  "#106AA5" : colors.blackMain,
                  }}
                />
              }
              label={item?.name}
            />
          </MenuItem>
        ))}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: "16px 16px" }}
        >
          <Button
            className="ff-Roboto fs-10 fw-medium"
            style={{
              color: "#474747",
              background: "transparent",
              width: "70px",
              height: "24px",
              borderRadius: '20px'
            }}
            variant="text"
            onClick={handleClearMenu}
          >
            Cancel
          </Button>
          <Button
            className="apply-btn ff-Roboto fs-10 fw-medium"
            variant="contained"
            onClick={handleApplyMenu}
          >
            Apply
          </Button>
        </Stack>
      </Menu>
    </>
  );
}
