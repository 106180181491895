import "../UserManagement.scss"
import React, { useState, useEffect } from "react"
import { Dialog, Stack, Typography, DialogActions, Button, IconButton, TextField, MenuItem, useMediaQuery } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined"
import * as yup from "yup"
import { FieldArray, getIn, Formik, Form } from "formik"
import { buttonStyle3, buttonStyle2, dialogButtonCancel } from "../../../../utils/commonStyle"
import AddIcon from "@mui/icons-material/Add"
import { useDispatch, useSelector } from "react-redux"
import appapi from "../../../../api/apiClient"
import { showmessage } from "../../../../utils/toastr"
import { setLoading } from "../../../../redux/features/appSlice"
import { colors } from "../../../../utils/colors"

yup.addMethod(yup.array, "unique", function (message, mapper = a => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size
  })
})

export default function AddUserDialog({ open, enterpriseUser, onHandleAddClose, userPermission }) {
  const { rolesList } = useSelector(state => state.app)
  const [roles, setRoles] = useState(rolesList)

  useEffect(() => {
    if (userPermission?.editAccess === "2") {
      let filteredValue = rolesList.filter(item => item.roleId !== 1)
      setRoles(filteredValue)
    } else {
      setRoles(rolesList)
    }
  }, [open])

  const dispatch = useDispatch()

  const [inviteCheck, setInviteCheck] = useState(false)
  // const [mobileTop, SetMobileTop] = useState(false);
  const [inputArr, setInputArr] = useState([
    {
      emailId: "",
      rolePermissionId: "",
      mobile: "",
      createdBy: enterpriseUser?.name,
    },
  ])

  const inviteUser = async obj => {
    obj.userArr.map(item => {
      let find = roles.find(role => role.roleId === item.rolePermissionId)
      if (find) {
        item[`roleName`] = find.roleName
      }
    })
    setInputArr(obj.userArr)
    setInviteCheck(true)
  }

  const handleSendInvitation = async () => {
    dispatch(setLoading(true))
    let arr = JSON.parse(JSON.stringify(inputArr))
    arr.map(item => {
      item.createdBy = enterpriseUser?.name
      delete item.roleName
    })
    let params = {
      params: {
        enterpriseId: enterpriseUser.enterpriseId,
      },
    }
    let payload = arr
    const response = await appapi.post(`/user/${enterpriseUser.enterpriseId}`, payload, params)
    if (response.ok) {
      dispatch(setLoading(false))
      const data = response.data
      if (data.status === "success") {
        handleCloseDialog(false)
        showmessage("Invitation has been sent successfully")
      } else {
        showmessage(data.message, "error")
      }
    } else {
      dispatch(setLoading(false))
      showmessage(`Something Went Wrong!`, "error", 2000)
    }
  }

  const isMobile = useMediaQuery("(max-width:1000px)")
  // const isMobile1 = useMediaQuery('(max-width:600px)');

  const IconContainer = ({ children, backgroundColor = colors.secondary }) => {
    return (
      <div className="iconBox flex-center" style={{ background: backgroundColor, borderRadius: "4px", width: "32px", height: "32px" }}>
        {children}
      </div>
    )
  }

  const handleCloseDialog = event => {
    setInputArr([
      {
        emailId: "",
        rolePermissionId: "",
        mobile: "",
        createdBy: enterpriseUser?.name,
      },
    ])
    setInviteCheck(false)
    onHandleAddClose(event)
  }

  /**
   * dialog style
   */
  const sx = {
    "& .MuiDialog-container": {
      alignItems: isMobile ? "end" : "center",
      WebkitAlignItems: isMobile ? "end" : "center",
      WebkitBoxAlign: isMobile ? "end" : "center",
    },
    "& .MuiPaper-root": {
      width: "736px",
      height: isMobile ? "620px" : "552px",
      margin: isMobile ? "0" : "8px",
      padding: "0 16px 23px 16px",
      borderRadius: isMobile ? "16px 16px 0 0" : "4px",
      maxWidth: "736px",
      maxHeight: "calc(100% - 20px)",
      // overflow: 'hidden'
    },
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return
    handleCloseDialog(true)
  }

  return (
    <Dialog
      sx={sx}
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
      className="AddUserContainer bottomDialog"
    >
      <Stack style={{ borderBottom: "1px solid rgb(112,112,112,0.3)", paddingBottom: "10px", marginBottom: "16px", position: "sticky", top: "0", paddingTop: "23px", zIndex: "99", background: "#FFF" }} direction="row" justifyContent="space-between" alignItems="center">
        <div className="flex">
          {/* {inviteCheck ? '' : <PersonIcon style={{ color: '#8AB756' }} />} */}
          <Typography style={{ color: colors.blackMain }} className="fw-bold fs-14 ff-Roboto">
            {inviteCheck ? "CONFIRMATION" : "ADD USER"}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => handleCloseDialog(true)}>
            <ClearIcon style={{ color: colors.blackMain, transform: "scale(1.143)" }} />
          </IconButton>
        </div>
      </Stack>
      {inviteCheck ? (
        <Stack>
          <Stack style={{ height: isMobile ? "412px" : "368px", overflowY: "scroll", overflowX: "hidden" }}>
            {inputArr.map((item, index) => (
              <Stack key={index}>
                <Stack direction={isMobile ? "column" : "row"} justifyContent="space-between" style={{ borderBottom: "1px solid rgb(112,112,112,0.3)", padding: "16px 0", rowGap: isMobile ? "16px" : "" }}>
                  <Stack direction="row" style={{ width: isMobile ? "100%" : "45%" }}>
                    <IconContainer>
                      <MailOutlineIcon style={{ color: colors.primaryDark, transform: "scale(0.8)" }} />
                    </IconContainer>
                    <Stack style={{ marginLeft: "16px", width: "calc(100% - 48px)" }}>
                      <Typography style={{ color: colors.primaryTxt }} className="ff-Roboto fs-10 fw-medium">
                        Email
                      </Typography>
                      <Typography style={{ color: colors.blackMain }} className="ff-Roboto fs-14 fw-regular wordBreak">
                        {item?.emailId}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" style={{ width: isMobile ? "100%" : "50%" }}>
                    <IconContainer>
                      <GroupOutlinedIcon style={{ color: colors.primaryDark, transform: "scale(0.8)" }} />
                    </IconContainer>
                    <Stack style={{ marginLeft: "16px", width: "calc(100% - 48px)" }}>
                      <Typography style={{ color: colors.primaryTxt }} className="ff-Roboto fs-10 fw-medium">
                        Roles
                      </Typography>
                      <Typography style={{ color: colors.blackMain }} className="ff-Roboto fs-14 fw-regular wordBreak">
                        {item?.roleName || "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <DialogActions
            style={{
              height: "66px",
              justifyContent: "flex-end",
              padding: "0",
              marginTop: "20px",
            }}
          >
            <Button sx={dialogButtonCancel} variant="outlined" onClick={() => setInviteCheck(false)}>
              Cancel
            </Button>
            <Button sx={buttonStyle2} style={{ marginLeft: "16px" }} onClick={handleSendInvitation} variant="contained">
              Send
            </Button>
          </DialogActions>
        </Stack>
      ) : (
        <Formik
          initialValues={{
            userArr: inputArr,
          }}
          validateOnMount
          validationSchema={yup.object().shape({
            userArr: yup
              .array()
              .of(
                yup.object().shape({
                  emailId: yup
                    .string("Enter your Email")
                    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is Invalid")
                    .required("Email is Required"),
                  mobile: yup
                    .string()
                    .min(10, "Mobile Number must be 10 digits")
                    .matches(/^[0-9]*$/, "Mobile Number should be only numbers")
                    .label("Mobile Number"),
                  rolePermissionId: yup.string("Select your role").required("Role is Required"),
                })
              )
              .unique("Duplicate emails not allowed", a => a.emailId),
          })}
          onSubmit={values => inviteUser(values)}
        >
          {({ values, touched, errors, handleChange, handleBlur, isValid, dirty }) => (
            <Form noValidate autoComplete="off">
              <FieldArray name="userArr">
                {({ push, remove }) => (
                  <>
                    <div style={{ height: isMobile ? "412px" : "350px", overflowY: "scroll", overflowX: "hidden" }}>
                      {values.userArr.map((p, index) => {
                        const emailId = `userArr[${index}].emailId`
                        const touchedEmailId = getIn(touched, emailId)
                        const errorEmailId = getIn(errors, emailId)

                        const mobile = `userArr[${index}].mobile`
                        const touchedmobile = getIn(touched, mobile)
                        const errormobile = getIn(errors, mobile)

                        const rolePermissionId = `userArr[${index}].rolePermissionId`
                        const touchedrolePermissionId = getIn(touched, rolePermissionId)
                        const errorRolePermissionId = getIn(errors, rolePermissionId)

                        return (
                          <Stack key={index} className="editContainer addContainer">
                            <Stack style={{ background: "#F2F2F2", padding: "8px", marginBottom: "8px", position: "relative" }}>
                              <Stack direction={isMobile ? "column" : "row"} justifyContent="space-between" style={{ rowGap: isMobile ? "14px" : "initial" }}>
                                <Stack style={{ width: isMobile ? "100%" : "328px" }}>
                                  <Typography className="fs-14 ff-Roboto fw-medium mb-7" color={colors.blackMain}>
                                    Email
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={emailId}
                                    name={emailId}
                                    value={p.emailId.toLowerCase()}
                                    error={Boolean(touchedEmailId && errorEmailId)}
                                    onChange={handleChange}
                                    onBlur={event => {
                                      handleBlur(event)
                                    }}
                                    helperText={touchedEmailId && errorEmailId}
                                    // onFocus={() => SetMobileTop(true)}
                                    autoComplete="off"
                                  />
                                </Stack>
                                <Stack style={{ width: isMobile ? "100%" : "328px" }}>
                                  <Typography className="fs-14 ff-Roboto fw-medium mb-7" color={colors.blackMain}>
                                    Mobile Number
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={mobile}
                                    name={mobile}
                                    value={p.mobile.toLowerCase()}
                                    error={Boolean(touchedmobile && errormobile)}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 10 }}
                                    onBlur={event => {
                                      handleBlur(event)
                                    }}
                                    helperText={touchedmobile && errormobile}
                                    // onFocus={() => SetMobileTop(true)}
                                    autoComplete="off"
                                  />
                                </Stack>
                              </Stack>
                              <Stack className="mt-9" direction={isMobile ? "column" : "row"} justifyContent="space-between" style={{ rowGap: isMobile ? "14px" : "initial" }}>
                                <Stack style={{ width: isMobile ? "100%" : "328px" }}>
                                  <Typography className="fs-14 ff-Roboto fw-medium mb-7" color={colors.blackMain}>
                                    Select Role
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={rolePermissionId}
                                    name={rolePermissionId}
                                    value={p.rolePermissionId}
                                    error={Boolean(touchedrolePermissionId && errorRolePermissionId)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touchedrolePermissionId && errorRolePermissionId}
                                    select
                                  >
                                    {roles?.map(item => (
                                      <MenuItem key={item.roleId} value={item.roleId}>
                                        {" "}
                                        {item.roleName}{" "}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Stack>
                              </Stack>
                              <div style={{ textAlign: "end", position: isMobile ? "absolute" : "initial", top: "0", right: "0" }}>
                                {values.userArr.length > 1 && (
                                  <IconButton
                                    style={{
                                      height: "32px",
                                      width: "32px",
                                      marginTop: isMobile ? 0 : "19px",
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    <ClearIcon style={{ color: "#496073", transform: isMobile ? "scale(1.143)" : "initial" }} />
                                  </IconButton>
                                )}
                              </div>
                            </Stack>
                          </Stack>
                        )
                      })}
                    </div>
                    {/* {errors &&
                                                errors.userArr && (
                                                    <span className="text-danger d-block">
                                                        hello
                                                    </span>
                                                )
                                            } */}
                    <div style={{ textAlign: "center" }}>
                      <Button
                        sx={buttonStyle3}
                        onClick={() =>
                          push({
                            emailId: "",
                            rolePermissionId: "",
                            mobile: "",
                            createdBy: enterpriseUser?.name,
                          })
                        }
                        variant="contained"
                        endIcon={<AddIcon />}
                      >
                        ADD
                      </Button>
                    </div>
                  </>
                )}
              </FieldArray>
              <DialogActions
                style={{
                  height: "66px",
                  justifyContent: "flex-end",
                  padding: "0",
                  marginTop: "20px",
                }}
              >
                <Button sx={dialogButtonCancel} variant="outlined" onClick={() => handleCloseDialog(true)}>
                  Cancel
                </Button>
                <Button sx={buttonStyle2} style={{ marginLeft: "16px" }} disabled={!isValid} type="submit" variant="contained">
                  Invite
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
