import CryptoJS from 'crypto-js';

const password = 'Studio@843'

export function encrypt(data) {
    const encrypedData = CryptoJS.AES.encrypt(data, password).toString();
    return encrypedData;
}

export function decrypt(data) {
    const decrypted = CryptoJS.AES.decrypt(data, password).toString(CryptoJS.enc.Utf8);
    return decrypted;
}
